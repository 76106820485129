import TaggingStudio from "./TaggingStudio";
import { useContext, useState, useRef, useEffect } from "react";
import { parse } from "csv-parse/browser/esm/sync";
import { DataContext } from "../../../../../context/DataContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHistory } from "@fortawesome/free-solid-svg-icons";
import { TagContext } from "../../../../../context/TagContext";
import Tags from "../DataCatalog/DataCatalogComponents/Tags/Tags";
import { mergeTagWithDefaults } from "../../../../utilities/functions/utils";
import AutoCreateTag from "./AutoCreateTag";
import AddTaggingRules from "../TaggingRules/TaggingRules";
import TagEditor from "./TagEditor";
import { COLOURS } from "../../../../../assets/colours";
import { faBook } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import TabComponent from "./Tabs.js";
import { useAtom } from "jotai";
import { selectedTagKeysAtom } from "../../../../../atoms";
import { toast } from "../../../../utilities/Toast";
import { uploadTags } from "../../../../utilities/functions/apiCalls";
import { updateParentLabelObj } from "../../../../utilities/functions/utils";
import { PermissionGuard } from "../../../../utilities/PermissionGuard";
import { sendRequest } from "../../../../utilities/functions/api";
import { ENDPOINTS } from "../../../../../api/endpoints";
import Auth from "../../../../../auth/AuthProvider";
import { API_USERNAME_KEYWORD } from "../../../../../constants/fixedValues";
import TagVersionList from "./TagVersionList";
import { useSpring, animated } from "@react-spring/web";
import { v4 as uuidv4 } from "uuid";
import { customStyleForSelect } from "../../../../utilities/SearchBar/SearchBar";
import isEqual from "lodash/isEqual";
import ExampleLimitModal from "../../../../utilities/Modal/ExampleLimitModal";
import { css } from "@emotion/react";
import { colors } from "../../../../../twExtend";

export default function AddNewTag() {
  const [currentValue, setCurrentValue] = useState("");

  const {
    currentTag,
    setCurrentTag,
    availableTags,
    setAvailableTags,
    defaultCurrentTag,
  } = useContext(DataContext);
  const [originalTag, setOriginalTag] = useState(null);

  const {
    saveTag,
    defaultTagTypes,
    activeTab,
    setActiveTab,
    isTagLibraryCollapsed,
    setIsTagLibraryCollapsed,
  } = useContext(TagContext);

  const [showTaggingStudio, setShowTaggingStudio] = useState(false);
  const [previousExamples, setPreviousExamples] = useState(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const [error, setError] = useState("");
  const fileInputRef = useRef(null);
  const exampleFileInputRef = useRef(null);
  const tagDict = {
    ...availableTags.llm.tagger_params.tag_dict,
    ...availableTags.sensitivity.tagger_params.tag_dict,
  };
  const [showExampleLimitModal, setShowExampleLimitModal] = useState(false);
  const [exampleLimitType, setExampleLimitType] = useState(null);

  const { usedCatalog } = useContext(DataContext);

  const isTagAvailable = tagDict.hasOwnProperty(currentTag.name);
  const [refreshTrigger, setRefreshTrigger] = useState(0);

  const [, setSelectedTagKeys] = useAtom(selectedTagKeysAtom);
  const options = Object.keys(tagDict).map((key) => ({
    value: key,
    label: key,
  }));

  const tagCount = availableTags
    ? Object.keys({
        ...availableTags.llm.tagger_params.tag_dict,
        ...availableTags.sensitivity.tagger_params.tag_dict,
      }).length
    : 0;

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) {
      setError("No file selected.");
      return;
    }

    const reader = new FileReader();
    reader.onload = async (e) => {
      const content = e.target.result;
      try {
        const records = parse(content, {
          columns: true,
          skip_empty_lines: true,
        });

        const requiredColumns = [
          "Tag Name",
          "Type of Tag",
          "Description",
          "Output constraint type",
          "Output constraint",
          "Output type",
          "Group",
        ];
        const actualColumns = Object.keys(records[0]);
        if (
          requiredColumns.length !== actualColumns.length ||
          !requiredColumns.every((col) => actualColumns.includes(col))
        ) {
          const missingColumns = requiredColumns.filter(
            (col) => !actualColumns.includes(col),
          );
          const extraColumns = actualColumns.filter(
            (col) => !requiredColumns.includes(col),
          );
          setError(
            `CSV does not have the required columns: ${missingColumns.join(", ")}`,
          );
          return;
        }

        // Value validation
        const isValid = records.every((record) => {
          const typeOfTagValid = ["Sensitivity", "Classification"].includes(
            record["Type of Tag"],
          );
          const expectingAValid = ["word", "number", "date"].includes(
            record["Output type"].toLowerCase(),
          );
          const outputConstraintTypeValid = [
            "yesNo",
            "aiGenerated",
            "custom",
          ].includes(record["Output constraint type"]);

          if (!typeOfTagValid) {
            setError(
              `Invalid Type of Tag '${record["Type of Tag"]}' for tag '${record["Tag Name"]}'`,
            );
            return false;
          }
          if (!expectingAValid) {
            setError(`Invalid Output Type ${record["Output type"]}`);
            return false;
          }
          if (!outputConstraintTypeValid) {
            setError(
              `Invalid Output constraint type '${record["Output constraint type"]}' for tag '${record["Tag Name"]}'`,
            );
            return false;
          }
          return true;
        });

        if (!isValid) {
          return;
        }

        processTags(records);
        toast.info({
          title: "Tags processing",
          description: "File processed successfully.",
        });
      } catch (error) {
        setError("Error processing file: " + error.message);
      }
    };
    reader.readAsText(file);
    setError("");
    event.target.value = null;
  };

  const handleExampleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) {
      setError("No file selected.");
      return;
    }

    const reader = new FileReader();
    reader.onload = async (e) => {
      const content = e.target.result;
      try {
        const records = parse(content, {
          columns: true,
          skip_empty_lines: true,
        });

        const requiredColumns = [
          "Tag Name",
          "Type of Tag",
          "Example Type",
          "Evidence",
          "Output",
        ];
        const actualColumns = Object.keys(records[0]);
        if (
          requiredColumns.length !== actualColumns.length ||
          !requiredColumns.every((col) => actualColumns.includes(col))
        ) {
          setError("CSV does not have the required columns.");
          return;
        }

        // Value validation
        const isValid = records.every((record) => {
          const typeOfTagValid = ["Sensitivity", "Classification"].includes(
            record["Type of Tag"],
          );
          const typeOfExampleValid = ["correct", "incorrect"].includes(
            record["Example Type"].toLowerCase(),
          );

          if (!typeOfTagValid) {
            setError(
              `Invalid Type of Tag '${record["Type of Tag"]}' for tag '${record["Tag Name"]}'`,
            );
            return false;
          }
          if (!typeOfExampleValid) {
            setError(
              `Invalid Example Type '${record["Example Type"]}' for tag '${record["Tag Name"]}'`,
            );
            return false;
          }
          return true;
        });

        if (!isValid) {
          return;
        }

        processExamples(records);
        toast.info({
          title: "Examples processing",
          description: "Examples added successfully.",
        });
      } catch (error) {
        setError("Error processing file: " + error.message);
      }
    };
    reader.readAsText(file);
    setError("");
    event.target.value = null;
  };

  const handleDownload = () => {
    const data = [
      [
        "Tag Name",
        "Type of Tag",
        "Description",
        "Output constraint type",
        "Output constraint",
        "Output type",
        "Group",
      ],
      [
        "yes-no-tag",
        "Classification",
        "this is description for a yes-no tag",
        "yesNo",
        "Yes,No",
        "Word",
        "Group1",
      ],
      [
        "custom-tag",
        "Classification",
        "this is a custom tag with predefined values",
        "custom",
        "category1,category2,category3",
        "Word",
        "Group2",
      ],
      [
        "open-ended-ai-tag",
        "Classification",
        "this is a tag without predefined values",
        "aiGenerated",
        "",
        "Word",
        "Group3",
      ],
    ];
    const exampleData = [
      ["Tag Name", "Type of Tag", "Example Type", "Evidence", "Output"],
      [
        "yes-no-tag",
        "Classification",
        "correct",
        "some text giving evidence for yes",
        "Yes",
      ],
      [
        "custom-tag",
        "Classification",
        "incorrect",
        "some text giving wrong evidence for category1",
        "category1",
      ],
    ];

    function arrayToCSV(data) {
      return data
        .map((row) => row.map((cell) => `"${cell}"`).join(","))
        .join("\n");
    }

    const csvContentTagData = arrayToCSV(data);
    const csvContentExampleData = arrayToCSV(exampleData);

    function downloadCSV(csvContent, fileName) {
      const link = document.createElement("a");
      link.setAttribute(
        "href",
        "data:text/csv;charset=utf-8," + encodeURIComponent(csvContent),
      );
      link.setAttribute("download", fileName);
      document.body.appendChild(link);

      link.click();
      document.body.removeChild(link);
    }

    downloadCSV(csvContentTagData, "tag-template.csv");
    downloadCSV(csvContentExampleData, "example-template.csv");
  };

  const updateTagVersion = async (tag) => {
    try {
      const creds = (await Auth.currentAuthenticatedUser()).username;
      const sendDetails = {
        tag_uuid: tag.tag_uuid,
        version_uuid: tag.version_uuid,
        tag_name: tag.name,
        catalog_name: usedCatalog,
        modified_attributes: JSON.stringify([]),
        new_values: JSON.stringify([]),
        old_values: JSON.stringify([]),
        updated_time: new Date().toISOString(),
        tag_details: {
          ...currentTag,
          customFields: currentTag.customFields || {},
        },
        [API_USERNAME_KEYWORD]: creds,
      };
      await sendRequest(sendDetails, ENDPOINTS["update_tag_version"]);
    } catch (error) {
      console.error("Error updating tag:", error);
      toast.error({
        title: "Error",
        description: "An error occurred while updating the tag",
      });
    }
  };

  const processTags = async (tags) => {
    const result = {};
    let updatedTags = { ...availableTags };
    const tagUpdatePromises = tags.map(async (tagData) => {
      let outputConstraint = [];

      if (tagData["Output constraint"] !== "") {
        outputConstraint = tagData["Output constraint"].split(",");
      }

      const tag = {
        tag_uuid: uuidv4(),
        version_uuid: uuidv4(),
        name: tagData["Tag Name"].replace(/,/g, ""),
        tagType: tagData["Type of Tag"],
        description: tagData["Description"],
        availableValues: outputConstraint,
        max_words: 1,
        option: tagData["Output constraint type"],
        allow_other_values: false,
        is_document_level: false,
        type: tagData["Output type"],
        examples: [],
      };

      if (tag.name.includes(",")) {
        toast.warning({
          title: "Warning",
          description: `Tag name "${tag.name}" cannot contain a comma. Commas were taken out of the tag name.`,
        });
      }

      const mergedTag = mergeTagWithDefaults(tag, defaultCurrentTag);
      updatedTags = updateParentLabelObj(updatedTags, mergedTag);

      const filename = tagData["Filename"] || "default";
      if (!result[filename]) {
        result[filename] = [];
      }
      result[filename].push(mergedTag);

      return updateTagVersion(tag);
    });

    try {
      await Promise.all(tagUpdatePromises);
      await uploadTags(updatedTags, usedCatalog);
      toast.success({
        title: "Success",
        description: "All tags processed and uploaded successfully!",
      });
    } catch (error) {
      toast.error({
        title: "Error",
        description: `Error uploading tags: ${error.message}`,
      });
    }

    setAvailableTags(updatedTags);
  };

  function exampleExists(list, example) {
    return list.some(
      (e) => e.evidence === example.evidence && e.value === example.value,
    );
  }

  const processExamples = async (examples) => {
    let updatedTags = { ...availableTags };

    const filterExamples = (examples, tagType, TagName) => {
      return examples.filter((exampleData) => {
        const { "Tag Name": rawTagName, "Type of Tag": TagType } = exampleData;
        return TagType === tagType && rawTagName.replace(/,/g, "") === TagName;
      });
    };

    const mapExamples = (filteredExamples) => {
      return filteredExamples.map((exampleData) => {
        const { "Example Type": ExampleType, Evidence, Output } = exampleData;
        return {
          evidence: Evidence,
          value: Output,
          example_type: ExampleType,
        };
      });
    };

    const addExamplesToTag = (tag, examplesToAdd, TagName) => {
      let example_added = false;
      examplesToAdd.forEach((example) => {
        let example_exists = false;
        const exampleType = example.example_type.toLowerCase();

        if (exampleType === "correct") {
          tag.examples = tag.examples || [];
          example_exists = exampleExists(tag.examples, example);
          if (!example_exists) {
            tag.examples.push(example);
            example_added = true;
          }
        } else if (exampleType === "incorrect") {
          tag.neg_examples = tag.neg_examples || [];
          example_exists = exampleExists(tag.neg_examples, example);
          if (!example_exists) {
            tag.neg_examples.push(example);
            example_added = true;
          }
        }

        if (example_exists) {
          toast.warning({
            title: "Warning",
            description: `Example with the same evidence and value already exists for tag ${TagName}. Example not uploaded.`,
          });
        }
      });
      return example_added;
    };

    const processTagExamples = async (tagDict, tagType) => {
      for (const [TagName, tag] of Object.entries(tagDict)) {
        const filteredExamples = filterExamples(examples, tagType, TagName);
        const examplesToAdd = mapExamples(filteredExamples);
        const example_added = addExamplesToTag(tag, examplesToAdd, TagName);

        if (example_added) {
          tag.tag_uuid = currentTag.tag_uuid;
          tag.version_uuid = uuidv4();
          await updateTagVersion(tag);
          toast.success({
            title: "Success",
            description: `Example uploaded successfully for tag ${TagName}`,
          });
        }
      }
    };

    const taggerParams = [
      {
        dict: updatedTags.llm?.tagger_params?.tag_dict,
        type: "Classification",
      },
      {
        dict: updatedTags.sensitivity?.tagger_params?.tag_dict,
        type: "Sensitivity",
      },
    ];

    for (const param of taggerParams) {
      if (param.dict) {
        await processTagExamples(param.dict, param.type);
      }
    }

    try {
      await uploadTags(updatedTags, usedCatalog);
      toast.success({
        title: "Success",
        description: "All examples processed and uploaded successfully!",
      });
    } catch (error) {
      toast.error({
        title: "Error",
        description: `Error uploading examples: ${error.message}`,
      });
    }

    setAvailableTags(updatedTags);
  };

  useEffect(() => {
    return () => setCurrentTag({ ...defaultCurrentTag });
  }, []);

  const toggleTaggingStudio = () => {
    const mergedTag = mergeTagWithDefaults(currentTag, defaultCurrentTag);
    setCurrentTag({ ...mergedTag });
    setActiveTab(1);
    setIsTagLibraryCollapsed(true);
  };

  const clearCurrentTag = () => {
    setCurrentTag({ ...defaultCurrentTag });
    setPreviousExamples(null);
    setShowTooltip(false);
  };

  const handleAddValue = (e) => {
    if (e.key === "Enter" && currentValue.trim()) {
      setCurrentTag((prevTag) => ({
        ...prevTag,
        availableValues: [currentValue, ...(prevTag?.availableValues || [])],
      }));
      setCurrentValue("");
    }
  };

  const handleDeleteValue = (value) => {
    setCurrentTag((prevTag) => ({
      ...prevTag,
      availableValues: prevTag.availableValues.filter((item) => item !== value),
    }));
  };

  const handleChangeOption = (option) => {
    switch (option) {
      case "yesNo":
        setCurrentTag((prevTag) => ({
          ...prevTag,
          availableValues: ["Yes", "No"],
          max_words: 1,
          allow_other_values: false,
          reference_file: "",
          option: "yesNo",
        }));
        break;
      case "fileUpload":
        setCurrentTag((prevTag) => ({
          ...prevTag,
          availableValues: ["Yes", "No"],
          max_words: 1,
          allow_other_values: false,
          option: "fileUpload",
        }));
        break;
      case "aiGenerated":
        setCurrentTag((prevTag) => ({
          ...prevTag,
          availableValues: [],
          allow_other_values: true,
          reference_file: "",
          max_words: 2,
          option: "aiGenerated",
        }));
        break;
      case "custom":
        setCurrentTag((prevTag) => ({
          ...prevTag,
          availableValues: prevTag.availableValues,
          allow_other_values: false,
          reference_file: "",
          option: "custom",
        }));
        break;
      default:
        break;
    }
  };

  const changeType = (type) => {
    setCurrentTag((prevTag) => ({
      ...prevTag,
      type: type || "word",
    }));
  };

  const changeIsDocumentLevel = (isDocumentLevel) => {
    setCurrentTag((prevTag) => ({
      ...prevTag,
      is_document_level: isDocumentLevel,
    }));
  };

  const handleTagTypeChange = (type) => {
    setCurrentTag((prevTag) => {
      const newTag = { ...prevTag };
      newTag.tagType = newTag.tagType === type ? "" : type;
      if (type === defaultTagTypes["sensitivity"]) {
        newTag.risk_level = newTag.risk_level || "High";
      }
      return newTag;
    });
  };

  const handleRiskLevelChange = (level) => {
    setCurrentTag((prevTag) => ({
      ...prevTag,
      risk_level: level,
    }));
  };

  const addExamplePair = () => {
    const updatedExamples = currentTag.examples
      ? [...currentTag.examples, { evidence: "", value: "" }]
      : [{ evidence: "", value: "" }];

    setCurrentTag({
      ...currentTag,
      examples: updatedExamples,
    });
  };

  const addNegExamplePair = () => {
    const updatedNegExamples = currentTag.neg_examples
      ? [...currentTag.neg_examples, { evidence: "", value: "" }]
      : [{ evidence: "", value: "" }];

    setCurrentTag({
      ...currentTag,
      neg_examples: updatedNegExamples,
    });
  };

  const updateExample = (index, field, value, exampleType = "examples") => {
    const newExamples =
      exampleType === "examples"
        ? [...currentTag.examples]
        : [...currentTag.neg_examples];

    newExamples[index] = {
      ...newExamples[index],
      [field]: value,
    };

    setCurrentTag({
      ...currentTag,
      [exampleType]: newExamples,
    });
  };

  const deleteExamplePair = (index) => {
    const newExamples = currentTag.examples.filter((_, i) => i !== index);
    setCurrentTag({
      ...currentTag,
      examples: newExamples,
    });
  };

  const deleteNegExamplePair = (index) => {
    const newExamples = currentTag.neg_examples.filter((_, i) => i !== index);
    setCurrentTag({
      ...currentTag,
      neg_examples: newExamples,
    });
  };
  const isExistingTag = currentTag.name in tagDict;

  const prevTagRef = useRef({ name: null, description: null });

  useEffect(() => {
    const prevName = prevTagRef.current.name;
    const prevDescription = prevTagRef.current.description;

    if (
      prevName !== currentTag.name &&
      prevDescription !== currentTag.description
    ) {
      // only initialize original tag when the whole tag is switched
      setOriginalTag(JSON.parse(JSON.stringify(currentTag)));
    }
    prevTagRef.current.name = currentTag.name;
    prevTagRef.current.description = currentTag.description;
  }, [currentTag.name, currentTag.description, currentTag.UUID]);

  const hasTagChanged = (original, current) => {
    const relevantKeys = [
      "allow_other_values",
      "availableValues",
      "description",
      "examples",
      "is_document_level",
      "max_words",
      "name",
      "neg_examples",
      "option",
      "reference_file",
      "risk_level",
      "standardized",
      "tagType",
      "type",
      "selected_tag_model",
      "customFields",
      "group",
    ];

    let changes = [];

    const compareValues = (oldVal, newVal, key) => {
      if (
        typeof oldVal === "object" &&
        oldVal !== null &&
        typeof newVal === "object" &&
        newVal !== null
      ) {
        if (!isEqual(oldVal, newVal)) {
          return false;
        }
      } else if (oldVal !== newVal) {
        return false;
      }
      return true;
    };

    for (let key of relevantKeys) {
      const originalValue = original[key];
      const currentValue = current[key];

      if (!compareValues(originalValue, currentValue, key)) {
        changes.push({
          attribute: key,
          oldValue: originalValue,
          newValue: currentValue,
        });
      }
    }

    return changes.length > 0 ? changes : null;
  };

  const handleSaveTag = async () => {
    const result = await update_tag();
    if (!isExistingTag && result) {
      setSelectedTagKeys([currentTag.name]);
    }
  };

  const transferValidationRecords = async (tag_uuid, prev_version_uuid, new_version_uuid) => {
    try {
      const creds = (await Auth.currentAuthenticatedUser()).username;
      const transferDetails = {
        [API_USERNAME_KEYWORD]: creds,
        catalog_name: usedCatalog,
        tag_uuid: tag_uuid,
        old_version_uuid: prev_version_uuid,
        new_version_uuid: new_version_uuid,
      };
      await sendRequest(
        transferDetails,
        ENDPOINTS["transfer_validation_records"],
      );
      setRefreshTrigger(prev => prev + 1);
    } catch (error) {
      console.error("Error transferring validation records", error);
    }
  };

  const update_tag = async () => {
    if (currentTag.examples?.length > 10) {
      setExampleLimitType("positive");
      setShowExampleLimitModal(true);
      return;
    } else if (currentTag.neg_examples?.length > 10) {
      setExampleLimitType("negative");
      setShowExampleLimitModal(true);
      return;
    }

    const changes = hasTagChanged(originalTag, currentTag);
    if (changes) {
      try {
        toast.info({
          title: "Info",
          description: "Preparing a new version of the tag for you",
        });
        const version_uuid = uuidv4();
        const prev_version_uuid = currentTag.version_uuid;
        currentTag.tag_uuid = currentTag.tag_uuid || uuidv4();
        currentTag.version_uuid = version_uuid;
        setOriginalTag(JSON.parse(JSON.stringify(currentTag)));

        await saveTag(currentTag, null, true);

        try {
          const creds = (await Auth.currentAuthenticatedUser()).username;
          const sendDetails = {
            tag_uuid: currentTag.tag_uuid,
            version_uuid: version_uuid,
            tag_name: currentTag.name,
            catalog_name: usedCatalog,
            modified_attributes: JSON.stringify(
              changes.map((change) => change.attribute),
            ),
            new_values: JSON.stringify(
              changes.map((change) => change.newValue),
            ),
            old_values: JSON.stringify(
              changes.map((change) => change.oldValue),
            ),
            updated_time: new Date().toISOString(),
            tag_details: {
              ...currentTag,
              customFields: currentTag.customFields || {},
            },
            [API_USERNAME_KEYWORD]: creds,
          };
          await sendRequest(sendDetails, ENDPOINTS["update_tag_version"]);
          await transferValidationRecords(currentTag.tag_uuid, prev_version_uuid, version_uuid);
          toast.success({
            title: "Success",
            description: "A new version of the tag has been created successfully",
          });
          setOriginalTag(JSON.parse(JSON.stringify(currentTag)));
        } catch (error) {
          console.error("Error updating tag:", error);
          toast.error({
            title: "Error",
            description: "An error occurred while updating the tag",
          });
        }
      } catch (error) {
        console.error("Error saving tag:", error);
        toast.error({
          title: "Error",
          description: "An error occurred while saving the tag",
        });
      }
    } else {
      toast.info({
        title: "Info",
        description: "No changes detected",
      });
    }
  };

  const [versions, setVersions] = useState({});
  const [versionsVisible, setVersionsVisible] = useState(false);

  const handleTagVersion = async () => {
    try {
      const creds = (await Auth.currentAuthenticatedUser()).username;
      const sendDetails = {
        // tag_name: currentTag.name,
        tag_uuid: currentTag.tag_uuid,
        catalog_name: usedCatalog,
        [API_USERNAME_KEYWORD]: creds,
      };
      const response = await sendRequest(
        sendDetails,
        ENDPOINTS["get_tag_versions"],
      );
      const data = await response.json();
      setVersions(data);
    } catch (err) {
      console.error("Error fetching tag versions", err);
      setError(err.message);
    }
  };

  useEffect(() => {
    if (Object.keys(versions).length > 0) {
      setVersionsVisible(true);
    }
  }, [versions]);

  const ref = useRef(null);
  const [contentHeight, setContentHeight] = useState(0);
  useEffect(() => {
    if (ref.current) {
      setContentHeight(ref.current.scrollHeight);
    }
  }, [versionsVisible]);

  const slideAnimation = useSpring({
    from: { maxHeight: 0, opacity: 0, transform: "translateY(-10px)" },
    to: {
      maxHeight: versionsVisible ? contentHeight : 0,
      opacity: versionsVisible ? 1 : 0,
      transform: versionsVisible ? "translateY(0)" : "translateY(-20px)",
    },
    config: {
      mass: 1,
      tension: 170,
      friction: 20,
    },
  });

  return (
    <div className="relative flex w-[98vw] overflow-hidden h-[90vh] transition-all duration-300">
      <div className={`flex  flex-row gap-4 ${!showTaggingStudio && "w-full"}`}>
        <div
          className={`relative bg-[rgb(231, 237, 244)] bg-white rounded-md flex flex-col transition-all duration-300 text-sm`}
          style={{
            width: isTagLibraryCollapsed ? "5vw" : "25vw",
            opacity: isTagLibraryCollapsed ? 0.5 : 1,
            transition: "width 0.5s ease-in-out, opacity 0.3s ease-in-out",
          }}
        >
          {isTagLibraryCollapsed ? (
            <>
              <div
                className="flex flex-row w-[5vw] justify-between p-1 items-center "
                style={{ backgroundColor: COLOURS["HeaderBackground"] }}
              >
                <FontAwesomeIcon
                  icon={faBook}
                  className="text-grey mr-2 text-xl"
                />
                <button
                  onClick={() => setIsTagLibraryCollapsed(false)}
                  className="text-grey p-1 rounded-md"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 text-gray-500"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </button>
              </div>
              <div className="flex flex-col items-center justify-center">
                <span className="text-grey font-bold items-center justify-center flex flex-row mt-4 text-xl">
                  {tagCount}
                </span>
                <span className="text-md">tags</span>
              </div>
            </>
          ) : (
            <div
              className="flex flex-col rounded-t-md overflow-auto text-lg"
              style={{ width: "100%" }}
            >
              <Tags
                title="Tag Library"
                tagTypes={["llm", "sensitivity"]}
                isTagLibraryCollapsed={isTagLibraryCollapsed}
                setIsTagLibraryCollapsed={setIsTagLibraryCollapsed}
              />
            </div>
          )}
        </div>
        {activeTab === 1 && (
          <div
            className={"relative bg-white rounded-md flex flex-col text-sm "}
          >
            <div className="flex flex-col w-[25vw]">
              <header
                className="text-grey p-1 rounded-t-md flex justify-between items-center"
                style={{ backgroundColor: COLOURS["HeaderBackground"] }}
              >
                <div className="w-[50%]">
                  <Select
                    options={options}
                    placeholder="Select a tag"
                    isSearchable={true}
                    value={options.find(
                      (option) => option.value === currentTag.name,
                    )}
                    onChange={(e) => {
                      setCurrentTag(
                        {
                          ...availableTags.llm.tagger_params.tag_dict,
                          ...availableTags.sensitivity.tagger_params.tag_dict,
                        }[e.value],
                      );
                    }}
                    styles={{
                      ...customStyleForSelect,
                      control: (provided, state) => ({
                        ...provided,
                        ...(state.selectProps.value
                          ? {}
                          : {
                              backgroundColor: colors.containerLight,
                              color: colors.grey,
                              boxShadow: `0 0 0 0 ${colors.primary}`,
                              animation: "pulsate 1.5s infinite",
                            }),
                      }),
                    }}
                    className={`${!currentTag.name ? "pulsate-select" : ""}`}
                  />
                </div>
                <div className="relative w-[50%] flex justify-end mr-2">
                  <div
                    className={`text-primary cursor-pointer file:rounded-md ${
                      tagDict &&
                      !Object.keys(tagDict).includes(currentTag.name) &&
                      "cursor-not-allowed opacity-50 disabled"
                    }`}
                    onClick={handleTagVersion}
                    title="View Versions"
                    disabled={
                      tagDict && !Object.keys(tagDict).includes(currentTag.name)
                    }
                  >
                    <FontAwesomeIcon icon={faHistory} className="h-4 w-4" />
                  </div>
                </div>
              </header>
              <animated.div style={slideAnimation} className="w-full">
                <div
                  ref={ref}
                  className="p-4 w-full"
                  style={{ backgroundColor: COLOURS["HeaderBackground"] }}
                >
                  {versionsVisible && (
                    <TagVersionList
                      versions={versions}
                      onClose={() => setVersionsVisible(false)}
                      currentTag={currentTag}
                      setCurrentTag={setCurrentTag}
                    />
                  )}
                </div>
              </animated.div>
              <TagEditor
                currentTag={currentTag}
                setCurrentTag={setCurrentTag}
                addExamplePair={addExamplePair}
                addNegExamplePair={addNegExamplePair}
                updateExample={updateExample}
                deleteExamplePair={deleteExamplePair}
                deleteNegExamplePair={deleteNegExamplePair}
                toggleTaggingStudio={toggleTaggingStudio}
                handleChangeOption={handleChangeOption}
                handleAddValue={handleAddValue}
                handleDeleteValue={handleDeleteValue}
                currentValue={currentValue}
                setCurrentValue={setCurrentValue}
                handleTagTypeChange={handleTagTypeChange}
                handleRiskLevelChange={handleRiskLevelChange}
                changeType={changeType}
                changeIsDocumentLevel={changeIsDocumentLevel}
                showTooltip={showTooltip}
                setShowTooltip={setShowTooltip}
                activeTab={activeTab}
                options={options}
              />
              <div className="flex flex-row justify justify-left w-full p-4 absolute bottom-0 bg-white">
                <button
                  className={`text-base py-2 rounded-md font-bold w-[160px] border-2 ${
                    isTagAvailable
                      ? "text-primary border-primary"
                      : "text-gray-400 border-gray-400"
                  }`}
                  onClick={async () => {
                    update_tag();
                  }}
                  disabled={!isTagAvailable}
                >
                  Update Tag
                </button>
              </div>
            </div>
          </div>
        )}
        <div
          className={`h-full bg-white rounded-md overflow-auto flex flex-col text-sm w-full`}
        >
          <div className="h-full overflow-hidden hide-scrollbar flex flex-col mb-5">
            <TabComponent
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              fileInputRef={fileInputRef}
              exampleFileInputRef={exampleFileInputRef}
              handleFileChange={handleFileChange}
            />
            {error && <p className="text-red-500">{error}</p>}
            {activeTab === 0 ? (
              <TagEditor
                addExamplePair={addExamplePair}
                addNegExamplePair={addNegExamplePair}
                updateExample={updateExample}
                deleteExamplePair={deleteExamplePair}
                deleteNegExamplePair={deleteNegExamplePair}
                toggleTaggingStudio={toggleTaggingStudio}
                handleChangeOption={handleChangeOption}
                handleAddValue={handleAddValue}
                handleDeleteValue={handleDeleteValue}
                currentValue={currentValue}
                setCurrentValue={setCurrentValue}
                handleTagTypeChange={handleTagTypeChange}
                handleRiskLevelChange={handleRiskLevelChange}
                changeType={changeType}
                changeIsDocumentLevel={changeIsDocumentLevel}
                showTooltip={showTooltip}
                setShowTooltip={setShowTooltip}
                activeTab={activeTab}
                options={options}
              />
            ) : activeTab === 1 ? (
              <TaggingStudio
                previousExamples={previousExamples}
                setPreviousExamples={setPreviousExamples}
                refreshTrigger={refreshTrigger}
                setRefreshTrigger={setRefreshTrigger}
              />
            ) : activeTab === 2 ? (
              <AutoCreateTag />
            ) : activeTab === 3 ? (
              <AddTaggingRules />
            ) : (
              <div className="flex flex-col w-full h-full relative p-5 z-40 bg-white rounded">
                <div className="mb-4">
                  <h1 className="text-lg font-semibold text-grey">
                    Import Tags
                  </h1>

                  <p className="text-md">
                    Please use the template csv to import your tags
                  </p>
                </div>
                <div className="flex gap-4 max-w-[50%]">
                  <button
                    className="text-md p-2 rounded-md text-primary border-2 whitespace-nowrap border-primary font-bold w-[40%]"
                    onClick={() => fileInputRef.current?.click()}
                  >
                    Upload Tags
                  </button>
                  <button
                    className="text-md p-2 rounded-md text-primary border-2 whitespace-nowrap border-primary font-bold w-[40%]"
                    onClick={() => exampleFileInputRef.current?.click()}
                  >
                    Upload Tag Examples
                  </button>
                  <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    className="hidden"
                  />
                  <input
                    type="file"
                    ref={exampleFileInputRef}
                    onChange={handleExampleFileChange}
                    className="hidden"
                  />
                  <button
                    onClick={handleDownload}
                    className="text-md p-2 rounded-md bg-primary border-2 border-primary whitespace-nowrap text-white font-bold w-[40%]"
                  >
                    Download Templates
                  </button>
                </div>
              </div>
            )}
          </div>
          {activeTab === 0 && versionsVisible && (
            <div className="z-10 flex justify-end ml-auto bottom-20 right-0 rounded-lg p-4 w-2/3">
              <TagVersionList
                versions={versions}
                onClose={() => setVersionsVisible(false)}
                currentTag={currentTag}
                setCurrentTag={setCurrentTag}
              />
            </div>
          )}
          {showExampleLimitModal && exampleLimitType && (
            <ExampleLimitModal
              onClose={() => {
                setShowExampleLimitModal(false);
                setExampleLimitType(null);
              }}
              exampleLimitType={exampleLimitType}
            />
          )}
          <PermissionGuard scope="tags" level="canEdit">
            {!showTaggingStudio && (
              <div className="flex w-full text-sm items-end text-right flex-col">
                <span className="w-full bg-grey opacity-15 h-[0.1vh]"></span>
                <div className="flex flex-row w-full justify-start">
                  {activeTab === 0 && (
                    <>
                      <button
                        className="border-primary border-2 w-1/12 text-primary m-4 p-2 rounded-md"
                        onClick={() => {
                          clearCurrentTag();
                        }}
                      >
                        New tag
                      </button>
                      <button
                        className="bg-primary w-2/12 text-white m-4 p-2 rounded-md"
                        disabled={
                          !(
                            currentTag.name &&
                            currentTag.tagType &&
                            currentTag.description
                          )
                        }
                        onClick={handleSaveTag}
                      >
                        Save Tag
                      </button>
                      <button
                        className={`text-primary border-primary border-2 w-1/12 m-4 p-2 rounded-md ${
                          tagDict &&
                          !Object.keys(tagDict).includes(currentTag.name) &&
                          "cursor-not-allowed opacity-50 disabled"
                        }`}
                        onClick={() => {
                          setActiveTab(1);
                          setIsTagLibraryCollapsed(true);
                        }}
                        title={
                          tagDict &&
                          !Object.keys(tagDict).includes(currentTag.name) &&
                          "Please select or save a tag to test it"
                        }
                        disabled={
                          tagDict &&
                          !Object.keys(tagDict).includes(currentTag.name)
                        }
                      >
                        Test tag
                      </button>
                      <div
                        className={`flex justify-end items-center text-primary cursor-pointer ml-auto w-auto m-4 p-2 rounded-md ${
                          tagDict &&
                          !Object.keys(tagDict).includes(currentTag.name)
                            ? "cursor-not-allowed opacity-50"
                            : ""
                        }`}
                        onClick={handleTagVersion}
                        title="View Versions"
                        disabled={
                          tagDict &&
                          !Object.keys(tagDict).includes(currentTag.name)
                        }
                      >
                        <FontAwesomeIcon
                          icon={faHistory}
                          className="h-6 w-6 mr-2"
                        />
                        Tag Versions
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
          </PermissionGuard>
        </div>
      </div>
    </div>
  );
}
