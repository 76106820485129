import { useState, useRef, useEffect, useContext } from "react";
import "./DataList.css";
import Auth from "../../../../../../../auth/AuthProvider";
import LoadComponent from "../../../../../../utilities/LoadComponent/LoadComponent";
import { DataContext } from "../../../../../../../context/DataContext";
import EvidenceTable from "./DataListComponent/EvidenceTable/EvidenceTable";
import { useUserProfile } from "../../../../../../../context/UserProfile";
import { useAtom } from "jotai";
import {
  documentsUploadTaskAtom,
  documentTaggingTaskAtom,
} from "../../../../../../../atoms";
import { abortTask } from "../../../../../../../utils/workers";
import CatalogTable from "../../../../../../../pages/CatalogTable";
import { NonTaskProgressBar } from "../../../../../../utilities/NavigationBar/Components/ProgressBar/ProgressBar";
import { TagContext } from "../../../../../../../context/TagContext";
import DocumentEvidenceTable from "./DataListComponent/EvidenceTable/DocumentEvidenceTable";

export default function DataList(props) {
  const legendRef = useRef(null);
  const [expandTags, setExpandTags] = useState(false);
  const [expandedRowKey, setExpandedRowKey] = useState(null);
  const [showAllEvidence, setShowAllEvidence] = useState(false);
  const [currentItemKey, setCurrentItemKey] = useState("");
  const [sortedChunkMappings, setSortedChunkMappings] = useState({});
  const [uploadDataProgress, setUploadDataProgress] = useState(0);
  const [initialUploadedDatasets, setInitialUploadedDatasets] = useState(0);

  const [documentsUploadTask, setDocumentsUploadTask] = useAtom(
    documentsUploadTaskAtom,
  );
  const [documentTaggingTask, setDocumentTaggingTask] = useAtom(
    documentTaggingTaskAtom,
  );

  useEffect(() => {
    if (!documentsUploadTask) {
      setInitialUploadedDatasets(0);
    } else {
      setInitialUploadedDatasets(Object.keys(catalogFiles).length);
    }
  }, [documentsUploadTask]);

  const {
    currentDataGroup,
    processingFile,
    currentProcessCount,
    currentTotalProcessCount,
    availableTags,
    handleEvidenceButtonClick,
    evidenceData,
    setEvidenceData,
    isEvidenceModalOpen,
    setIsEvidenceModalOpen,
    totalDatasetsUploaded,
    catalogFiles,
    isDocumentEvidenceModalOpen,
    setIsDocumentEvidenceModalOpen,
  } = useContext(DataContext);

  const { processingTagTaskProgress } = useContext(TagContext);

  const { username } = useUserProfile();

  useEffect(() => {
    const sortedChunkKeys = Object.keys(
      currentDataGroup[currentItemKey]?.chunks || {},
    ).sort((a, b) => {
      const aStart = parseInt(a.split("_")[0], 10);
      const bStart = parseInt(b.split("_")[0], 10);
      return aStart - bStart;
    });

    const chunkMappings = {};
    sortedChunkKeys.forEach((chunkKey, index) => {
      chunkMappings[chunkKey] = `Chunk ${index + 1}`;
    });

    setSortedChunkMappings(chunkMappings);
  }, [currentDataGroup, currentItemKey]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!legendRef.current?.contains(event.target)) {
        setExpandTags(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [
    processingFile,
    expandedRowKey,
    currentProcessCount,
    currentTotalProcessCount,
  ]);

  useEffect(() => {
    const totalDatasets = totalDatasetsUploaded;
    const uploadedDatasets =
      Object.keys(catalogFiles).length - initialUploadedDatasets;
    const calculatedProgress =
      totalDatasets > 0
        ? Math.round((uploadedDatasets / totalDatasets) * 100)
        : 0;
    setUploadDataProgress(calculatedProgress);
  }, [catalogFiles, totalDatasetsUploaded]);

  if (props.answerLoading) {
    return (
      <div className="LoadComponentContainer">
        <LoadComponent />
      </div>
    );
  }

  return (
    <div className="overflow-hidden flex w-full h-full flex-col rounded-b-md">
      {(documentsUploadTask || documentTaggingTask) && (
        <div className="rounded-t-md w-full justify-betweem flex flex-row bg-white transition-all duration-200 p-4 items-center gap-4">
          {documentsUploadTask && (
            <>
              <NonTaskProgressBar progress={uploadDataProgress} />
              <button
                onClick={async () => {
                  await abortTask(
                    documentsUploadTask,
                    (await Auth.currentAuthenticatedUser()).username,
                    "Failed to stop the data upload process.",
                    "The data uploading process has been successfully stopped.",
                  );
                  setDocumentsUploadTask(null);
                }}
                className="text-sm bg-red-400 px-2 py-1 rounded-md text-white whitespace-nowrap"
              >
                Abort Upload
              </button>
            </>
          )}
          {documentTaggingTask && (
            <>
              <NonTaskProgressBar
                progress={(processingTagTaskProgress * 100).toFixed(2)}
              />
            </>
          )}
        </div>
      )}

      <div className="flex w-full h-full overflow-hidden">
        <CatalogTable
          onEvidenceClick={handleEvidenceButtonClick}
          availableTags={availableTags}
        />
      </div>
      <EvidenceTable
        showAllEvidence={showAllEvidence}
        setShowAllEvidence={setShowAllEvidence}
        evidenceData={evidenceData}
        setEvidenceData={setEvidenceData}
        currentItemKey={currentItemKey}
        isEvidenceModalOpen={isEvidenceModalOpen}
        setIsEvidenceModalOpen={setIsEvidenceModalOpen}
        sortedChunkMappings={sortedChunkMappings}
        username={username}
      />
      <DocumentEvidenceTable
        setShowAllEvidence={setShowAllEvidence}
        isDocumentEvidenceModalOpen={isDocumentEvidenceModalOpen}
        setIsDocumentEvidenceModalOpen={setIsDocumentEvidenceModalOpen}
      />
    </div>
  );
}
