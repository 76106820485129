// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.userUsageSelect .MuiSelect-select {
  padding: 3px 32px 3px 5px;
  min-width: 210px;
}
.userUsageSelect:disabled {
  cursor: not-allowed;
  opacity: .5;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/Home/HomeComponents/Preferences/PreferencesComponents/styles.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,gBAAgB;AAClB;AACA;EACE,mBAAmB;EACnB,WAAW;AACb","sourcesContent":[".userUsageSelect .MuiSelect-select {\n  padding: 3px 32px 3px 5px;\n  min-width: 210px;\n}\n.userUsageSelect:disabled {\n  cursor: not-allowed;\n  opacity: .5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
