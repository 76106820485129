import React, { useContext, useState, useEffect } from 'react';
import Auth from "../../../auth/AuthProvider";
import { ENDPOINTS } from "../../../api/endpoints";
import { API_USERNAME_KEYWORD } from "../../../constants/fixedValues";
import { sendRequest } from "../functions/api";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortUp, faSortDown, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { DataContext } from "../../../context/DataContext";
import { toast } from "../../../components/utilities/Toast";
import { toZonedTime, format } from 'date-fns-tz';

export const ScheduledTasksModal = ({ isModalOpen }) => {
  const [scheduledTasks, setScheduledTasks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [statusFilter, setStatusFilter] = useState('');
  const [sortDirection, setSortDirection] = useState('desc');
  const [visibleDetails, setVisibleDetails] = useState(null); // To track which task's details are visible
  const [detailsData, setDetailsData] = useState({ successCount: 0, failCount: 0, unfinishedCount: 0 });
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false); // To control the details modal visibility
  const [visibleFileList, setVisibleFileList] = useState(null); // To track which file list is visible
  const [selectedFiles, setSelectedFiles] = useState([]);
  const { preferences } = useContext(DataContext);
  const [activeTaskIds, setActiveTaskIds] = useState([]);

  const SortIcon = ({ isAsc }) => (
    <FontAwesomeIcon icon={isAsc ? faSortUp : faSortDown} />
  );

  const toggleSortDate = () => {
    const newDirection = sortDirection === 'asc' ? 'desc' : 'asc';
    setSortDirection(newDirection);
    const sortedTasks = [...scheduledTasks].sort((a, b) => {
      const dateA = new Date(a.schedule_at);
      const dateB = new Date(b.schedule_at);
      return newDirection === 'asc' ? dateA - dateB : dateB - dateA;
    });
    setScheduledTasks(sortedTasks);
  };

  const formatDate = (utcDateString) => {
    // Convert UTC date to the local time zone 
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const zonedTime = toZonedTime(new Date(utcDateString), timeZone);
    return format(zonedTime, 'yyyy-MM-dd HH:mm:ss', { timeZone });
  };
  
  const fetchScheduledTasks = async () => {
    setIsLoading(true);
    try {
      const creds = (await Auth.currentAuthenticatedUser()).username;
      const sendDetails = {
        [API_USERNAME_KEYWORD]: creds,
      };
      const response = await sendRequest(sendDetails, ENDPOINTS["get_user_scheduled_tasks"]);
      const responseData = await response.json();
      
      const tasksWithLocalTime = responseData.scheduled_tasks.map(task => ({
        ...task,
        scheduled_at: formatDate(task.scheduled_at)
      }));
      
      setScheduledTasks(tasksWithLocalTime || []);

      // Fetch task details if a task is currently selected
      if (visibleDetails) {
        await fetchTaskDetails(visibleDetails);
      }
      await fetchActiveTasks();
    } catch (error) {
      console.error('Failed to fetch scheduled tasks:', error);
      setScheduledTasks([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isModalOpen) {
      fetchScheduledTasks();
    }
  }, [isModalOpen]);

  useEffect(() => {
    setFilteredTasks(
      scheduledTasks.filter(task => statusFilter ? task.status.toLowerCase() === statusFilter.toLowerCase() : true)
    );
  }, [statusFilter, scheduledTasks]);

  const fetchTaskDetails = async (taskId) => {
    setIsLoading(true);
    try {
      const creds = (await Auth.currentAuthenticatedUser()).username;
      const sendDetails = {
        [API_USERNAME_KEYWORD]: creds,
        task_id: taskId
      };
      const response = await sendRequest(sendDetails, ENDPOINTS["get_schedule_task_details"]); // this will return {'success': {'filename':..., 'filename':...}, 'fail': {'filename':..., 'filename':...}, 'unfinished': {'filename':..., 'filename':...}}
      const responseData = await response.json();

      if (!responseData || !responseData.task_details) {
        console.warn("No file details available, please try later");
        setDetailsData({
          successCount: 0,
          failCount: 0,
          unfinishedCount: 0,
          successFiles: {},
          failFiles: {},
          unfinishedFiles: {},
          message: "No file details available, please try later"
        });
        return;
      }

      const file_details = responseData.task_details;
      const successCount = Object.keys(file_details.success || {}).length;
      const failCount = Object.keys(file_details.fail || {}).length;
      const unfinishedCount = Object.keys(file_details.unfinished || {}).length;

      setDetailsData({
        successCount,
        failCount,
        unfinishedCount,
        successFiles: file_details.success || {},
        failFiles: file_details.fail || {},
        unfinishedFiles: file_details.unfinished || {}
      });
    } catch (error) {
      console.error('Failed to fetch task details:', error);
      setDetailsData({
        successCount: 0,
        failCount: 0,
        unfinishedCount: 0,
        successFiles: {},
        failFiles: {},
        unfinishedFiles: {},
        message: "Failed to fetch task details, please try later"
      });
    } finally {
      setIsLoading(false);
    }
  };

  const fetchActiveTasks = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const creds = user.username;
      const email = user.attributes.email;

      const sendDetails = {
        username: creds,
        user_email: email,
      };

      const response = await sendRequest(sendDetails, ENDPOINTS["get_tasks_status"]);
      const responseData = await response.json();

      const activeTaskIds = [...new Set(responseData.activeTasks.map(task => task.id))];
      setActiveTaskIds(activeTaskIds);

    } catch (error) {
      console.error('Failed to fetch active tasks:', error);
      setActiveTaskIds([]);
    }
  };
  const toggleDetails = async (taskId) => {
    if (visibleDetails === taskId) {
      setVisibleDetails(null);
      setIsDetailsModalOpen(false);
    } else {
      await fetchTaskDetails(taskId);
      setVisibleDetails(taskId);
      setIsDetailsModalOpen(true);
    }
  };

  const toggleFileList = (listType) => {
    setVisibleFileList(visibleFileList === listType ? null : listType);
  };

  const handleFileSelect = (fileName) => {
    setSelectedFiles(prevSelected =>
      prevSelected.includes(fileName)
        ? prevSelected.filter(name => name !== fileName)
        : [...prevSelected, fileName]
    );
  };

  const rerunSelectedFiles = async () => {
    try {
      const creds = (await Auth.currentAuthenticatedUser()).username;
      const sendDetails = {
        [API_USERNAME_KEYWORD]: creds,
        task_id: visibleDetails, // Assuming `visibleDetails` contains the current task ID
        file_name_list: selectedFiles,
      };

      const response = await sendRequest(sendDetails, ENDPOINTS["fetch_entries_for_scheduled_tasks"]);
      const responseData = await response.json();

      const filteredEntries = responseData.entries.filter(entry => {
        const fileName = Object.keys(entry.file_catalog_entry)[0];
        return selectedFiles.includes(fileName);
      });
      const rerunDetails = {
        entries: filteredEntries,
        [preferences.system.API_USERNAME_KEYWORD]: creds,
        schedule_at: new Date().toISOString(),
        period: "",
        preferences: JSON.stringify(preferences),
        rerun: true,
        task_id: visibleDetails,
      };

      setVisibleFileList(null);
      setSelectedFiles([]);
      toast.success({
        title: "Success",
        description: `"You re-run task has been scheduled successfully."`,
      });
      await sendRequest(rerunDetails, ENDPOINTS["schedule_catalog_creation_bulk"]);

    } catch (error) {
      console.error('Failed to rerun files:', error);
    }
  };


  const handleSelectAll = (files) => {
    if (selectedFiles.length === Object.keys(files).length) {
      setSelectedFiles([]);
    } else {
      setSelectedFiles(Object.keys(files));
    }
  };

  const getDisplayStatus = (task) => {
    if (task.status.toLowerCase() === 'running' && !activeTaskIds.includes(task.task_id)) {
      return 'completed';
    }
    return task.status;
  };

  const renderFileListTable = (files, title, closeList, canSelectFiles = false) => (
    <div className="mt-4 bg-white rounded-lg shadow-md p-4">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-xl font-semibold text-gray-800">{title}</h3>
        <button
          onClick={closeList}
          className="bg-red-500 text-white px-3 py-1 rounded-md hover:bg-red-600 text-sm"
        >
          Close
        </button>
      </div>
      <div className="overflow-auto" style={{ maxHeight: '25rem' }}>
        <div className="relative">
            <table className="w-full text-xs table-auto bg-white rounded-lg shadow-md">
              <thead className="bg-gray-500 text-white sticky top-0">
                <tr>
                  {canSelectFiles && <th className="px-6 py-3 text-xs text-left"></th>}
                  <th className="px-6 py-2 text-sm text-left">File Name</th>
                  <th className="px-6 py-2 text-sm text-left">Start Time</th>
                  <th className="px-6 py-2 text-sm text-left">End Time</th>
                  <th className="px-6 py-2 text-sm text-left">Duration(s)</th>
                  <th className="px-6 py-2 text-sm text-left">Error Message</th>
                </tr>
              </thead>
              <tbody className="bg-white text-xs divide-y divide-gray-200 overflow-auto">
              {Object.entries(files).map(([fileName, fileData]) => (
                  <tr key={fileName} className="h-10 hover:bg-gray-50">
                    {canSelectFiles && (
                      <td className="px-6 py-2">
                        <input
                          type="checkbox"
                          checked={selectedFiles.includes(fileName)}
                          onChange={() => handleFileSelect(fileName)}
                          className="h-4 w-4"
                        />
                      </td>
                    )}
                    <td className="px-6 py-2 text-sm truncate max-w-xs">{fileName}</td>
                    <td className="px-6 py-2 text-sm">{fileData.start_time}</td>
                    <td className="px-6 py-2 text-sm">{fileData.end_time}</td>
                    <td className="px-6 py-2 text-sm">{fileData.time_spent}</td>
                    <td className="px-6 py-2 text-sm truncate max-w-xs">{fileData.error_message || 'N/A'}</td>
                  </tr>
                ))}
              </tbody>
            </table>
        </div>
      </div>

      {canSelectFiles && (
        <div className="flex justify-between items-center mt-5 mb-2">
          <button
            onClick={() => handleSelectAll(files)}
            className="bg-gray-300 text-gray-800 px-3 py-1 rounded-md hover:bg-gray-400 text-sm"
          >
            {selectedFiles.length === Object.keys(files).length ? 'Deselect All' : 'Select All'}
          </button>
          <button
            onClick={() => {
              rerunSelectedFiles();
            }}
            className="bg-primary text-white px-4 py-2 rounded-md hover:bg-green-700 text-sm"
            disabled={selectedFiles.length === 0}
          >
            Rerun Selected Files
          </button>
        </div>
      )}
    </div>
  );

  const abortTask = async (taskId) => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const creds = user.username;
      const email = user.attributes.email;

      const revokeResponse = await sendRequest(
        {
          task_id: taskId,
          [preferences.system.API_USERNAME_KEYWORD]: creds,
        },
        ENDPOINTS.revoke_task,
      );

      if (revokeResponse.ok) {
        toast.success({
          title: "Success",
          description: `Task ${taskId} has been successfully aborted.`,
        });

        setActiveTaskIds((prevIds) => prevIds.filter((id) => id !== taskId));

        await sendRequest(
          {
            username: creds,
            task_id: taskId,
            status: "aborted",
          },
          ENDPOINTS.update_schedule_task_status,
        );
      } else {
        toast.error({
          title: "Error",
          description: `Failed to abort task ${taskId}. Please try again.`,
        });
      }
    } catch (error) {
      console.error("Error aborting the task:", error);
      toast.error({
        title: "Error",
        description: `An unexpected error occurred while trying to abort task ${taskId}.`,
      });
    }
  };

  if (!isModalOpen) return null;
  return (
    <div className="font-sans bg-gray-50 p-6 rounded-lg w-full h-screen overflow-auto">
      {isLoading ? (
        <div className="flex justify-center items-center h-full text-lg text-gray-700">Loading...</div>
      ) : (
        <div className="flex flex-col h-full w-full gap-2">
          <div className="w-full text-center text-lg font-bold mb-2 ml-3 py-2 flex justify-between items-center text-gray-800 border-b-2 border-gray-300">
            Scheduled Tasks
            <button
              onClick={async () => {
                await fetchScheduledTasks();

                // If there are expanded details, re-fetch the file details
                if (visibleDetails) {
                  await fetchTaskDetails(visibleDetails);
                  setIsDetailsModalOpen(false);
                  setTimeout(() => setIsDetailsModalOpen(true), 0);
                }
              }}
              className="bg-gray-400 text-white px-3 py-2 rounded-md hover:bg-gray-600 text-sm shadow-sm"
            >
              <FontAwesomeIcon icon={faSyncAlt} />
            </button>
          </div>
          {visibleFileList && isDetailsModalOpen && (
            <div className="flex flex-col flex-grow max-h-[80vh] overflow-auto">
              <div className="flex-grow">
                {visibleFileList === 'successFiles' && (
                  <div className="bg-white rounded-lg shadow-md p-4 h-full">
                    {renderFileListTable(detailsData.successFiles, `${detailsData.successCount} files succeeded`, () => toggleFileList('successFiles'))}
                  </div>
                )}
                {visibleFileList === 'failFiles' && (
                  <div className="bg-white rounded-lg shadow-md p-4 h-full">
                    {renderFileListTable(detailsData.failFiles, `${detailsData.failCount} files failed`, () => toggleFileList('failFiles'), true)}
                  </div>
                )}
                {visibleFileList === 'unfinishedFiles' && (
                  <div className="bg-white rounded-lg shadow-md p-4 h-full">
                    {renderFileListTable(detailsData.unfinishedFiles, `${detailsData.unfinishedCount} files unfinished`, () => toggleFileList('unfinishedFiles'), true)}
                  </div>
                )}
              </div>
            </div>
          )}
          {!visibleFileList && (
            <div className="flex flex-col flex-grow h-[calc(100vh-150px)] overflow-auto">
              <div className="w-full">
                <table className="w-full table-auto bg-white rounded-lg shadow-md">
                  <thead className="bg-gray-200 text-black sticky top-0 z-10">
                    <tr>
                      <th className="px-6 py-2 text-sm text-center">Task ID</th>
                      <th className="px-6 py-2 text-sm text-center cursor-pointer" onClick={toggleSortDate}>
                        Schedule Time <SortIcon isAsc={sortDirection === 'asc'} />
                      </th>
                      {/* <th className="px-6 py-2 text-sm text-center">Running Period</th> */}
                      <th className="px-6 py-2 text-sm text-center">Status</th>
                      <th className="px-6 py-2 text-sm text-center">Summary</th>
                      <th className="px-6 py-2 text-sm text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {filteredTasks.length === 0 ? (
                      <tr>
                        <td colSpan={6} className="text-center py-8 text-gray-500">No scheduled tasks</td>
                      </tr>
                      ) : (
                        filteredTasks.map(task => (
                          <tr key={task.task_id} className="hover:bg-gray-100 transition duration-150 ease-in-out text-sm text-center">
                            <td className="px-6 py-2">{task.task_id}</td>
                            <td className="px-6 py-2">{task.scheduled_at}</td>
                            {/* <td className="px-6 py-2">{task.running_period || 'Non-periodic'}</td> */}
                            <td className="px-6 py-2">
                              <div className={`font-bold ${
                                getDisplayStatus(task).toLowerCase() === 'aborted' ? 'text-orange-600' :
                                getDisplayStatus(task).toLowerCase() === 'completed' ? 'text-green-600' :
                                getDisplayStatus(task).toLowerCase() === 'scheduled' ? 'text-yellow-500' :
                                getDisplayStatus(task).toLowerCase() === 'running' ? 'text-blue-600' :
                                'text-red-600'
                              }`}>
                                {getDisplayStatus(task)}
                              </div>
                            </td>
                            <td className="px-6 py-2">
                              <div>
                                <button
                                onClick={() => toggleDetails(task.task_id)}
                                className="ml-4 bg-primary text-white px-4 py-2 rounded-md hover:bg-green-800 text-sm shadow-sm"
                              >
                                {visibleDetails === task.task_id ? 'Hide details' : 'Details'}
                              </button>
                              {visibleDetails === task.task_id && detailsData && (
                                <div className="mt-2 p-3 bg-gray-50 border border-gray-200 rounded-lg shadow-sm">
                                  {detailsData.message ? (
                                    <div className="text-sm mt-2 text-red-600">
                                      <p>
                                        No file details available,<br />please try later
                                      </p>
                                    </div>
                                  ) : (
                                    <div className="text-sm text-gray-700">
                                      <p
                                        onClick={() => toggleFileList('successFiles')}
                                        className="cursor-pointer hover:underline hover:text-blue-600 hover:bg-gray-100 p-2 rounded-md transition-colors duration-200"
                                      >
                                        <strong>{detailsData.successCount}</strong> files succeeded <span className="text-blue-600">›</span>
                                      </p>
                                      <p
                                        onClick={() => toggleFileList('failFiles')}
                                        className="cursor-pointer hover:underline hover:text-blue-600 hover:bg-gray-100 p-2 rounded-md transition-colors duration-200"
                                      >
                                        <strong>{detailsData.failCount}</strong> files failed <span className="text-blue-600">›</span>
                                      </p>
                                      <p
                                        onClick={() => toggleFileList('unfinishedFiles')}
                                        className="cursor-pointer hover:underline hover:text-blue-600 hover:bg-gray-100 p-2 rounded-md transition-colors duration-200"
                                      >
                                        <strong>{detailsData.unfinishedCount}</strong> files unfinished <span className="text-blue-600">›</span>
                                      </p>
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          </td>
                          <td className="px-6 py-4">
                            {activeTaskIds.includes(task.task_id) && (
                              <button
                                onClick={() => abortTask(task.task_id)}
                                className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 text-sm shadow-sm"
                              >
                                Abort
                              </button>
                            )}
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
