import { useEffect, useContext, useMemo, memo, useState } from "react";
import "./Tags.css";
import { COLOURS } from "../../../../../../../assets/colours";
import {
  faTrashAlt,
  faEdit,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DataContext } from "../../../../../../../context/DataContext";
import { TagContext } from "../../../../../../../context/TagContext";
import { PermissionGuard } from "../../../../../../utilities/PermissionGuard";
import { toast } from "../../../../../../utilities/Toast";
import Select from "react-select";
import { customStyleForSelect } from "../../../../../../utilities/SearchBar/SearchBar";

const Tags = memo(
  function Tags({ title, tagTypes, ...props }) {
    const {
      deleteAllLabels,
      currentDataGroup,
      availableTags,
      currentTag,
      setCurrentTag,
      defaultCurrentTag,
    } = useContext(DataContext);
    const {
      setRelatedInfo,
      defaultTagTypes,
      editTag,
      setActiveTab,
      activeTab,
    } = useContext(TagContext);
    const [filter, setFilter] = useState("all");
    const [groupFilter, setGroupFilter] = useState("");
    const [outputTypeFilter, setOutputTypeFilter] = useState("");
    const [optionFilter, setOptionFilter] = useState("");
    const [searchTerm, setSearchTerm] = useState("");

    const tagDict = useMemo(() => {
      return tagTypes.reduce((acc, type) => {
        if (availableTags[type] && availableTags[type].tagger_params) {
          const filteredTags = Object.entries(
            availableTags[type].tagger_params.tag_dict,
          )
            .filter(([_, value]) => {
              if (filter === "all") return true;
              if (filter === defaultTagTypes["classification"])
                return value.tagType === defaultTagTypes["classification"];
              return value.tagType !== defaultTagTypes["classification"];
            })
            .filter(([_, value]) => {
              if (!groupFilter || !groupFilter.value) return true;
              if (groupFilter.value === "Ungrouped") return !value.group;
              return value.group === groupFilter.value;
            })
            .filter(([_, value]) => {
              if (!outputTypeFilter || !outputTypeFilter.value) return true;
              return value.type === outputTypeFilter.value;
            })
            .filter(([_, value]) => {
              if (!optionFilter || !optionFilter.value) return true;
              return value.option === optionFilter.value;
            })
            .reduce((newAcc, [k, v]) => ({ ...newAcc, [k]: v }), {});
          return { ...acc, ...filteredTags };
        }
        return acc;
      }, {});
    }, [
      filter,
      groupFilter,
      outputTypeFilter,
      optionFilter,
      availableTags,
      tagTypes,
    ]);

    const allGroups = useMemo(() => {
      const groups = new Set();
      Object.values(tagDict).forEach((tag) => {
        if (tag.group) {
          groups.add(tag.group);
        } else {
          groups.add("Ungrouped");
        }
      });
      return Array.from(groups).sort((a, b) => a.localeCompare(b));
    }, [tagDict]);

    const groupOptions = useMemo(() => {
      return allGroups.map((group) => ({
        value: group,
        label: group,
      }));
    }, [allGroups]);

    const outputTypeOptions = [
      { value: "word", label: "Word" },
      { value: "number", label: "Number" },
      { value: "date", label: "Date" },
    ];

    const optionOptions = [
      { value: "aiGenerated", label: "Open ended tags" },
      { value: "yesNo", label: "Yes No" },
      { value: "custom", label: "Defined values" },
    ];

    useEffect(() => {
      const calculateRelatedInfo = () => {
        let info = {};
        Object.entries(tagDict).forEach(([key, value]) => {
          let counter = 0;
          let name = value.name;
          let matchingNames = [];

          Object.entries(currentDataGroup).forEach(([groupKey, groupValue]) => {
            if (!groupValue.hasOwnProperty(name)) {
              counter++;
              matchingNames.push(groupKey);
            }
          });

          info[key] = { name, counter, matchingNames };
        });

        setRelatedInfo(info);
      };

      calculateRelatedInfo();
    }, [currentDataGroup, setRelatedInfo, tagDict]);

    const getCircleColor = (type) => {
      return type === defaultTagTypes["classification"]
        ? "bg-primary"
        : "bg-grey-500";
    };

    const handleSearchChange = (e) => {
      setSearchTerm(e.target.value);
    };

    const handleTagDownload = async () => {
      try {
        const tags = tagDict;

        if (typeof tags !== "object") {
          toast.error({
            title: "Error",
            description: `Tags need to be a dictionary.`,
          });
        }

        const data = Object.values(tags).map((tag_info) => ({
          "Tag Name": tag_info.name || "",
          "Type of Tag": tag_info.tagType || "Classification",
          Description: tag_info.description || "",
          "Output constraint type": tag_info.option || "aiGenerated",
          "Output constraint": tag_info.availableValues
            ? tag_info.availableValues.join(",")
            : "",
          "Output type": tag_info.type || "word",
          Group: tag_info.group || "Ungrouped",
        }));

        const examplesData = [];
        Object.values(tags).forEach((tag_info) => {
          const examples = tag_info.examples || [];
          const negExamples = tag_info.neg_examples || [];

          examples.forEach((example) => {
            examplesData.push({
              "Tag Name": tag_info.name || "",
              "Type of Tag": tag_info.tagType || "Classification",
              "Example Type": "correct",
              Evidence: example.evidence || "",
              Output: example.value || "",
            });
          });

          negExamples.forEach((negExample) => {
            examplesData.push({
              "Tag Name": tag_info.name || "",
              "Type of Tag": tag_info.tagType || "Classification",
              "Example Type": "incorrect",
              Evidence: negExample.evidence || "",
              Output: negExample.value || "",
            });
          });
        });

        // Convert data to CSV format
        const tagsCsv = convertToCsv(data);
        const examplesCsv = convertToCsv(examplesData);

        // Create and download tags.csv
        let blob = new Blob([tagsCsv], { type: "text/csv" });
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = `tags.csv`;
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);

        // Create and download examples.csv
        blob = new Blob([examplesCsv], { type: "text/csv" });
        url = window.URL.createObjectURL(blob);
        a = document.createElement("a");
        a.href = url;
        a.download = `tag-examples.csv`;
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
      } catch (e) {
        toast.error({
          title: "Error",
          description: `Error in downloading tags: ${e.message}`,
        });
      }
    };

    // Helper function to convert JSON data to CSV format
    function convertToCsv(data) {
      if (!data || data.length === 0) {
        return "";
      }
      const escapeCsv = (value) => {
        if (typeof value === "string") {
          value = value.replace(/"/g, '""');
          if (
            value.includes(",") ||
            value.includes("\n") ||
            value.includes('"')
          ) {
            value = `"${value}"`;
          }
        }
        return value;
      };
      const headers = Object.keys(data[0]).map(escapeCsv).join(",");
      const rows = data
        .map((row) => Object.values(row).map(escapeCsv).join(","))
        .join("\n");

      return `${headers}\n${rows}`;
    }

    const customSelectStyles = {
      ...customStyleForSelect,
      control: (provided) => ({
        ...provided,
        minHeight: "30px",
        height: "30px",
        width: "100%",
      }),
      valueContainer: (provided) => ({
        ...provided,
        height: "30px",
        padding: "0 6px",
      }),
      input: (provided) => ({
        ...provided,
        margin: "0px",
      }),
      indicatorsContainer: (provided) => ({
        ...provided,
        height: "30px",
      }),
      menu: (provided) => ({
        ...provided,
        width: "100%",
      }),
    };

    return (
      <div
        className={`label-container ${props.isTagLibraryCollapsed ? "collapsed" : "expanded"}`}
      >
        <div
          className="flex flex-row justify-between px-2 items-center text-xs"
          style={{ backgroundColor: COLOURS["HeaderBackground"] }}
        >
          <header
            style={{ color: COLOURS["MainText"], fontWeight: 500 }}
            className="font-bold text-lg"
          >
            {title ? title : "Tag Definitions"}
          </header>
          {props.hasOwnProperty("isTagLibraryCollapsed") &&
            !props.isTagLibraryCollapsed && (
              <button
                onClick={() => props.setIsTagLibraryCollapsed(true)}
                className="text-grey text-base p-1 rounded-md mt-1"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M11 19l-7-7 7-7m8 14l-7-7 7-7"
                  />
                </svg>
              </button>
            )}
        </div>
        <div className="flex flex-col p-2 w-full space-y-2 bg-white">
          <div className="flex items-center space-x-2">
            <input
              type="text"
              placeholder="Search tags..."
              value={searchTerm}
              onChange={handleSearchChange}
              className="flex-grow py-1 px-2 rounded-md outline-none text-xs border border-gray-300"
            />
            <button
              onClick={handleTagDownload}
              className="text-grey p-1 rounded-md hover:bg-gray-100"
              title="Download tags"
            >
              <FontAwesomeIcon icon={faDownload} />
            </button>
          </div>
          <div className="flex space-x-2">
            <Select
              value={groupFilter}
              onChange={setGroupFilter}
              options={groupOptions}
              isClearable
              placeholder="Group"
              className="flex-1 text-xs"
              classNamePrefix="react-select"
              styles={customSelectStyles}
            />
            <Select
              value={outputTypeFilter}
              onChange={setOutputTypeFilter}
              options={outputTypeOptions}
              isClearable
              placeholder="Output type"
              className="flex-1 text-xs"
              classNamePrefix="react-select"
              styles={customSelectStyles}
            />
            <Select
              value={optionFilter}
              onChange={setOptionFilter}
              options={optionOptions}
              isClearable
              placeholder="Tag type"
              className="flex-1 text-xs"
              classNamePrefix="react-select"
              styles={customSelectStyles}
            />
          </div>
        </div>
        <div className="label-list hide-scrollbar bg-white">
          {Object.keys(tagDict).length === 0 ? (
            <div className="flex justify-center items-center h-full shadow-md">
              <p className="text-gray-500 text-base py-3 rounded-md">
                No tags created yet
              </p>
            </div>
          ) : (
            Object.entries(tagDict)
              .sort(([_, tagA], [__, tagB]) => {
                if (!tagA.updated_at) {
                  tagA.updated_at = new Date("1976/01/01").toISOString();
                }
                if (!tagB.updated_at) {
                  tagB.updated_at = new Date("1976/01/01").toISOString();
                }

                return (
                  new Date(tagB.updated_at).getTime() -
                  new Date(tagA.updated_at).getTime()
                );
              })
              .filter(([_, value]) =>
                value.name.toLowerCase().includes(searchTerm.toLowerCase()),
              )
              .map(([key, value]) => {
                return (
                  <div
                    key={key}
                    className={`flex items-center justify-between p-3 my-[8px] rounded-lg shadow-md border-2 ${
                      currentTag.name === value.name
                        ? "bg-green-600 bg-opacity-10"
                        : "bg-white"
                    }`}
                  >
                    <div
                      className="flex flex-1 items-center space-x-2 cursor-pointer"
                      onClick={() => {
                        document.getElementById(value.name).click();
                      }}
                    >
                      <div
                        className={`flex flex-col overflow-hidden transition-max-height duration-500 ease-in-out`}
                        style={{
                          backgroundColor: getCircleColor(value.tagType),
                        }}
                      />
                      <div className="flex flex-col overflow-hidden">
                        <p className="text-xs font-bold text-gray-800 flex-wrap max-w-[15vw] overflow-auto hide-scrollbar">
                          {value.name}
                        </p>
                        <p className="text-xs text-gray-600 break-words max-w-[15vw]">
                          {value.description}
                        </p>
                        <hr className="border-t-2 border-gray-300 mt-2" />
                        <p className="w-full mt-2 mb-1 text-xs text-grey">
                          {value.group || "Ungrouped"}
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center space-x-2 h-full">
                      <PermissionGuard scope="tags" level="canEdit">
                        <>
                          <button
                            className="p-2 rounded-full text-buttonGrey hover:text-primary h-full"
                            onClick={(e) => {
                              editTag(e, value.name);
                              if (activeTab === 1) return;
                              setActiveTab(0);
                            }}
                            id={value.name}
                          >
                            <FontAwesomeIcon icon={faEdit} />
                          </button>
                          <button
                            className="p-2 rounded-full text-buttonGrey hover:text-red-600"
                            onClick={(e) => {
                              deleteAllLabels(e, [key]).then(() =>
                                setCurrentTag({ ...defaultCurrentTag }),
                              );
                            }}
                          >
                            <FontAwesomeIcon icon={faTrashAlt} />
                          </button>
                        </>
                      </PermissionGuard>
                    </div>
                  </div>
                );
              })
          )}
        </div>
      </div>
    );
  },
  (prev, next) => {
    return (
      prev.title === next.title &&
      prev.tagTypes?.length === next.tagTypes?.length &&
      prev.mode === next.mode
    );
  },
);

export default Tags;
