import Auth from "../auth/AuthProvider";
import {
  createContext,
  useContext,
  useState,
  useRef,
} from "react";
import { ENDPOINTS } from "../api/endpoints";
import { sendRequest } from "../components/utilities/functions/api";

import {
  countStatusValues,
  filterCatalogByFailedTag,
  filterDataGroupByRelatedInfo,
  mergeTagWithDefaults,
  updateParentLabelObj,
} from "../components/utilities/functions/utils";
import { DataContext } from "./DataContext";
import {
  uploadTags,
  uploadTag,
  runRule,
  updateCatalog,
} from "../components/utilities/functions/apiCalls";
import { toast } from "../components/utilities/Toast";
import { useAtom } from "jotai";
import { runningTasksAtom, selectedCatalogItemsAtom } from "../atoms";
import { abortTask, waitTaskDone as waitForTaskToEnd } from "../utils/workers";

export const TagContext = createContext();

export const TagProvider = ({ children }) => {
  const {
    setShowScreen,
    currentTag,
    setCurrentTag,
    setCurrentTotalProcessCount,
    setCatalogSummary,
    setCatalogFiles,
    availableTags,
    setAvailableTags,
    currentDataGroup,
    preferences,
    tagReRun,
    failedTags,
    usedCatalog,
    fetchInitialCatalog,
    defaultCurrentTag,
  } = useContext(DataContext);

  const [isActiveAction, setIsActiveAction] = useState(false);
  const [prevCount, setPrevCount] = useState(0);
  const [processingTags, setProcessingTags] = useState([]);
  const [, setRunningTasks] = useAtom(runningTasksAtom);
  const [selectedCatalogItems] = useAtom(selectedCatalogItemsAtom);
  const defaultTagTypes = {
    sensitivity: "Sensitivity",
    classification: "Classification",
  };
  const defaultOutputTypes = {
    word: "Word",
    number: "Number",
    date: "Date",
  };

  const [autoCreatedTags, setAutoCreatedTags] = useState([]);

  const [relatedInfo, setRelatedInfo] = useState({});
  const [tagDict, setTagDict] = useState({
    ...availableTags.sensitivity.tagger_params.tag_dict,
    ...availableTags.llm.tagger_params.tag_dict,
  });
  const [processingTagTasks, setProcessingTagTasks] = useState(new Map());
  const [processingTagTaskProgress, setProcessingTagTaskProgress] = useState(0);
  const [tagStudioResponses, setTagStudioResponses] = useState([]);
  const [tagStudioLoadingStatus, setTagStudioLoadingStatus] = useState({});
  const tagStudioAbortTaskSignal = useRef(new AbortController());
  const [tagStudioCheckedItems, setTagStudioCheckedItems] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [evaluatedScores, setEvaluatedScores] = useState({});
  const [isTagLibraryCollapsed, setIsTagLibraryCollapsed] = useState(false);

  const editTag = (e, name) => {
    e.stopPropagation();
    const selectedTag = mergeTagWithDefaults(
      {
        ...availableTags.llm.tagger_params.tag_dict,
        ...availableTags.sensitivity.tagger_params.tag_dict,
      }[name],
      defaultCurrentTag,
      true,
    );

    setCurrentTag(selectedTag);

    setShowScreen("addNewTag");

    if (activeTab === 2) {
      setActiveTab(0);
    }
  };

  const autoStandardizeTagsWithoutAvailableValues = async () => {
    const rawStandardizeTagResponse = await sendRequest(
      {
        [preferences.system.API_USERNAME_KEYWORD]: (
          await Auth.currentAuthenticatedUser()
        ).username,
        catalog_name: usedCatalog,
      },
      ENDPOINTS["standardize_tag_in_catalog"],
    );
    const standardizeTagResponse = await rawStandardizeTagResponse.json();
    if (!standardizeTagResponse.catalog_changed) {
      return;
    }
    setCatalogSummary(standardizeTagResponse.new_catalog_summary);
    setCatalogFiles(standardizeTagResponse.new_catalog);
    updateCatalog(usedCatalog, standardizeTagResponse.new_catalog);
  };

  const processTag = async (tagMethod, tagKey = null, force = false) => {
    const controller = new AbortController();
    setProcessingTags((prev) => {
      const newTags = [
        ...prev,
        {
          label: tagKey,
        },
      ];

      return newTags;
    });
    setIsActiveAction(true);
    setPrevCount(countStatusValues(currentDataGroup, "Processing").Processing);

    let currentDataGroupSnapshot = { ...currentDataGroup };
    const creds = (await Auth.currentAuthenticatedUser()).username;
    let newTag = {};

    if (tagMethod === "addTag") {
      if (!currentTag.name.trim() || !currentTag.description.trim()) {
        toast.error({
          title: "Error",
          description:
            "Please fill out the name, description, and type of tag before proceeding.",
        });
        return false;
      }
      newTag = { ...currentTag };
      setCurrentTag(preferences.webapp_profile.DEFAULT_TAG);
      const currentTags = availableTags;
      const updatedTags = updateParentLabelObj(currentTags, newTag);

      uploadTags(updatedTags, usedCatalog);
      setAvailableTags(() => {
        console.debug("processtag");
        return updatedTags;
      });
    } else if (tagMethod === "ReRunFailed") {
      currentDataGroupSnapshot = filterCatalogByFailedTag(
        currentDataGroupSnapshot,
        failedTags.get(tagKey) || [],
      );

      newTag = {
        ...availableTags.sensitivity.tagger_params.tag_dict,
        ...availableTags.llm.tagger_params.tag_dict,
      }[tagKey];

      tagReRun(tagKey);
    } else if (tagMethod === "RunAll") {
      newTag = {
        ...availableTags.sensitivity.tagger_params.tag_dict,
        ...availableTags.llm.tagger_params.tag_dict,
      }[tagKey];

      tagReRun(tagKey);
    } else if (!force) {
      if (selectedCatalogItems.size) {
        for (const docKey in currentDataGroupSnapshot) {
          if (selectedCatalogItems.has(docKey)) continue;
          delete currentDataGroupSnapshot[docKey];
        }
      } else {
        currentDataGroupSnapshot = filterDataGroupByRelatedInfo(
          currentDataGroupSnapshot,
          relatedInfo[tagKey].matchingNames,
        );
      }

      newTag = {
        ...availableTags.sensitivity.tagger_params.tag_dict,
        ...availableTags.llm.tagger_params.tag_dict,
      }[tagKey];
    } else {
      currentDataGroupSnapshot = { ...currentDataGroup };
      newTag = {
        ...availableTags.sensitivity.tagger_params.tag_dict,
        ...availableTags.llm.tagger_params.tag_dict,
      }[tagKey];
    }

    setCurrentTotalProcessCount(Object.keys(currentDataGroupSnapshot).length);
    setShowScreen("catalog");
    setCurrentTotalProcessCount(Object.keys(currentDataGroupSnapshot).length);

    const entries = [];

    for (const [id, catalogItem] of Object.entries(currentDataGroupSnapshot)) {
      const sendObject = {
        data_store: JSON.stringify({
          ...preferences.webapp_profile.DATA_STORES[
            catalogItem.data_store_name
              ? catalogItem.data_store_name
              : catalogItem.storage_type
          ],
          path: `${catalogItem.file_directory}/${id}`,
        }),
        tagger_list: JSON.stringify({
          llm: {
            tagger_params: {
              model: {
                provider: preferences.webapp_profile.PROVIDER_USED,
                version: preferences.webapp_profile.MODEL_USED,
              },
              iters: 1,
              tag_dict: { [newTag.name]: newTag },
            },
          },
        }),
        file_catalog_entry: JSON.stringify({ [id]: catalogItem }),
        catalog_name: usedCatalog,
        quarantine_name: preferences.system.QUARANTINECATALOG,
        check_sensitivity: false,
      };

      entries.push(sendObject);
    }

    sendRequest(
      {
        rerun: force,
        entries: entries,
        [preferences.system.API_USERNAME_KEYWORD]: creds,
        preferences: JSON.stringify(preferences),
      },
      ENDPOINTS["create_catalog_in_bulk"],
      undefined,
      undefined,
      controller.signal,
    )
      .then((response) => response.json())
      .then(({ task_id }) => {
        setProcessingTagTasks((prev) => {
          const newMap = new Map(prev);
          newMap.set(tagKey, [...(newMap.get(tagKey) || []), task_id]);
          return newMap;
        });

        return waitForTaskToEnd(task_id, creds, undefined, ({ completed }) => {
          setRunningTasks((prev) => {
            const task = prev.find((t) => t.id === tagKey);
            try {
              task.completed = completed;
            } catch (error) {}
            return [...prev];
          });
        });
      })
      .then(async () => {
        setIsActiveAction(false);
        setProcessingTags((prev) =>
          prev.filter(({ label }) => label !== tagKey),
        );
        setRunningTasks((prev) => {
          const task = prev.find((t) => t.id === tagKey);
          try {
            task.completed = 1;
          } catch (error) {}
          return [...prev];
        });
        setProcessingTagTasks((prev) => {
          const newMap = new Map(prev);
          newMap.delete(tagKey);
          return newMap;
        });

        return await fetchInitialCatalog(usedCatalog);
      })
      .then(() => {
        autoStandardizeTagsWithoutAvailableValues();
      })
      .catch((error) => {
        console.error("Error during processing tag:", error);
      });
  };

  const handleTagTest = async () => {
    const chooseSensitivity = false;
    const is_soft_run = true;

    setTagStudioResponses([]);
    setTagStudioLoadingStatus({});

    const tagAtClick = currentTag;

    if (tagAtClick.name === "") {
      alert("Please create tag details to test.");
      return;
    }
    const creds = (await Auth.currentAuthenticatedUser()).username;
    const tagTestTaskIDs = new Map();
    for (const file_name of Object.keys(tagStudioCheckedItems)) {
      if (tagStudioAbortTaskSignal.current.signal.aborted) {
        for (const [task_id, file_name] of tagTestTaskIDs.entries()) {
          abortTask(task_id, (await Auth.currentAuthenticatedUser()).username);
        }
        setTagStudioLoadingStatus({});
        return;
      }
      setTagStudioLoadingStatus((prevStatus) => ({
        ...prevStatus,
        [file_name]: true,
      }));

      const tagName = tagAtClick.name;
      const usedTags = {
        [tagName]: { ...tagAtClick },
      };

      const availableTagsCopy = JSON.parse(JSON.stringify(availableTags));
      availableTagsCopy.llm.tagger_params.tag_dict = usedTags;
      const path = `${
        preferences.webapp_profile.DATA_STORES[
          tagStudioCheckedItems[file_name].source
        ].base_path
      }${tagStudioCheckedItems[file_name].folder || ""}${file_name}`;

      const cleanPath = path.replace(/([^:]\/)\/+/g, "$1");

      const sendChunkObject = {
        data_store: {
          ...preferences.webapp_profile.DATA_STORES[
            tagStudioCheckedItems[file_name].source
          ],
          path: cleanPath,
        },
        tagger_list: availableTagsCopy,
        [preferences.system.API_USERNAME_KEYWORD]: creds,
        file_catalog_entry: { [file_name]: {} },
        catalog_name: usedCatalog,
        quarantine_name: preferences.system.QUARANTINECATALOG,
        check_sensitivity: chooseSensitivity,
        is_soft_run: is_soft_run,
      };
      const tagTestResponse = await sendRequest(
        sendChunkObject,
        ENDPOINTS["create_catalog"],
      );
      if (!tagTestResponse) {
        console.error(
          "The fetch request failed or returned no tagTestRequest.",
        );
        return;
      }
      if (!tagTestResponse.ok) {
        console.error(
          `HTTP error when creating tag test task! status: ${tagTestResponse.status}`,
        );
        return;
      }
      const taskID = (await tagTestResponse.json()).task_id;
      tagTestTaskIDs.set(taskID, file_name);
    }

    const tagTestTaskIDEntries = tagTestTaskIDs.entries();
    for (const [tagTestTaskId, file_name] of tagTestTaskIDEntries) {
      if (tagStudioAbortTaskSignal.current.signal.aborted) {
        setTagStudioLoadingStatus((prevStatus) => {
          return Object.fromEntries(
            Object.entries(prevStatus).filter(
              ([key, value]) => value === false,
            ),
          );
        });
        break;
      }
      const tagTestResponse = await sendRequest(
        {
          [preferences.system.API_USERNAME_KEYWORD]: creds,
          task_id: tagTestTaskId,
        },
        ENDPOINTS["create_catalog_result"],
      );
      if (!tagTestResponse) {
        console.error(
          "The fetch request failed or returned no tagTestResponse.",
        );
        return;
      }
      if (!tagTestResponse.ok) {
        console.error(
          `HTTP error when fetching tag test task status! status: ${tagTestResponse.status}`,
        );
        console.error("tagTestResponse", tagTestResponse);
        return;
      }
      try {
        const responseJson = await tagTestResponse.json();
        setTagStudioResponses((prev) => [responseJson, ...prev]);
      } catch (error) {
        console.error("Error parsing JSON from tagTestResponse", error);
      }
      setTagStudioLoadingStatus((prevStatus) => ({
        ...prevStatus,
        [file_name]: false,
      }));
    }
    let abortedTagTestTaskID = tagTestTaskIDEntries.next();
    while (!abortedTagTestTaskID.done) {
      if (tagStudioAbortTaskSignal) {
        abortTask(
          abortedTagTestTaskID.value[0],
          (await Auth.currentAuthenticatedUser()).username,
        );
      }
      abortedTagTestTaskID = tagTestTaskIDEntries.next();
    }
  };

  const fetchValidationRecords = async () => {
    const creds = (await Auth.currentAuthenticatedUser()).username;
    const sendDetails = {
      [preferences.system.API_USERNAME_KEYWORD]: creds,
      catalog_name: usedCatalog,
      tag_uuid: currentTag.tag_uuid,
      version_uuid: currentTag.version_uuid,
    };
    try {
      const response = await sendRequest(
        sendDetails,
        ENDPOINTS["get_validation_records"],
      );

      if (!response.ok) {
        console.error("Failed to fetch validation records:", response);
        return { folderKeys: [], validation_records: {} };
      }
      const data = await response.json();
      const validation_records = data.validation_records || {};
      return { validation_records };
    } catch (error) {
      console.error("Error fetching validation records:", error);
    }
  };

  const get_chunk_indices = async (activeTab, filename, validation_records) => {
    const chunks = validation_records.validation_records;
    const filteredRecords = Object.values(chunks).filter(
      (record) =>
        record.filename === filename &&
        (activeTab === 1
          ? record.label === "incorrect"
          : record.label === "correct"),
    );

    const chunkIndices = filteredRecords.map((record) => {
      const [start, end] = record.chunk_id.split("_").map(Number);
      return [start, end];
    });

    return chunkIndices; // format: [(chunk_start_idx, chunk_end_idx), (chunk_start_idx, chunk_end_idx), ...]
  };

  const handleChunkTagTest = async (activeTab) => {
    setTagStudioResponses([]);
    setTagStudioLoadingStatus({});

    if (currentTag.name === "") {
      alert("Please create tag details to test.");
      return;
    }

    const validation_records = await fetchValidationRecords();
    const creds = (await Auth.currentAuthenticatedUser()).username;

    for (const file_name of Object.keys(tagStudioCheckedItems)) {
      setTagStudioLoadingStatus((prevStatus) => ({
        ...prevStatus,
        [file_name]: true,
      }));

      const tagName = currentTag.name;
      const usedTags = {
        [tagName]: { ...currentTag },
      };

      const availableTagsCopy = JSON.parse(JSON.stringify(availableTags));
      availableTagsCopy.llm.tagger_params.tag_dict = usedTags;

      const filterTags = (tagger_params, currentTag) => {
        const filteredTagDict = {};
        Object.keys(tagger_params.tag_dict).forEach((tagName) => {
          if (tagger_params.tag_dict[tagName].name === currentTag.name) {
            filteredTagDict[tagName] = tagger_params.tag_dict[tagName];
          }
        });
        tagger_params.tag_dict = filteredTagDict;
      };

      if (availableTagsCopy.llm && availableTagsCopy.llm.tagger_params) {
        filterTags(availableTagsCopy.llm.tagger_params, currentTag);
      }

      if (
        availableTagsCopy.sensitivity &&
        availableTagsCopy.sensitivity.tagger_params
      ) {
        filterTags(availableTagsCopy.sensitivity.tagger_params, currentTag);
      }

      const chunk_indices = await get_chunk_indices(
        activeTab,
        file_name,
        validation_records,
      );
      const path = `${
        preferences.webapp_profile.DATA_STORES[
          tagStudioCheckedItems[file_name].source
        ].base_path
      }${tagStudioCheckedItems[file_name].folder || ""}${file_name}`;

      const cleanPath = path.replace(/([^:]\/)\/+/g, "$1");

      const sendChunkObject = {
        [preferences.system.API_USERNAME_KEYWORD]: creds,
        data_store: JSON.stringify({
          ...preferences.webapp_profile.DATA_STORES[
            tagStudioCheckedItems[file_name].source
          ],
          path: cleanPath,
        }),
        tagger_list: JSON.stringify(availableTagsCopy),
        file_catalog_entry: JSON.stringify({ [file_name]: {} }),
        catalog_name: usedCatalog,
        chunk_indices: chunk_indices,
      };
      const response = await sendRequest(
        sendChunkObject,
        ENDPOINTS["create_catalog_by_chunks"],
      );

      if (!response) {
        console.error("The fetch request failed or returned no response.");
        return;
      }

      if (!response.ok) {
        console.error(`HTTP error! status: ${response.status}`);
        return;
      }

      try {
        const responseJson = await response.json();
        setTagStudioResponses((prev) => [...prev, responseJson]);
      } catch (error) {
        console.error("Error parsing JSON from response", error);
      }
      setTagStudioLoadingStatus((prevStatus) => ({
        ...prevStatus,
        [file_name]: false,
      }));
    }
  };

  const runSingleRule = async (catalog, output_tag) => {
    toast.info({
      title: "Info",
      description: "Applying rule on catalog...",
    });

    await runRule(catalog, usedCatalog, output_tag);

    toast.info({
      title: "Info",
      description: "Rule ran successfully!",
    });
  };

  const validateTag = (tag) => {
    const hasValidName =
      tag.name && typeof tag.name === "string" && tag.name.trim();
    const hasValidDescription =
      tag.description &&
      typeof tag.description === "string" &&
      tag.description.trim();
    const hasValidTagType =
      tag.tagType && typeof tag.tagType === "string" && tag.tagType.trim();

    return hasValidName && hasValidDescription && hasValidTagType;
  };

  const updateNonEditedTags = async (tags) => {
    let updatedTags = availableTags;
    Object.values(tags).forEach((tag) => {
      updatedTags = updateParentLabelObj(updatedTags, tag);
    });
    await uploadTags(updatedTags, usedCatalog);
    setAvailableTags(updatedTags);
  };

  const saveTag = async (
    currentTag,
    currentUpdatedTags = null,
    silentSave = false,
  ) => {
    const newTag = { ...currentTag };
    if (!validateTag(newTag)) {
      return false;
    }
    toast.info({
      title: "Info",
      description: "Preparing tag to be saved.",
    });

    const validExamples = (newTag.examples || []).filter((example) => {
      const exampleValue = Array.isArray(example.value)
        ? example.value.join(", ")
        : example.value;

      return (
        example.evidence &&
        example.evidence.trim() !== "" &&
        exampleValue.trim() !== ""
      );
    });

    newTag.examples = validExamples;

    const validNegExamples = (newTag.neg_examples || []).filter((example) => {
      const exampleValue = Array.isArray(example.value)
        ? example.value.join(", ")
        : example.value;

      return (
        example.evidence &&
        example.evidence.trim() !== "" &&
        exampleValue.trim() !== ""
      );
    });

    newTag.neg_examples = validNegExamples;

    // ensure the tag name does not contain a comma then toast a warning and return
    if (newTag.name.includes(",")) {
      toast.error({
        title: "Error",
        description: "Tag name cannot contain a comma.",
      });
      return false;
    }

    if (newTag.type === "date" && !newTag.date_format) {
      newTag.date_format = "MM/DD/YYYY";
    }

    if (newTag.availableValues) {
      const normalizedValues = newTag.availableValues.map((val) =>
        val.toLowerCase(),
      );
      newTag.max_words =
        newTag.availableValues.length === 2 &&
        ["yes", "no"].every((val) => normalizedValues.includes(val))
          ? 1
          : newTag.availableValues.length;
    }

    const mergedTag = mergeTagWithDefaults(newTag, defaultCurrentTag);
    const currentTags = currentUpdatedTags || availableTags;
    const updatedTags = updateParentLabelObj(currentTags, mergedTag);
    await uploadTag(mergedTag, usedCatalog);

    setAvailableTags(updatedTags);

    if (!silentSave) {
      toast.success({
        title: "Success",
        description: "Tag saved successfully!",
      });
    }

    return updatedTags;
  };

  return (
    <TagContext.Provider
      value={{
        // Getters
        relatedInfo,
        tagDict,
        isActiveAction,
        prevCount,
        processingTags,
        defaultTagTypes,
        defaultOutputTypes,
        tagStudioResponses,
        tagStudioLoadingStatus,
        tagStudioCheckedItems,
        processingTagTasks,
        processingTagTaskProgress,
        activeTab,
        autoCreatedTags,
        evaluatedScores,
        isTagLibraryCollapsed,
        tagStudioAbortTaskSignal,
        // Setters
        setAutoCreatedTags,
        setIsTagLibraryCollapsed,
        setEvaluatedScores,
        setActiveTab,
        setIsActiveAction,
        setTagStudioCheckedItems,
        setRelatedInfo,
        setPrevCount,
        setTagDict,
        setProcessingTags,
        setProcessingTagTaskProgress,
        setTagStudioResponses,
        setTagStudioLoadingStatus,
        setProcessingTagTasks,
        // Functions
        processTag,
        updateNonEditedTags,
        saveTag,
        runSingleRule,
        handleTagTest,
        handleChunkTagTest,
        editTag,
        autoStandardizeTagsWithoutAvailableValues,
      }}
    >
      {children}
    </TagContext.Provider>
  );
};
