import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFileIconMapper } from "./hooks";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { colors } from "../../../twExtend";

export const FileCheckbox = ({ fileName, isSelected, onClick }) => {
  const { getIconFor } = useFileIconMapper();

  const checkboxStyle = {
    width: "16px",
    height: "16px",
    border: "1px solid #d1d5db",
    borderRadius: "4px",
    backgroundColor: isSelected ? colors.primary : "white",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "14px",
    cursor: "pointer",
    transition: "all 200ms",
  };

  const iconStyle = {
    color: "white",
    fontSize: "12px",
    display: isSelected ? "block" : "none",
  };

  return (
    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
      <div style={checkboxStyle} onClick={(e) => onClick(e, fileName)}>
        <FontAwesomeIcon icon={faCheck} style={iconStyle} />
      </div>
      <FontAwesomeIcon
        icon={getIconFor(fileName)}
        style={{ fontSize: "1.25rem" }}
      />
      <label className="cursor-pointer select-none" onClick={(e) => onClick(e, fileName)}>
        {fileName}
      </label>
    </div>
  );
};
