import {
  fetchDocumentContent,
  updateCatalog,
} from "../../../../../../../../utilities/functions/apiCalls";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect, useMemo, useRef, useContext } from "react";
import {
  faEye,
  faThumbsUp,
  faLock,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { sendRequest } from "../../../../../../../../utilities/functions/api";
import { ENDPOINTS } from "../../../../../../../../../api/endpoints";
import Auth from "../../../../../../../../../auth/AuthProvider";
import "./QuarantineListItem.css";
import { DataContext } from "../../../../../../../../../context/DataContext";
import { PermissionGuard } from "../../../../../../../../utilities/PermissionGuard";

export default function QuarantineListItem({
  fileName,
  updateAccessGroup,
  ...props
}) {
  const [showChunkDetails, setShowChunkDetails] = useState(false);
  const [showRunAll, setShowRunAll] = useState(false);
  const [hasUnprocessedChunks, setHasUnprocessedChunks] = useState(false);
  const [currentLoadingIndex, setCurrentLoadingIndex] = useState(null);
  const chunkDetailsRef = useRef(null);

  const {
    quarantinedFiles,
    setQuarantinedFiles,
    preferences,
    deleteMultipleQuarantine,
  } = useContext(DataContext);

  const chunkYesEntries = useMemo(() => {
    if (!quarantinedFiles[fileName]) {
      return {};
    }

    return Object.entries(quarantinedFiles[fileName].chunks || {}).reduce(
      (acc, [chunkIndex, chunkData]) => {
        acc[chunkIndex] = Object.entries(chunkData)
          .filter(([key, value]) => value[0] === "Yes")
          .map(([key, value]) => ({
            key,
            evidence: value[1],
            certainty: value[2],
          }));
        return acc;
      },
      {},
    );
  }, [quarantinedFiles, fileName, preferences.system.SENSITIVITY_TAGS]);

  const getConditionalClass = (baseClass) => {
    return `${baseClass} ${showChunkDetails ? "disabled-element" : ""}`;
  };

  useEffect(() => {
    const fileChunks = quarantinedFiles[fileName]?.chunks || {};
    const unprocessed = Object.entries(fileChunks).some(
      ([_, chunkData]) => chunkData.status === "NotProcessed",
    );
    setHasUnprocessedChunks(unprocessed);
  }, [quarantinedFiles, fileName, preferences.system.SENSITIVITY_TAGS]);

  const handleChunkDetailsClick = (e) => {
    e.stopPropagation();
    setShowChunkDetails((prev) => !prev);
    setShowRunAll((prev) => !prev);
  };

  const toggleReviewStatus = async (chunkIndex) => {
    let updatedFiles = { ...quarantinedFiles };
    let currentChunk = updatedFiles[fileName].chunks[chunkIndex];
    currentChunk.reviewed = !currentChunk.reviewed;

    setQuarantinedFiles(updatedFiles);
    props.setFilteredFiles(updatedFiles);
    await updateCatalog(preferences.system.QUARANTINECATALOG, updatedFiles);
  };

  const handleGroupChange = (e, fileName) => {
    const selectedOptions = Array.from(
      e.target.selectedOptions,
      (option) => option.value,
    );

    const currentAccessGroup = quarantinedFiles[fileName].access_group || [];

    const newAccessGroup = selectedOptions
      .filter((option) => !currentAccessGroup.includes(option))
      .concat(
        currentAccessGroup.filter(
          (option) => !selectedOptions.includes(option),
        ),
      );

    const updatedAccess = {
      ...quarantinedFiles,
      [fileName]: {
        ...quarantinedFiles[fileName],
        access_group: newAccessGroup,
      },
    };

    setQuarantinedFiles(updatedAccess);
  };

  const runAllChunksSensitivity = async (fileName, ogChunkKey) => {
    props.setIsScanning(true);
    try {
      const creds = (await Auth.currentAuthenticatedUser()).username;
      const FileToRun = quarantinedFiles[fileName];

      let chunksToProcess;
      if (FileToRun.chunks && Object.keys(FileToRun.chunks).length > 0) {
        chunksToProcess = Object.keys(FileToRun.chunks).map((ogChunkKey) =>
          ogChunkKey.split("_").map(Number),
        );
      } else {
        const rawChunksResponse = await sendRequest(
          {
            data_store: JSON.stringify({
              ...preferences.webapp_profile.DATA_STORES[
                FileToRun.data_store_name
                  ? FileToRun.data_store_name
                  : FileToRun.storage_type
              ],
              path: `${FileToRun.file_directory}/${fileName}`,
            }),
            [preferences.system.API_USERNAME_KEYWORD]: creds,
          },
          ENDPOINTS["create_chunks"],
        );

        const chunksResponse = await rawChunksResponse.json();
        chunksToProcess = chunksResponse.chunk_list;
      }

      const fullText = await fetchDocumentContent(
        `${FileToRun.file_directory}/${fileName}`,
        preferences.webapp_profile.DATA_STORES,
        ["none"],
        false,
        preferences.webapp_profile.DATA_STORES[
          quarantinedFiles[fileName].data_store_name
            ? quarantinedFiles[fileName].data_store_name
            : quarantinedFiles[fileName].storage_type
        ],
      );

      let aggregatedResult = { [fileName]: quarantinedFiles[fileName] };
      let updatedFiles = { ...quarantinedFiles };

      for (const chunk of chunksToProcess) {
        const chunkKey = `${chunk[0]}_${chunk[1]}`;
        const chunkData = FileToRun.chunks[chunkKey];

        if (
          chunkData &&
          Object.keys(chunkData).some((key) =>
            preferences.system.SENSITIVITY_TAGS.hasOwnProperty(key),
          )
        ) {
          continue;
        }

        setCurrentLoadingIndex(chunkKey);

        const sensitivityCheckResponse = await sendRequest(
          {
            data_store: JSON.stringify({
              ...preferences.webapp_profile.DATA_STORES[
                FileToRun.data_store_name
                  ? FileToRun.data_store_name
                  : FileToRun.storage_type
              ],
              path: `${FileToRun.file_directory}/${fileName}`,
            }),
            chunk_indeces: [chunk],
            [preferences.system.API_USERNAME_KEYWORD]: creds,
            full_text: fullText,
            tagger_list: JSON.stringify({ ...preferences.system.TAGGER_LIST }),
            file_catalog_entry: JSON.stringify({
              [fileName]: aggregatedResult,
            }),
            catalog_name: preferences.system.QUARANTINECATALOG,
            endpoint: preferences.profile.OPENAI_ENDPOINTS[0],
          },
          ENDPOINTS["check_chunk_sensitivity"],
        );

        const sensitivity = await sensitivityCheckResponse.json();
        updatedFiles[fileName] = {
          ...updatedFiles[fileName],
          chunks: {
            ...updatedFiles[fileName].chunks,
            [chunkKey]: { ...sensitivity.catalog[fileName].chunks[chunkKey] },
          },
        };

        setQuarantinedFiles(updatedFiles);

        await updateCatalog(preferences.system.QUARANTINECATALOG, updatedFiles);
      }
    } catch (error) {
      console.log("Error during the request", error);
    } finally {
      setCurrentLoadingIndex(null);
      props.setIsScanning(false);
    }
  };

  return (
    <div className={`p-4 ${props.index % 2 ? "bg-slate-200" : "bg-slate-100"}`}>
      <div className="flex justify-between w-full overflow-hidden gap-4">
        <button
          className={getConditionalClass("previewButton-quarantine")}
          onClick={() => props.getDocumentContent(fileName)}
          title="Feature currently unavailable"
          disabled={true} // TODO: to be changed later once preview is centralized
        >
          <FontAwesomeIcon icon={faEye} />
        </button>
        <span className="text-left align-middle flex w-full">{fileName}</span>
        <button
          className="details-button px-4 py-2 whitespace-nowrap text-sm"
          onClick={(e) => handleChunkDetailsClick(e)}
        >
          Reason to quarantine
        </button>
        <PermissionGuard scope="catalogs" level="canEdit">
          <div className="flex">
            <button
              className={`${getConditionalClass(
                "text-white rounded-l-md px-4 py-2 whitespace-nowrap hover:bg-opacity-80 transition-all",
              )} bg-primary`}
              onClick={() => props.approveFiles([fileName])}
            >
              <FontAwesomeIcon icon={faThumbsUp} />
            </button>
            <button
              className={`${getConditionalClass(
                "text-white rounded-r-md px-4 py-2 whitespace-nowrap hover:bg-opacity-80 transition-all",
              )} bg-red-400`}
              onClick={() => deleteMultipleQuarantine([fileName])}
            >
              <FontAwesomeIcon icon={faLock} />
            </button>
          </div>
        </PermissionGuard>
      </div>
      {showChunkDetails && (
        <div ref={chunkDetailsRef} className="h-full py-8">
          {quarantinedFiles[fileName].chunks &&
            Object.entries(quarantinedFiles[fileName].chunks).map(
              ([chunkIndex, chunkData], sectionIndex) => {
                const chunkEntries = chunkYesEntries[chunkIndex] || [];
                const isChunkNotProcessed = chunkData.status === "NotProcessed";
                if (
                  !isChunkNotProcessed &&
                  hasUnprocessedChunks &&
                  sectionIndex === 0
                ) {
                  return (
                    <div className="quarantine-list-item-parent">
                      <div className="run-all-container">
                        <button
                          className={`run-all-button ${
                            props.isScanning ? "scanning" : ""
                          }`}
                          onClick={() =>
                            runAllChunksSensitivity(fileName, chunkIndex)
                          }
                        >
                          {props.isScanning ? "Scanning" : "Scan all chunks"}
                        </button>
                      </div>
                      <div key={chunkIndex} className="chunk-section">
                        <div className="chunk-status">
                          {currentLoadingIndex === chunkIndex && (
                            <FontAwesomeIcon icon={faSpinner} spin />
                          )}
                        </div>
                        <div className="chunk-alignment">
                          <button
                            className="previewButton-quarantine"
                            onClick={() =>
                              props.getDocumentContent(
                                fileName,
                                chunkIndex,
                                chunkEntries,
                              )
                            }
                            title="Feature currently unavailable"
                            disabled={true} // TODO: to be changed later once preview is centralized
                          >
                            <FontAwesomeIcon icon={faEye} />
                          </button>
                          <h4 className="chunk-title">
                            Section {sectionIndex + 1}
                          </h4>

                          {!isChunkNotProcessed && (
                            <button
                              className={
                                chunkData.reviewed
                                  ? "review-button-reviewed bg-primary"
                                  : "review-button-unreviewed"
                              }
                              onClick={() => toggleReviewStatus(chunkIndex)}
                            >
                              {chunkData.reviewed
                                ? "Reviewed"
                                : "Mark as Reviewed"}
                            </button>
                          )}
                        </div>

                        {isChunkNotProcessed ? (
                          <div className="no-sensitive-info">
                            This chunk has not been scanned yet{" "}
                            {isChunkNotProcessed}
                          </div>
                        ) : chunkEntries.length === 0 ? (
                          <div className="no-sensitive-info">
                            This chunk contains no sensitive information
                          </div>
                        ) : (
                          chunkEntries.map(
                            ({ key, evidence, certainty }, index) => (
                              <div key={index} className="chunk-entry">
                                <strong>{key}:</strong>
                                {<div>Evidence: {evidence}</div>}
                                {<div>Risk: {certainty}</div>}
                              </div>
                            ),
                          )
                        )}
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div key={chunkIndex} className="chunk-section">
                      <div className="chunk-status">
                        {currentLoadingIndex === chunkIndex && (
                          <FontAwesomeIcon icon={faSpinner} spin />
                        )}
                      </div>
                      <div className="chunk-alignment gap-4">
                        <button
                          className="previewButton-quarantine"
                          onClick={() =>
                            props.getDocumentContent(
                              fileName,
                              chunkIndex,
                              chunkEntries,
                            )
                          }
                          title="Feature currently unavailable"
                          disabled={true} // TODO: to be changed later once preview is centralized
                        >
                          <FontAwesomeIcon icon={faEye} />
                        </button>
                        <h4 className="chunk-title">
                          Section {sectionIndex + 1}
                        </h4>

                        {!isChunkNotProcessed && (
                          <button
                            className={
                              chunkData.reviewed
                                ? "review-button-reviewed bg-primary"
                                : "review-button-unreviewed"
                            }
                            onClick={() => toggleReviewStatus(chunkIndex)}
                          >
                            {chunkData.reviewed
                              ? "Reviewed"
                              : "Mark as Reviewed"}
                          </button>
                        )}
                      </div>

                      {isChunkNotProcessed ? (
                        <div className="no-sensitive-info">
                          This chunk has not been scanned yet{" "}
                          {isChunkNotProcessed}
                        </div>
                      ) : chunkEntries.length === 0 ? (
                        <div className="no-sensitive-info">
                          This chunk contains no sensitive information
                        </div>
                      ) : (
                        chunkEntries.map(
                          ({ key, evidence, certainty }, index) => (
                            <div key={index} className="chunk-entry">
                              <strong>{key}:</strong>
                              {<div>Evidence: {evidence}</div>}
                              {<div>Risk: {certainty}</div>}
                            </div>
                          ),
                        )
                      )}
                    </div>
                  );
                }
              },
            )}
        </div>
      )}
    </div>
  );
}
