import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import React, { useState, useContext } from "react";
import { DataContext } from "../../../../../../context/DataContext";
import { uploadTags } from "../../../../../utilities/functions/apiCalls";
import { toast } from "../../../../../utilities/Toast";


type ApiTypeOptions = {
  model_used: string[];
  embed_model_used: string[];
  rpm: number;
  tpm: number;
};

type ApiTypeOptionMap = {
  [key: string]: ApiTypeOptions;
};

type DataRow = {
  api_type: string;
  base_url?: string;
  embed_model?: string;
  model?: string;
  api_version?: string;
  api_key?: string;
  model_used?: string;
  embed_model_used?: string;
  enabled?: boolean;
  rpm?: number;
  tpm?: number;
};

type DynamicTableProps = {
  initialData: DataRow[];
  onDataChange: (newData: DataRow[]) => void;
};

function DynamicTable({ initialData, onDataChange }: DynamicTableProps) {
  
  const {
    availableTags,
    setAvailableTags,
    usedCatalog,
  } = useContext(DataContext);

  const tagDict = {
    ...availableTags.llm.tagger_params.tag_dict,
  };

  const staticHeaders: string[] = [
    "api_type",
    "base_url",
    "embed_model",
    "model",
    "api_version",
    "api_key",
    "model_used",
    "embed_model_used",
    "rpm",
    "tpm",
    "enabled",
  ];

  const endpointHeadings: any = {
    api_type: "Provider",
    base_url: "Base URL",
    embed_model: "Embedding Model Deployed",
    model: "Model Deployed",
    api_version: "API Version",
    api_key: "API Key",
    model_used: "Model",
    embed_model_used: "Embedding model",
    enabled: "Enabled",
    rpm: "RPM",
    tpm: "TPM",
  };

  const apiTypeOptions: ApiTypeOptionMap = {
    openai: {
      model_used: [
        "gpt-4o-mini",
        "gpt-4o",
        "gpt-4o-2024-05-13",
        "gpt-4-turbo-preview",
        "gpt-4-0125-preview",
        "gpt-4-1106-preview",
        "gpt-4",
        "gpt-4-0613",
        "gpt-4-32k",
        "gpt-4-32k-0613",
        "gpt-3.5-turbo-0125",
        "gpt-3.5-turbo",
        "gpt-3.5-turbo-1106",
        "gpt-3.5-turbo-instruct",
      ],
      embed_model_used: [
        "text-embedding-3-large",
        "text-embedding-3-small",
        "text-embedding-ada-002",
      ],
      rpm: 10000,
      tpm: 10000000,
    },
    azure: {
      model_used: [
        "gpt-4",
        "gpt-4-32k",
        "gpt-35-turbo",
        "gpt-35-turbo-16k",
        "gpt-35-turbo-instruct",
      ],
      embed_model_used: ["text-embedding-ada-002"],
      rpm: 1000,
      tpm: 1000000,
    },
    bedrock: {
      model_used: [
        "anthropic.claude-3-sonnet-20240229-v1:0",
        "anthropic.claude-3-haiku-20240307-v1:0",
        "anthropic.claude-v2:1",
        "anthropic.claude-instant-v1",
        "ai21.j2-mid-v1",
        "ai21.j2-ultra-v1",
        "cohere.command-text-v14",
        "mistral.mistral-7b-instruct-v0:2",
        "mistral.mixtral-8x7b-instruct-v0:1",
        "mistral.mistral-large-2402-v1:0",
        "meta.llama3-8b-instruct-v1:0",
        "meta.llama3-70b-instruct-v1:0",
      ],
      embed_model_used: [
        "amazon.titan-embed-text-v1",
        "amazon.titan-embed-image-v1",
      ],
      rpm: 1000,
      tpm: 100000,
    },
    cohere: {
      model_used: [
        "command-r",
        "command-light",
        "command-r-plus",
        "command-medium",
        "command-medium-beta",
        "command-xlarge-nightly",
        "command-nightly",
      ],
      embed_model_used: [
        "embed-english-v3.0",
        "embed-english-light-v3.0",
        "embed-multilingual-v3.0",
        "embed-multilingual-light-v3.0",
        "embed-english-v2.0",
        "embed-english-light-v2.0",
        "embed-multilingual-v2.0",
      ],
      rpm: 1000,
      tpm: 100000,
    },
    mistral: {
      model_used: [
        "mistral/mistral-small-latest",
        "mistral/mistral-medium-latest",
        "mistral/mistral-large-latest",
        "mistral/open-mistral-7b",
        "mistral/open-mixtral-8x7b",
        "mistral/open-mixtral-8x22b",
      ],
      embed_model_used: ["mistral/mistral-embed"],
      rpm: 1000,
      tpm: 100000,
    },
    anthropic: {
      model_used: [
        "claude-3-haiku-20240307",
        "claude-3-opus-20240229",
        "claude-3-sonnet-20240229",
      ],
      embed_model_used: ["text-embedding-ada-002"],
      rpm: 1000,
      tpm: 100000,
    },
    gemini: {
      model_used: [
        "gemini-1.5-pro-preview-0514",
        "gemini-pro",
        "gemini-1.5-flash-preview-0514",
      ],
      embed_model_used: ["text-embedding-004`"],
      rpm: 5000,
      tpm: 1000000,
    },
  };

  const editableFields = {
    openai: [
      "api_type",
      "model_used",
      "embed_model_used",
      "api_key",
      "enabled",
      "rpm",
      "tpm",
    ],
    bedrock: [
      "api_type",
      "model_used",
      "embed_model_used",
      "enabled",
      "rpm",
      "tpm",
    ],
    azure: [
      "api_type",
      "model_used",
      "embed_model_used",
      "api_key",
      "base_url",
      "api_version",
      "enabled",
      "model",
      "embed_model",
      "rpm",
      "tpm",
    ],
    cohere: [
      "api_type",
      "model_used",
      "embed_model_used",
      "api_key",
      "enabled",
    ],
    mistral: [
      "api_type",
      "model_used",
      "embed_model_used",
      "api_key",
      "enabled",
      "rpm",
      "tpm",
    ],
    anthropic: [
      "api_type",
      "model_used",
      "embed_model_used",
      "api_key",
      "enabled",
      "rpm",
      "tpm",
    ],
    gemini: [
      "api_type",
      "model_used",
      "embed_model_used",
      "api_key",
      "enabled",
      "rpm",
      "tpm",
    ],
  };

  const alignDataWithHeaders = (data: DataRow[]): DataRow[] => {
    return data.map((item) => {
      const alignedItem: Partial<DataRow> = {};

      staticHeaders.forEach((header) => {
        const value: string | boolean =
          (item as any)[header] ?? (header === "enabled" ? true : "");
        (alignedItem as any)[header] = value as DataRow[keyof DataRow];
      });

      return alignedItem as DataRow;
    });
  };

  const [tableData, setTableData] = useState<DataRow[]>(() =>
    alignDataWithHeaders(initialData),
  );

  const handleRowChange = <K extends keyof DataRow>(
    index: number,
    key: K,
    value: DataRow[K],
  ) => {
    const newData = [...tableData];
    const isOnlyEnabled = tableData.filter(row => row.enabled).length === 1;
  
    if (key === "enabled" && value === false) {
      if (isOnlyEnabled && tableData[index].enabled) {
        alert("You must have at least one enabled model.");
        return;
      }
  
      const endpointModel = tableData[index].model_used;
  
      if (!endpointModel) {
        console.warn("Endpoint model is undefined, cannot proceed with disabling.");
        return;
      }
  
      const associatedTags = Object.values(tagDict).filter((tag) => {
        const typedTag = tag as Tag;
        return Array.isArray(typedTag.selected_tag_model) && typedTag.selected_tag_model.includes(endpointModel);
      });
  
      if (associatedTags.length > 0) {
        const confirmDisable = window.confirm(
          `${associatedTags.length} tag(s) are associated with this endpoint. Are you sure you want to disable it? This will remove the associated model from these tags.`
        );
  
        if (!confirmDisable) {
          return;
        }

        // remove the endpoint model from the selected_tag_model array of each tag
        associatedTags.forEach((tag) => {
          const typedTag = tag as Tag;
          typedTag.selected_tag_model = typedTag.selected_tag_model?.filter(model => model !== endpointModel) || null;
        });
        
        const updatedTagDict = {
          ...tagDict,
          ...Object.fromEntries(Object.entries(tagDict).map(([key, tag]) => {
            const typedTag = tag as Tag;
            if (Array.isArray(typedTag.selected_tag_model)) {
              typedTag.selected_tag_model = typedTag.selected_tag_model.filter(model => model !== endpointModel);
            }
            return [key, typedTag];
          })),
        };

        const updatedAvailableTags = {
          ...availableTags,
          llm: {
            ...availableTags.llm,
            tagger_params: {
              ...availableTags.llm.tagger_params,
              tag_dict: updatedTagDict,
            },
          },
        };

        setAvailableTags(updatedAvailableTags);
        try {
          uploadTags(updatedAvailableTags, usedCatalog);
        } catch (error) {
          toast.error({
            title: "Error",
            description: "Error updating tag models",
          });
        }
      }
    }
  
    newData[index][key] = value;

    if (key === "api_type") {
      const selectedApiType = value as string;
      newData[index].model_used = apiTypeOptions[selectedApiType].model_used[0];
      newData[index].embed_model_used =
        apiTypeOptions[selectedApiType].embed_model_used[0];

      newData[index].rpm = apiTypeOptions[selectedApiType].rpm;
      newData[index].tpm = apiTypeOptions[selectedApiType].tpm;
    }

    setTableData(newData);
    onDataChange(newData);
  };

  const renderCellInput = (
    rowIndex: number,
    header: keyof DataRow,
    value: string | boolean,
  ) => {
    const apiType = tableData[rowIndex].api_type;

    const handleRowChangeWrapper = (
      e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    ) => {
      let newValue: string | boolean;
      if (header === "enabled" && e.target.type === "checkbox") {
        newValue = (e.target as HTMLInputElement).checked;
      } else {
        newValue = e.target.value;
      }
      handleRowChange(rowIndex, header, newValue as any);
    };

    const isDisabled = !(editableFields as any)[apiType]?.includes(header);
    const disabledClasses =
      "bg-gray-200 text-gray-500 cursor-not-allowed border-gray-300";
    const enabledClasses = "bg-white border-gray-200";
    const commonClasses =
      "form-input block w-full mt-1 border-2 rounded-lg shadow focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50 py-2 text-sm";

    const finalClasses = `${commonClasses} ${isDisabled ? disabledClasses : enabledClasses}`;
    const valueToShow = isDisabled ? "" : String(value);

    if (header === "api_type") {
      return (
        <select
          className="form-select block w-full mt-1 border-2 border-gray-200 rounded-lg shadow focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50 py-2 text-sm leading-tight bg-white"
          value={String(value)}
          onChange={handleRowChangeWrapper}
        >
          <option value="openai">OpenAI</option>
          <option value="azure">Azure</option>
          <option value="bedrock">Bedrock</option>
          <option value="cohere">Cohere</option>
          <option value="mistral">Mistral</option>
          <option value="anthropic">Anthropic</option>
          <option value="gemini">Gemini</option>
        </select>
      );
    } else if (
      (header === "model_used" || header === "embed_model_used") &&
      apiType !== "azure"
    ) {
      const options = apiTypeOptions[apiType]?.[header];
      const hasOptions = Array.isArray(options);

      if (!hasOptions) {
        return <span>No options available</span>;
      }

      return (
        <select
          disabled={isDisabled}
          className={finalClasses}
          value={String(value)}
          onChange={handleRowChangeWrapper}
        >
          {options.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      );
    } else if (header === "enabled") {
      return (
        <input
          type="checkbox"
          disabled={isDisabled}
          className="appearance-none w-full h-5 flex-1 border border-gray-300 rounded-sm bg-white checked:bg-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 transition duration-200 ease-in-out cursor-pointer"
          checked={Boolean(value)}
          onChange={handleRowChangeWrapper}
        />
      );
    } else if (header === "tpm" || header === "rpm") {
      return (
        <input
          type="number"
          disabled={isDisabled}
          className={finalClasses}
          value={valueToShow}
          onChange={handleRowChangeWrapper}
        />
      );
    } else {
      return (
        <input
          type="text"
          disabled={isDisabled}
          className={finalClasses}
          value={valueToShow}
          onChange={handleRowChangeWrapper}
        />
      );
    }
  };

  const addRow = () => {
    const defaultApiType = "openai";
    const newRow: DataRow = staticHeaders.reduce<DataRow>((acc, header) => {
      if (header === "model_used") {
        acc[header] = apiTypeOptions[defaultApiType].model_used[0];
      } else if (header === "embed_model_used") {
        acc[header] = apiTypeOptions[defaultApiType].embed_model_used[0];
      } else if (header === "rpm") {
        acc[header] = apiTypeOptions[defaultApiType].rpm;
      } else if (header === "tpm") {
        acc[header] = apiTypeOptions[defaultApiType].tpm;
      } else {
        (acc as any)[header] =
          header === "enabled"
            ? true
            : header === "api_type"
              ? defaultApiType
              : "";
      }
      return acc;
    }, {} as DataRow);
    const updatedTableData = [...tableData, newRow];
    setTableData(updatedTableData);
    onDataChange(updatedTableData);
  };

  interface Tag {
    selected_tag_model: string[] | null;
  }

  const deleteRow = (index: number) => {
    const isOnlyEnabled = tableData.filter(row => row.enabled).length === 1;
  
    if (isOnlyEnabled && tableData[index].enabled) {
      alert("You must have at least one enabled model.");
      return;
    }
  
    const endpointModel = tableData[index].model_used;
  
    if (!endpointModel) {
      console.warn("Endpoint model is undefined, cannot proceed with deletion.");
      return;
    }
  
    const associatedTags = Object.values(tagDict).filter((tag) => {
      const typedTag = tag as Tag;
      return Array.isArray(typedTag.selected_tag_model) && typedTag.selected_tag_model.includes(endpointModel);
    });
    
    if (associatedTags.length > 0) {
      const confirmDelete = window.confirm(
        `${associatedTags.length} tag(s) are associated with this endpoint. Are you sure you want to delete it? This will remove the associated model from these tags.`
      );
  
      if (!confirmDelete) {
        return; 
      }
      
      // remove the endpoint model from the selected_tag_model array of each tag
      associatedTags.forEach((tag) => {
        const typedTag = tag as Tag;
        typedTag.selected_tag_model = typedTag.selected_tag_model?.filter(model => model !== endpointModel) || null;
      });
      
      const updatedTagDict = {
        ...tagDict,
        ...Object.fromEntries(Object.entries(tagDict).map(([key, tag]) => {
          const typedTag = tag as Tag;
          if (Array.isArray(typedTag.selected_tag_model)) {
            typedTag.selected_tag_model = typedTag.selected_tag_model.filter(model => model !== endpointModel);
          }
          return [key, typedTag];
        })),
      };

      const updatedAvailableTags = {
        ...availableTags,
        llm: {
          ...availableTags.llm,
          tagger_params: {
            ...availableTags.llm.tagger_params,
            tag_dict: updatedTagDict,
          },
        },
      };

      setAvailableTags(updatedAvailableTags);      
      try {
        uploadTags(updatedAvailableTags, usedCatalog);
      } catch (error) {
        toast.error({
          title: "Error",
          description: "Error updating tag models",
        });
      }

    }
    const newData = tableData.filter((_, rowIndex) => rowIndex !== index);
    setTableData(newData);
    onDataChange(newData);
  };
  
  const getWidthClass = (header: keyof DataRow) => {
    switch (header) {
      case "api_type":
        return "w-1/12";
      default:
        return "w-auto";
    }
  };

  return (
    <div className="overflow-x-auto">
      <table className="divide-y divide-gray-300 min-w-full">
        <thead>
          <tr className="bg-gray-100">
            {staticHeaders.map((header) => (
              <th
                key={header}
                className="py-2 text-center text-xs font-medium text-gray-700 uppercase tracking-wider justify-center items-center whitespace-nowrap"
              >
                {endpointHeadings[header]}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-300">
          {tableData.map((row, index) => (
            <tr key={index} className="">
              {staticHeaders.map((header) => (
                <td
                  key={header}
                  className={`items-center justify-content px-2 py-3 whitespace-nowrap text-sm text-gray-700 bg-white hover:bg-gray-50 ${getWidthClass(header as keyof DataRow)}`}
                >
                  {renderCellInput(
                    index,
                    header as keyof DataRow,
                    (row as any)[header] ?? "",
                  )}
                </td>
              ))}
              <td>
                <button
                  className="text-grey hover:text-red-700 p-4"
                  onClick={() => deleteRow(index)}
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button
        className="mt-4 px-4 py-2 text-sm bg-gray-200 hover:bg-gray-300 rounded shadow-sm"
        onClick={addRow}
      >
        Add Row
      </button>
    </div>
  );
}

export default DynamicTable;
