import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal, Box, Typography } from "@mui/material";
import { FC, useEffect, useRef, useState } from "react";
import { useFileUploadMutation } from "../../../api/queryHooks";
import { toast } from "../Toast";

import "./styles.css";
import { useDataContext } from "../../../context/DataContext";
import { Auth } from "aws-amplify";
import React from "react";
import { SelectFilesButton, SelectFoldersButton } from "./selectButton";
import { abortTask } from "../../../utils/workers";

type Props = {
  onClose: () => void;
  onSuccess: () => void;
  dataStore: {
    type: "s3" | "azureblob";
    name: string;
    base_path: string;
    credentials:
      | {
          access_key_id: string;
          secret_access_key: string;
        }
      | {
          client_id: string;
          client_secret: string;
          tenant_id: string;
        };
  };
};

export const FileUploadModal: FC<Props> = ({
  onClose,
  dataStore,
  onSuccess,
}) => {
  const resetAndClose = () => {
    setFiles([]);
    onClose();
  };

  const filesUploadMutation = useFileUploadMutation(
    { storage: dataStore },
    {
      onSuccess: () => {
        resetAndClose();
        onSuccess();
        toast.success({
          title: "Files uploaded successfully",
          description: "",
        });
      },
      onError: () => {
        toast.error({
          title: "Failed to upload files",
          description: "Try again",
        });
      },
    },
  );
  const [files, setFiles] = useState<File[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const folderInputRef = useRef<HTMLInputElement>(null);

  const readDirectory = async (dir: FileSystemDirectoryEntry) => {
    const readers = dir.createReader();

    return await new Promise<File[]>((resolve) => {
      const entries: File[] = [];
      readers.readEntries(async (results) => {
        if (!results.length) {
          return entries;
        }

        for (const entry of results) {
          if (entry instanceof FileSystemFileEntry) {
            const fileEntry = await new Promise<File>((resolve, reject) => {
              entry.file(resolve, reject);
            });
            entries.push(fileEntry);
          } else if (entry instanceof FileSystemDirectoryEntry) {
            const children = await readDirectory(entry);
            entries.push(...children);
          }
        }

        resolve(entries);
      });
    });
  };

  const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const entries: File[] = [];

    for (let i = 0; i < e.dataTransfer.items.length; i++) {
      const item = e.dataTransfer.items[i];
      const entry = item.webkitGetAsEntry() as FileSystemEntry;

      if (!entry) {
        continue;
      }

      if (entry instanceof FileSystemFileEntry) {
        entry.file((fileObj) => {
          entries.push(fileObj);
        });
      } else if (entry instanceof FileSystemDirectoryEntry) {
        const files = await readDirectory(entry);

        entries.push(...files);
      }
    }

    setFiles(entries);
  };

  const disabled = !files.length || filesUploadMutation.isPending;
  const submit = () => {
    if (!disabled) {
      filesUploadMutation.mutate({ files });
    }
  };

  const {
    uploadFileTaskProgress,
    uploadFileTask,
    setUploadFileTaskProgress,
    setUploadFileTask,
  } = useDataContext();

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFiles(Array.from(e.target.files || []));
  };

  const handleFileRemoval = (filename: string) => {
    setFiles(files.filter((file) => file.name !== filename));
  };

  useEffect(() => {
    if (folderInputRef.current !== null) {
      folderInputRef.current.setAttribute("directory", "");
      folderInputRef.current.setAttribute("webkitdirectory", "");
    }
  }, [folderInputRef, fileInputRef]);
  return (
    <Modal open onClose={resetAndClose}>
      <>
        {filesUploadMutation.isPending ||
          (uploadFileTaskProgress !== null && (
            <Box className="FileUploadLoadingIndicator">
              <div className="w-full flex flex-col items-center justify-center">
                <span className="text-black text-2xl font-bold">
                  Upload Progress
                </span>
                <div className="w-full bg-gray-200 rounded-full h-6 relative">
                  <div
                    className="bg-primary h-6 rounded-full transition-width duration-300 ease-in-out animate-pulse"
                    style={{
                      width: `${(uploadFileTaskProgress * 100).toFixed(2)}%`,
                    }}
                  ></div>
                  <div className="absolute inset-0 flex items-center justify-center">
                    <span className="text-gray-800">
                      {(uploadFileTaskProgress * 100).toFixed(2)}%
                    </span>
                  </div>
                </div>
                <button
                  className="py-3 px-6 flex flex-row items-center justify-center text-lg mt-4 absolute bottom-0 right-0"
                  onClick={async () => {
                    await abortTask(
                      uploadFileTask,
                      await Auth.currentAuthenticatedUser().then(
                        (user) => user.username,
                      ),
                      "Failed to stop the data upload process.",
                      "The data uploading process has been successfully stopped.",
                    );
                    setUploadFileTaskProgress(null);
                    setUploadFileTask(null);
                  }}
                >
                  <p className="text-md bg-red-400 text-white p-2 rounded-md font-bold">
                    Abort Upload
                  </p>
                </button>
              </div>
            </Box>
          ))}
        {!filesUploadMutation.isPending && uploadFileTaskProgress === null && (
          <Box
            className="FileUploadContainer"
            onDragOver={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onDragEnter={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onDrop={handleDrop}
          >
            <div>
              <Typography variant="h4" style={{ fontSize: "1.5rem" }}>
                Upload files
              </Typography>
            </div>
            <ul className="overflow-auto mb-2">
              {files.map((file) => (
                <li
                  className="bg-gray-200 rounded border-2 p-2 my-2 me-2 flex justify-between items-center"
                  key={file.name}
                >
                  <span className="truncate max-w-xs">{file.name}</span>
                  <FontAwesomeIcon
                    onClick={() => handleFileRemoval(file.name)}
                    className="cursor-pointer text-red-500 hover:text-red-700 relative right-0"
                    icon={faTrashAlt}
                  />
                </li>
              ))}
            </ul>
            <div>
              <SelectFilesButton handleFileChange={handleFileChange} />
              <SelectFoldersButton handleFileChange={handleFileChange} />
            </div>
            <Box className="FileUploadControls">
              <div
                onClick={resetAndClose}
                className="cursor-pointer bg-white text-sm text-primary hover:opacity-50 font-bold py-2 px-4 rounded border-primary border-2"
              >
                Cancel
              </div>
              <Box className="FileUploadControls">
                <div
                  onClick={submit}
                  className={`bg-primary text-white text-sm font-bold py-2 px-4 rounded border-2 border-primary ${disabled ? "opacity-50 cursor-not-allowed" : "cursor-pointer hover:opacity-50"}`}
                >
                  Upload
                </div>
              </Box>
            </Box>
          </Box>
        )}
      </>
    </Modal>
  );
};
