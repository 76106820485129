import { useState, useContext, useEffect, useMemo, useCallback } from "react";
import Auth from "../../../../../auth/AuthProvider";
import { DataContext } from "../../../../../context/DataContext";
import { sendRequest } from "../../../../utilities/functions/api";
import { ENDPOINTS } from "../../../../../api/endpoints";
import FolderList from "../../../../utilities/NavigationBar/FolderList";
import InfoIcon from "../../../../utilities/InfoIcon/InfoIcon";
import { runningTasksAtom } from "../../../../../atoms";
import { useAtom } from "jotai";
import {
  useCatalogNames,
  useFolderEntriesAvailable,
} from "../../../../../api/queryHooks";
import { TagContext } from "../../../../../context/TagContext";
import { NonTaskProgressBar } from "../../../../utilities/NavigationBar/Components/ProgressBar/ProgressBar";
import { toast } from "../../../../utilities/Toast";
import { uploadTags } from "../../../../utilities/functions/apiCalls";
import { v4 as uuidv4 } from "uuid";
import { Disclosure, Transition } from "@headlessui/react";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/solid";
import QuestionUploadSection from "./QuestionSection";
import ContextModal from "./ContextModal";
import { customStyleForSelect } from "../../../../utilities/SearchBar/SearchBar";
import Select from "react-select";
import { Select as MuiSelect, MenuItem } from "@mui/material";
import { abortTask, waitTaskDone } from "../../../../../utils/workers";

const FILTERMAPPING = {
  "Defined Values": "custom",
  "Yes No": "yesNo",
  "Open ended tags": "aiGenerated",
};

const OUTPUTTYPES = ["word", "number", "date"];

export default function AutoCreateTag() {
  const { data: folderEntryByStorageKind, isLoadingFolderEntires } =
    useFolderEntriesAvailable();
  const [currentFolder, setCurrentFolder] = useState(null);
  const [checkedItems, setCheckedItems] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [filterOption, setFilterOption] = useState("");
  const [sortOption, setSortOption] = useState("");
  const [outputTypeFilter, setOutputTypeFilter] = useState("");
  const [selectedTags, setSelectedTags] = useState(new Set());
  const [selectAllTags, setSelectAllTags] = useState(false);
  const [taskID, setTaskID] = useState("");
  const { data: catalogNames = [] } = useCatalogNames();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [allGroupsExpanded, setAllGroupsExpanded] = useState(true);
  const [openGroups, setOpenGroups] = useState({});
  const [runningTasks, setRunningTasks] = useAtom(runningTasksAtom);

  const {
    preferences,
    usedCatalog,
    setAvailableTags,
    catalogFiles,
    autoCreateProgress,
    setAutoCreateProgress,
    isAutoCreating,
    getCatalogAndUpdateCatalog,
    setIsAutoCreating,
    handleCatalogChange,
  } = useContext(DataContext);

  const {
    autoCreatedTags,
    setAutoCreatedTags,
    evaluatedScores,
    setEvaluatedScores,
  } = useContext(TagContext);

  const selectedCatalog = useMemo(() => {
    return catalogNames.includes(usedCatalog) ? usedCatalog : "";
  }, [usedCatalog, catalogNames]);

  const availableStorageKinds = useMemo(
    () =>
      Object.keys(folderEntryByStorageKind ?? {}).filter(
        (storageKind) =>
          Object.keys(folderEntryByStorageKind[storageKind]).length > 0,
      ),
    [folderEntryByStorageKind],
  );

  const [selectedStorageKind, setSelectedStorageKind] = useState(
    () => availableStorageKinds[0] || "",
  );

  const isPrepared = useCallback(
    (fileData) => {
      return (
        fileData &&
        fileData.Key_Questions &&
        fileData.Key_Questions.length > 0 &&
        fileData.Themes &&
        fileData.Themes.length > 0 &&
        fileData.Entities &&
        fileData.Entities.length > 0
      );
    },

    [catalogFiles],
  );

  const folders = useMemo(() => {
    const folderStructure = {};
    Object.keys(catalogFiles).forEach((file) => {
      const directory = catalogFiles[file].file_directory[0];
      if (!folderStructure[directory]) {
        folderStructure[directory] = [];
      }
      folderStructure[directory].push(file);
    });
    return folderStructure;
  }, [catalogFiles]);

  // Get filtered folder keys based on search text
  const filteredFolderKeys = useMemo(() => {
    return Object.keys(folders).filter(
      (folderKey) =>
        folderKey.toLowerCase().includes(searchText.toLowerCase()) ||
        folders[folderKey].some((file) =>
          file.toLowerCase().includes(searchText.toLowerCase()),
        ),
    );
  }, [folders, searchText]);

  const handleModalSubmit = (context) => {
    setIsModalOpen(false);
    const finalContext = context || "proceed_without_context";
    handleGenerateTags(finalContext);
  };

  const getUniqueTagId = (tag) => `${tag.name}_${tag.option}`;

  const filteredTags = useMemo(() => {
    return autoCreatedTags.filter(
      (tag) =>
        (filterOption === "" || tag.option === FILTERMAPPING[filterOption]) &&
        (outputTypeFilter === "" || tag.output_type === outputTypeFilter),
    );
  }, [autoCreatedTags, filterOption, outputTypeFilter]);

  const groupedTags = useMemo(() => {
    return filteredTags.reduce((acc, tag) => {
      const group = tag.group || "Ungrouped";
      if (!acc[group]) {
        acc[group] = [];
      }
      acc[group].push(tag);
      return acc;
    }, {});
  }, [filteredTags]);

  const toggleAllGroups = () => {
    const newAllGroupsExpanded = !allGroupsExpanded;
    setAllGroupsExpanded(newAllGroupsExpanded);
    setOpenGroups(
      Object.keys(groupedTags).reduce((acc, group) => {
        acc[group] = newAllGroupsExpanded;
        return acc;
      }, {}),
    );
  };

  const toggleGroup = (group) => {
    setOpenGroups((prev) => ({
      ...prev,
      [group]: !prev[group],
    }));
  };

  const selectAll = () => {
    setSelectAllTags((prevState) => !prevState);
    const currentState = !selectAllTags;
    if (currentState) {
      const allFilteredTags = new Set(
        filteredTags.map((tag) => getUniqueTagId(tag)),
      );
      setSelectedTags(allFilteredTags);
    } else {
      setSelectedTags(new Set());
    }
  };

  const toggleTag = (tag) => {
    if (!tag) return;
    const tagId = getUniqueTagId(tag);
    setSelectedTags((prevSelectedTags) => {
      const newSelectedTags = new Set(prevSelectedTags);
      if (newSelectedTags.has(tagId)) {
        newSelectedTags.delete(tagId);
      } else {
        newSelectedTags.add(tagId);
      }
      return newSelectedTags;
    });
  };

  const handleGenerateTags = async (context) => {
    setAutoCreatedTags([]);
    setEvaluatedScores({});
    setIsAutoCreating(true);

    const automaticUpdateCatalogIntervalId = setInterval(() => {
      getCatalogAndUpdateCatalog(usedCatalog);
    }, 10000);

    const isNoContext = context === "proceed_without_context";

    try {
      const selectedFiles = Object.keys(checkedItems);
      const totalItems = selectedFiles.length;

      let preparedItems = 0;

      for (const file of selectedFiles) {
        if (isPrepared(catalogFiles[file])) {
          preparedItems++;
        }
      }
      const creds = (await Auth.currentAuthenticatedUser()).username;

      const taskRequestParams = {
        file_names: selectedFiles,
        tag_context: isNoContext ? "" : context,
        [preferences.system.API_USERNAME_KEYWORD]: creds,
        catalog_name: usedCatalog,
        example_questions: questions,
      };

      const autoCreateTagTaskResponse = await sendRequest(
        taskRequestParams,
        ENDPOINTS["auto_create_tags"],
      );

      const { task_id } = await autoCreateTagTaskResponse.json();
      setTaskID(task_id);

      await waitTaskDone(task_id, creds, undefined, ({ completed }) => {
        completed = Number((completed * 100).toFixed(2));
        setRunningTasks((tasks) => {
          const updatedTask = tasks.find((task) => task.id === task_id);
          if (updatedTask) {
            updatedTask.completed = completed;
          }
          return [...tasks];
        });
        setAutoCreateProgress(completed);
      });

      const resultRequestParams = {
        [preferences.system.API_USERNAME_KEYWORD]: (
          await Auth.currentAuthenticatedUser()
        ).username,
        task_id: task_id,
      };
      const autoCreateTagResultResponse = await sendRequest(
        resultRequestParams,
        ENDPOINTS["auto_create_tags_result"],
      );
      const createdTags = await autoCreateTagResultResponse.json();
      const autoTags = createdTags.auto_created_tags;

      setAutoCreatedTags(autoTags);
      setIsAutoCreating(false);
      clearInterval(automaticUpdateCatalogIntervalId);
      toast.success({
        title: "Success",
        description: `${Object.keys(autoTags).length} tags have been successfully generated`,
      });
      await getCatalogAndUpdateCatalog(usedCatalog, true, true);
    } catch (error) {
      console.log("Error during the request", error);
      setIsAutoCreating(false);
    } finally {
      setAutoCreateProgress(0);
    }
  };

  useEffect(() => {
    if (
      selectedStorageKind ||
      isLoadingFolderEntires ||
      !availableStorageKinds
    ) {
      return;
    }

    setSelectedStorageKind(availableStorageKinds[0]);
  }, [availableStorageKinds, isLoadingFolderEntires, selectedStorageKind]);

  const sortTags = (tags) => {
    const sortWithinGroup = (groupTags) => {
      if (sortOption === "score") {
        return groupTags.sort((a, b) => {
          const aScore = evaluatedScores[a.name] || 0;
          const bScore = evaluatedScores[b.name] || 0;
          return bScore - aScore;
        });
      } else if (sortOption === "name") {
        return groupTags.sort((a, b) => a.name.localeCompare(b.name));
      }
      return groupTags;
    };

    const groupedTags = tags.reduce((acc, tag) => {
      const group = tag.group || "Ungrouped";
      if (!acc[group]) {
        acc[group] = [];
      }
      acc[group].push(tag);
      return acc;
    }, {});

    Object.keys(groupedTags).forEach((group) => {
      groupedTags[group] = sortWithinGroup(groupedTags[group]);
    });

    return Object.values(groupedTags).flat();
  };

  const optionMapping = {
    aiGenerated: "document_extraction",
    custom: "pre_defined_values",
    yesNo: "yes_no",
  };

  const updateTagVersion = async (tag) => {
    try {
      const creds = (await Auth.currentAuthenticatedUser()).username;
      const sendDetails = {
        tag_uuid: tag.tag_uuid,
        version_uuid: tag.version_uuid,
        tag_name: tag.name,
        catalog_name: usedCatalog,
        modified_attributes: JSON.stringify([]),
        new_values: JSON.stringify([]),
        old_values: JSON.stringify([]),
        updated_time: new Date().toISOString(),
        tag_details: tag,
        [preferences.system.API_USERNAME_KEYWORD]: creds,
      };
      await sendRequest(sendDetails, ENDPOINTS["update_tag_version"]);
    } catch (error) {
      console.error("Error updating tag:", error);
      toast.error({
        title: "Error",
        description: "An error occurred while updating the tag",
      });
    }
  };

  const handleConfirmSelection = async () => {
    let countNewTags = 0;
    let updatedTags = {};
    const tagsToUpdate = [];

    const confirmSelection = window.confirm(
      `Are you sure you want to add ${selectedTags.size} tags?`,
    );
    if (!confirmSelection) {
      return;
    }

    setAvailableTags((prevAvailableTags) => {
      const updatedTagDict = {
        ...prevAvailableTags.llm.tagger_params.tag_dict,
      };
      const startingNumberAvailableTags = Object.keys(updatedTagDict).length;

      // Count occurrences of each tag name
      const tagNameCounts = {};
      selectedTags.forEach((tagId) => {
        const tagDetails = autoCreatedTags.find(
          (tag) => getUniqueTagId(tag) === tagId,
        );
        if (tagDetails) {
          tagNameCounts[tagDetails.name] =
            (tagNameCounts[tagDetails.name] || 0) + 1;
        }
      });

      selectedTags.forEach((tagId) => {
        const tagDetails = autoCreatedTags.find(
          (tag) => getUniqueTagId(tag) === tagId,
        );
        if (tagDetails && !tagDetails.name.includes(",")) {
          const newTagUuid = uuidv4();
          const newVersionUuid = uuidv4();

          // Map the option to the new format
          const mappedOption =
            optionMapping[tagDetails.option] || tagDetails.option;

          // Use the unique key (tagId) as the name only for duplicate names
          // Include the mapped option in the unique name
          const uniqueTagName =
            tagNameCounts[tagDetails.name] > 1
              ? `${tagDetails.name}-${mappedOption}`
              : tagDetails.name;

          updatedTagDict[uniqueTagName] = {
            ...tagDetails,
            name: uniqueTagName,
            tag_uuid: newTagUuid,
            version_uuid: newVersionUuid,
            option: tagDetails.option, // Keep the original option
          };
          tagsToUpdate.push(updatedTagDict[uniqueTagName]);
        } else if (tagDetails && tagDetails.name.includes(",")) {
          toast.warning({
            title: "Tag name contains invalid character",
            description: `Tag name '${tagDetails.name}' contains invalid character ',' and will not be added to the tag library`,
          });
        }
      });

      countNewTags =
        Object.keys(updatedTagDict).length - startingNumberAvailableTags;

      updatedTags = {
        ...prevAvailableTags,
        llm: {
          ...prevAvailableTags.llm,
          tagger_params: {
            ...prevAvailableTags.llm.tagger_params,
            tag_dict: updatedTagDict,
          },
        },
      };

      return updatedTags;
    });

    try {
      await Promise.all(tagsToUpdate.map(updateTagVersion));

      if (countNewTags > 0) {
        await uploadTags(updatedTags, usedCatalog);
        toast.success({
          title: "Success",
          description: `Added ${countNewTags} tag${countNewTags > 1 ? `s` : ""}`,
        });
      } else {
        alert(`Tag(s) already in library`);
      }
    } catch (error) {
      console.error(`Error updating or uploading tags: ${error.message}`);
      toast.error({
        title: "Error",
        description: `Error updating or uploading tags: ${error.message}`,
      });
    }
  };

  useEffect(() => {
    if (availableStorageKinds.length > 0 && !selectedStorageKind) {
      setSelectedStorageKind(availableStorageKinds[0]);
    }
  }, [availableStorageKinds, selectedStorageKind]);

  const filterOptions = [
    { value: "", label: "All" },
    ...Object.keys(FILTERMAPPING).map((key) => ({ value: key, label: key })),
  ];

  const outputTypeOptions = [
    { value: "", label: "All" },
    ...OUTPUTTYPES.map((type) => ({
      value: type,
      label: type.charAt(0).toUpperCase() + type.slice(1),
    })),
  ];

  const sortOptions = [
    { value: "", label: "None" },
    { value: "name", label: "Tag Name" },
  ];

  return (
    <div className="flex bg-white h-full w-full justify-between p-5 rounded-md gap-5 flex-row">
      <div className="flex flex-col w-1/2 h-full overflow-auto hide-scrollbar">
        <h1 className="text-md font-semibold text-grey">Auto-suggest Tags</h1>
        <p className="text-gray-600 mb-6">
          This tool helps you automatically generate relevant tags for your
          data. Follow the steps below to create custom tags tailored to your
          content.
        </p>
        <div className="flex flex-col space-y-4">
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex items-center justify-between w-full px-4 py-2 text-sm font-medium text-left text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                  <span>1. Select data</span>
                  <p className="flex flex-row gap-2 items-center text-primary">
                    {Object.keys(checkedItems).length > 0 && (
                      <span className="font-bold p-1 rounded-md">
                        {Object.keys(checkedItems).length}{" "}
                        {Object.keys(checkedItems).length > 1
                          ? "Files"
                          : "File"}{" "}
                        selected
                      </span>
                    )}
                    <ChevronUpIcon
                      className={`${
                        open ? "transform rotate-180" : ""
                      } w-5 h-5 text-grey transition-transform duration-300`}
                    />
                  </p>
                </Disclosure.Button>
                <Transition
                  show={open}
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <Disclosure.Panel className=" text-sm text-gray-500">
                    <header className="pb-2 pl-2">
                      Select data from "{usedCatalog}" to generate tags
                    </header>
                    <div className="max-h-[50vh] overflow-y-auto hide-scrollbar border-2  rounded-lg bg-gray-100 p-1">
                      <div className="flex flex-row gap-3 mb-2 text-sm">
                        <MuiSelect
                          className="h-8 bg-white text-sm"
                          onChange={handleCatalogChange}
                          value={selectedCatalog}
                          style={{
                            backgroundColor: "white",
                            fontSize: "0.7rem",
                          }}
                        >
                          <MenuItem
                            value=""
                            disabled
                            style={{ fontSize: "0.875rem" }}
                          >
                            Select a catalog
                          </MenuItem>
                          {catalogNames.map((catalog, index) => (
                            <MenuItem
                              key={index}
                              value={catalog}
                              style={{ fontSize: "0.875rem" }}
                            >
                              {catalog}
                            </MenuItem>
                          ))}
                        </MuiSelect>
                      </div>
                      <FolderList
                        checkedItems={checkedItems}
                        currentFolder={currentFolder}
                        filteredFolderKeys={filteredFolderKeys}
                        folders={folders}
                        integration={usedCatalog}
                        searchText={searchText}
                        setCheckedItems={setCheckedItems}
                        setCurrentFolder={setCurrentFolder}
                        setSearchText={setSearchText}
                        mode="autoCreation"
                      />
                    </div>
                  </Disclosure.Panel>
                </Transition>
              </>
            )}
          </Disclosure>
          <QuestionUploadSection
            questions={questions}
            setQuestions={setQuestions}
          />
        </div>

        <div className="mt-auto pt-4 pb-6 flex justify-end">
          {!isAutoCreating ? (
            <button
              onClick={() => setIsModalOpen(true)}
              className={`${
                Object.keys(checkedItems).length === 0
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-primary hover:bg-primary-dark"
              } text-white font-bold py-2 px-4 rounded text-md`}
              disabled={Object.keys(checkedItems).length === 0}
            >
              Run auto-suggestion
            </button>
          ) : (
            <button
              onClick={async () => {
                await abortTask(
                  taskID,
                  (await Auth.currentAuthenticatedUser()).username,
                  "Failed to stop the auto create tag process.",
                  "The auto creating tag process has been successfully stopped.",
                );
              }}
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded text-md"
            >
              Abort Auto Create Tags
            </button>
          )}
        </div>
      </div>

      <ContextModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={(context) => handleModalSubmit(context)}
      />
      <div className="flex flex-col w-3/5 overflow-y-hidden">
        <div className="flex flex-row w-full justify-between items-center mb-2">
          {autoCreatedTags && Object.keys(autoCreatedTags).length > 0 && (
            <header className="text-md font-semibold text-grey">
              Auto-generated tags{" "}
              <InfoIcon
                infoText={
                  autoCreatedTags && Object.keys(autoCreatedTags).length > 0
                    ? "Choose tags to be added to your tag library"
                    : "Please choose datasets to generate your custom tags"
                }
              />
            </header>
          )}

          {autoCreatedTags && Object.keys(autoCreatedTags).length > 0 && (
            <div className="flex gap-2">
              <button
                onClick={toggleAllGroups}
                className="font-bold text-primary border-2 border-primary text-gray-800 px-3 py-1 rounded hover:text-white hover:bg-primary flex items-center text-xs"
              >
                {allGroupsExpanded ? (
                  <>
                    <ChevronUpIcon className="w-4 h-4 mr-1" />
                    Collapse All
                  </>
                ) : (
                  <>
                    <ChevronDownIcon className="w-4 h-4 mr-1" />
                    Expand All
                  </>
                )}
              </button>
              <button
                onClick={selectAll}
                className="font-bold text-primary border-2 border-primary text-gray-800 px-3 py-1 rounded hover:text-white hover:bg-primary text-xs"
              >
                {selectAllTags ? "Deselect All" : "Select All"}
              </button>
            </div>
          )}
        </div>

        {autoCreatedTags && Object.keys(autoCreatedTags).length > 0 && (
          <div className="flex flex-row gap-4 items-center mb-4">
            <div className="flex flex-col w-1/3">
              <label className="text-xs font-semibold text-grey mb-1">
                Filter by Option
              </label>
              <Select
                options={filterOptions}
                value={filterOptions.find(
                  (option) => option.value === filterOption,
                )}
                onChange={(selectedOption) =>
                  setFilterOption(selectedOption.value)
                }
                styles={customStyleForSelect}
                isSearchable={false}
              />
            </div>
            <div className="flex flex-col w-1/3">
              <label className="text-xs font-semibold text-grey mb-1">
                Filter by Output Type
              </label>
              <Select
                options={outputTypeOptions}
                value={outputTypeOptions.find(
                  (option) => option.value === outputTypeFilter,
                )}
                onChange={(selectedOption) =>
                  setOutputTypeFilter(selectedOption.value)
                }
                styles={customStyleForSelect}
                isSearchable={false}
              />
            </div>
            <div className="flex flex-col w-1/3">
              <label className="text-xs font-semibold text-grey mb-1">
                Sort by
              </label>
              <Select
                options={sortOptions}
                value={sortOptions.find(
                  (option) => option.value === sortOption,
                )}
                onChange={(selectedOption) =>
                  setSortOption(selectedOption.value)
                }
                styles={customStyleForSelect}
                isSearchable={false}
              />
            </div>
          </div>
        )}

        <div className="flex flex-col overflow-y-auto w-full no-scrollbar flex-grow">
          {!isAutoCreating ? (
            <GroupedTagsDisplay
              filteredTags={sortTags(filteredTags)}
              selectedTags={selectedTags}
              toggleTag={toggleTag}
              getUniqueTagId={getUniqueTagId}
              setSelectedTags={setSelectedTags}
              allGroupsExpanded={allGroupsExpanded}
              openGroups={openGroups}
              toggleGroup={toggleGroup}
              groupedTags={groupedTags}
            />
          ) : (
            <div className="w-full h-full flex justify-center items-center p-5">
              <NonTaskProgressBar progress={autoCreateProgress} />
            </div>
          )}
        </div>

        <div
          className={`mt-auto pt-4 pb-6 flex ${isAutoCreating ? "justify-center items-center" : "justify-end"}`}
        >
          {filteredTags && filteredTags.length > 0 && !isAutoCreating && (
            <button
              onClick={handleConfirmSelection}
              className={`text-white border-2 border-primary bg-primary px-4 py-2 rounded-md text-md hover:bg-primary-dark transition-all duration-300 ease-in-out ${
                selectedTags.size === 0 && "opacity-50 cursor-not-allowed"
              }`}
              disabled={selectedTags.size === 0}
            >
              Add Selected Tags ({selectedTags.size})
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

const GroupedTagsDisplay = ({
  filteredTags,
  selectedTags,
  toggleTag,
  getUniqueTagId,
  setSelectedTags,
  allGroupsExpanded,
  openGroups,
  toggleGroup,
  groupedTags,
}) => {
  const toggleSelectAllInGroup = (group) => {
    const groupTags = groupedTags[group];
    const allSelected = groupTags.every((tag) =>
      selectedTags.has(getUniqueTagId(tag)),
    );

    setSelectedTags((prevSelectedTags) => {
      const newSelectedTags = new Set(prevSelectedTags);
      groupTags.forEach((tag) => {
        const tagId = getUniqueTagId(tag);
        if (allSelected) {
          newSelectedTags.delete(tagId);
        } else {
          newSelectedTags.add(tagId);
        }
      });
      return newSelectedTags;
    });
  };

  const getGroupSelectionStatus = (group) => {
    const groupTags = groupedTags[group];
    const selectedCount = groupTags.filter((tag) =>
      selectedTags.has(getUniqueTagId(tag)),
    ).length;
    return `${selectedCount}/${groupTags.length}`;
  };

  return (
    <div className="space-y-6">
      {Object.entries(groupedTags).map(([group, tags]) => {
        const groupSelectionStatus = getGroupSelectionStatus(group);
        const isGroupFullySelected =
          groupSelectionStatus.split("/")[0] ===
          groupSelectionStatus.split("/")[1];

        return (
          <div
            key={group}
            className={`bg-white shadow-md border-2 rounded-lg overflow-hidden ${
              isGroupFullySelected ? "border-primary" : "border-gray-300"
            }`}
          >
            <button
              className="flex justify-between w-full px-6 py-4 text-md font-semibold text-left text-primary bg-primary-50 hover:bg-primary-100 focus:outline-none focus-visible:ring focus-visible:ring-primary-500 focus-visible:ring-opacity-75 transition-all duration-300"
              onClick={() => toggleGroup(group)}
            >
              <span>
                {group} ({tags.length})
              </span>
              <div className="flex items-center">
                <span className="mr-2 text-sm font-normal">
                  {groupSelectionStatus}
                </span>
                <ChevronUpIcon
                  className={`${
                    openGroups[group] ? "transform rotate-180" : ""
                  } w-6 h-6 text-primary transition-transform duration-300`}
                />
              </div>
            </button>
            {openGroups[group] && (
              <div className="px-6 pt-4 pb-6 bg-white">
                <div className="mb-4">
                  <button
                    onClick={() => toggleSelectAllInGroup(group)}
                    className={`text-sm border-2 rounded px-3 py-1 hover:text-white focus:outline-none ${
                      isGroupFullySelected
                        ? "bg-primary text-white border-primary"
                        : "text-primary border-primary hover:bg-primary"
                    }`}
                  >
                    {isGroupFullySelected
                      ? `Deselect All from ${group}`
                      : `Select All from ${group}`}
                  </button>
                </div>
                <div className="space-y-4">
                  {tags.map((tag) => (
                    <div
                      key={getUniqueTagId(tag)}
                      className={`transition duration-300 border-2 ease-in-out p-4 rounded-lg shadow-md hover:shadow-md cursor-pointer ${
                        selectedTags.has(getUniqueTagId(tag))
                          ? "border-primary bg-primary-50"
                          : "bg-gray-50 hover:bg-gray-100"
                      }`}
                      onClick={() => toggleTag(tag)}
                    >
                      <div className="flex justify-between items-center mb-2">
                        <h3 className="text-md font-semibold text-gray-800">
                          {tag.name}
                        </h3>
                        <span
                          title="Output type"
                          className={`text-sm font-medium px-2 py-1 rounded ${
                            tag.output_type === "word"
                              ? "bg-deasieTurquoise text-white"
                              : tag.output_type === "number"
                                ? "bg-deasieBlack text-white"
                                : "bg-yellow-200 text-yellow-800"
                          }`}
                        >
                          {tag.output_type.charAt(0).toUpperCase() +
                            tag.output_type.slice(1)}
                        </span>
                      </div>
                      <p className="text-gray-600 text-sm mb-2">
                        {tag.description}
                      </p>
                      {tag.option !== "aiGenerated" && (
                        <div className="mt-2">
                          <h4 className="text-sm font-medium text-gray-700 mb-1">
                            Available Values:
                          </h4>
                          <ul className="list-disc list-inside text-sm text-gray-600">
                            {tag.availableValues.map((value, valueIndex) => (
                              <li key={valueIndex}>{value}</li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};
