import { useState, useEffect, useContext, useCallback } from "react";
import { DataContext } from "../../../../../context/DataContext";
import { ENDPOINTS } from "../../../../../api/endpoints";
import { API_USERNAME_KEYWORD } from "../../../../../constants/fixedValues";
import { sendRequest } from "../../../../utilities/functions/api";
import Auth from "../../../../../auth/AuthProvider";

export default function FolderTabsComponent({
  activeTab,
  setActiveTab,
  setFilteredFolderKeys,
  setCustomFilteredFiles,
  folders,
  refreshTrigger,
  setCheckedItems,
  setIsLoadingFolderTabs,
}) {
  const { usedCatalog, currentTag, availableTags } = useContext(DataContext);
  const [fileCounts, setFileCounts] = useState({
    "Not yet validated": 0,
    "Previously incorrect": 0,
    "Previously correct": 0,
  });

  const tagDict = {
    ...availableTags.llm.tagger_params.tag_dict,
  };

  const tabs = [
    { 
      label: "Not yet validated", 
      action: (index) => handleTabChange(index) 
    },
    {
      label: "Previously incorrect",
      action: (index) => handleTabChange(index),
    },
    { 
      label: "Previously correct", 
      action: (index) => handleTabChange(index) 
    },
  ];

  const fetchAllValidationRecords = async () => {
    const creds = (await Auth.currentAuthenticatedUser()).username;
    const sendDetails = {
      [API_USERNAME_KEYWORD]: creds,
      catalog_name: usedCatalog,
      tag_uuid: currentTag.tag_uuid,
      version_uuid: currentTag.version_uuid,
    };
    try {
      const response = await sendRequest(
        sendDetails,
        ENDPOINTS["get_validation_records"],
      );
      if (!response.ok) {
        console.error("Failed to fetch validation records:", response);
        return {};
      }

      const data = await response.json();
      return data.validation_records || {};
    } catch (error) {
      console.error("Error fetching validation records:", error);
      return {};
    }
  };

  const processValidationRecords = (validationRecords) => {
    const categories = {
      "Previously incorrect": [],
      "Previously correct": [],
      "Not yet validated": [],
    };
    const validatedFilenames = new Set();

    Object.values(validationRecords).forEach((record) => {
      if (record.label === "incorrect") {
        categories["Previously incorrect"].push(record);
      } else if (record.label === "correct") {
        categories["Previously correct"].push(record);
      }
      validatedFilenames.add(record.filename);
    });

    // Process untested data
    Object.entries(folders).forEach(([folderKey, folderFiles]) => {
      folderFiles.forEach((file) => {
        if (!validatedFilenames.has(file)) {
          categories["Not yet validated"].push({
            filename: file,
            folder: folderKey,
          });
        }
      });
    });

    return categories;
  };

  const initializeData = useCallback(async () => {
    if (!currentTag || !currentTag.name) {
      console.log("Current tag is not set or doesn't have a name");
      return;
    }

    setIsLoadingFolderTabs(true);

    try {
      const validationRecords = await fetchAllValidationRecords();
      const processedData = processValidationRecords(validationRecords);

      const newFileCounts = Object.fromEntries(
        Object.entries(processedData).map(([key, value]) => [
          key,
          new Set(value.map((record) => record.filename)).size,
        ]),
      );
      setFileCounts(newFileCounts);

      const currentTabLabel = tabs[activeTab].label;
      const currentTabData = processedData[currentTabLabel];

      const folderKeys = [
        ...new Set(currentTabData.map((record) => record.folder)),
      ];
      const customFilteredFiles = currentTabData.map(
        (record) => record.filename,
      );

      setFilteredFolderKeys(folderKeys);
      setCustomFilteredFiles(customFilteredFiles);
    } catch (error) {
      console.error("Error initializing data:", error);
    } finally {
      setIsLoadingFolderTabs(false);
    }
  }, [currentTag.version_uuid, activeTab, folders]);

  useEffect(() => {
    if (
      fileCounts["Not yet validated"] < 0 ||
      (fileCounts["Not yet validated"] === 0 &&
        fileCounts["Previously correct"] === 0 &&
        fileCounts["Previously incorrect"] === 0)
    ) {
      initializeData();
    }
  }, [fileCounts["Not yet validated"], activeTab]);

  const handleTabChange = useCallback(
    async (index) => {
      setIsLoadingFolderTabs(true);
      setCheckedItems({});
      setActiveTab(index);

      const currentTabLabel = tabs[index].label;
      const validationRecords = await fetchAllValidationRecords();
      const processedData = processValidationRecords(validationRecords);
      const currentTabData = processedData[currentTabLabel];

      const folderKeys = [
        ...new Set(currentTabData.map((record) => record.folder)),
      ];
      const customFilteredFiles = currentTabData.map(
        (record) => record.filename,
      );

      setFilteredFolderKeys(folderKeys);
      setCustomFilteredFiles(customFilteredFiles);
      setIsLoadingFolderTabs(false);
    },
    [tabs, folders],
  );

  useEffect(() => {
    if (activeTab === 0) {
      handleTabChange(0);
    }
    initializeData();
  }, [refreshTrigger, currentTag.version_uuid]);

  return (
    <div className="w-full flex bg-gray-200 flex-row justify-between">
      {tabs.map((tab, index) => {
        let isDisabled = false;
        let tabTitle = "";
        
        if (tagDict && !Object.keys(tagDict).includes(currentTag.name)) {
          isDisabled = true;
          tabTitle = "Please select or save a tag to view results";
        }
        const labelWithCount = `${tab.label} (${fileCounts[tab.label]})`;
        return (
          <div
            key={index}
            className={`border-gray-400 w-full px-3 py-1.5 text-sm
            ${isDisabled ? "opacity-50 cursor-not-allowed pointer-events-none" : "cursor-pointer"}
            ${activeTab === index ? "text-bold bg-deasieTurquoise text-white" : "bg-gray-200 text-gray-600"}
            ${index !== tabs.length - 1 ? "border-r" : ""}`}
            onClick={() => tab.action(index)}
            title={tabTitle}
          >
            {labelWithCount}
          </div>
        );
      })}
    </div>
  );
}
