import { useState, useEffect, useMemo } from "react";
import Modal from "../../../../../../../../utilities/Modal/Modal";
import "./EvidenceTable.css";
import { useAtom } from "jotai";
import { pdfAtom, pdfSearchAtom } from "../../../../../../../../../atoms";
import { useDataContext } from "../../../../../../../../../context/DataContext";
import { sendRequest } from "../../../../../../../../../components/utilities/functions/api";
import Auth from "../../../../../../../../../auth/AuthProvider";
import { toast } from "../../../../../../../../utilities/Toast";
import { FaArrowCircleLeft, FaArrowCircleRight } from "react-icons/fa";
import { ENDPOINTS } from "../../../../../../../../../api/endpoints";
import { API_USERNAME_KEYWORD } from "../../../../../../../../../constants/fixedValues";
import { useCatalogDocumentInfoLoader } from "../../../../../../../../../api/queryHooks";
import Paginator from "../../../../../../../../Paginator/Paginator";

export const DownloadIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 9H15V3H9V9H5L12 16L19 9ZM5 18V21H19V18H5Z"
      fill="currentColor"
    />
  </svg>
);

const cleanSearch = (text = "") => {
  return text.replace(/ /g, "").replace(/_/g, "").toLowerCase();
};

export default function EvidenceTable(props) {
  const [tagFilter, setTagFilter] = useState("");
  const [chunkFilter, setChunkFilter] = useState("");
  const [evidenceFilter, setEvidenceFilter] = useState("");
  const [valuesFilter, setValuesFilter] = useState("");
  const [fileNameFilter, setFileNameFilter] = useState("");
  const [pageRangeFilter, setPageRangeFilter] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [pendingChanges, setPendingChanges] = useState({});
  const [previousValues, setPreviousValues] = useState({});
  const [pdf, setPdf] = useAtom(pdfAtom);
  const [, setPdfSearch] = useAtom(pdfSearchAtom);
  const {
    usedCatalog,
    setShowFileOnPage,
    catalogFiles,
    preferences,
    availableTags,
  } = useDataContext();

  const tagDict = {
    ...availableTags.llm.tagger_params.tag_dict,
  };
  const [editableValues, setEditableValues] = useState({});

  useEffect(() => {
    setEditableValues({});
  }, [filteredData]);

  const [collapsedCollumns, setCollapsedCollumns] = useState(new Set());
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const loadDocumentInfo = useCatalogDocumentInfoLoader();
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentChange, setCurrentChange] = useState({
    tag: "",
    chunk: "",
    newValue: "",
    oldValue: "",
    field: "values",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  const filteredDataOnPage = useMemo(() => {
    return filteredData.slice(
      (currentPage - 1) * pageSize,
      currentPage * pageSize,
    );
  }, [filteredData, currentPage, pageSize]);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const showModalConfirmation = (tag, chunk, newValue, oldValue) => {
    setCurrentChange({ tag, chunk, newValue, oldValue, field: "values" });
    setIsModalOpen(true);
  };

  const updateTagValueInCatalog = async ({
    catalog,
    tag,
    oldValue,
    newValue,
  }) => {
    const creds = (await Auth.currentAuthenticatedUser()).username;
    const sendDetails = {
      [API_USERNAME_KEYWORD]: creds,
      catalog_name: catalog,
      tag_name: tag,
      prev_value: oldValue,
      new_value: newValue,
    };

    await sendRequest(
      sendDetails,
      ENDPOINTS["apply_tag_value_change_to_catalog"],
    );
  };

  const applyValueChangeToAll = async () => {
    const { tag, newValue, oldValue } = currentChange;
    const newData = props.evidenceData.map((data) => {
      if (data.tag === tag) {
        return {
          ...data,
          evidences: data.evidences.map((evidence) => {
            const updatedValues = evidence.values.map((value) => {
              if (value === oldValue) {
                return newValue;
              }
              return value;
            });
            return {
              ...evidence,
              values: updatedValues,
            };
          }),
        };
      }
      return data;
    });

    props.setEvidenceData(newData);
    setIsModalOpen(false);

    // update database
    try {
      updateTagValueInCatalog({
        catalog: usedCatalog,
        tag: tag,
        oldValue: oldValue,
        newValue: newValue,
      });
      toast.success({ title: "Changes saved successfully!" });
    } catch (error) {
      toast.error({ title: "Changes saved failed, please try again" });
    } finally {
      setIsLoading(false);
    }
  };

  const updateTagValueInChunk = async ({
    catalog,
    tag,
    oldValue,
    newValue,
    chunk,
  }) => {
    const creds = (await Auth.currentAuthenticatedUser()).username;
    const sendDetails = {
      [API_USERNAME_KEYWORD]: creds,
      catalog_name: catalog,
      tag_name: tag,
      prev_value: oldValue,
      new_value: newValue,
      chunk_id: chunk,
    };

    await sendRequest(sendDetails, ENDPOINTS["apply_single_tag_value_change"]);
  };

  const applySingleValueChange = async () => {
    const { tag, chunk, newValue, oldValue } = currentChange;
    const newData = props.evidenceData.map((data) => {
      if (data.tag === tag) {
        const updatedEvidences = data.evidences.map((evidence) => {
          if (evidence.chunk === chunk) {
            const updatedValues = evidence.values.map((value) => {
              if (value === oldValue) {
                return newValue;
              }
              return value;
            });
            return {
              ...evidence,
              values: updatedValues,
            };
          }
          return evidence;
        });
        return {
          ...data,
          evidences: updatedEvidences,
        };
      }
      return data;
    });
    props.setEvidenceData(newData);
    setIsModalOpen(false);

    try {
      updateTagValueInChunk({
        catalog: usedCatalog,
        tag: tag,
        oldValue: oldValue,
        newValue: newValue,
        chunk: chunk,
      });
      toast.success({ title: "Changes saved successfully!" });
    } catch (error) {
      toast.error({ title: "Changes saved failed, please try again" });
    }
  };

  const [currentValue, setCurrentValue] = useState("");
  const [isTyping, setIsTyping] = useState(false);

  async function fetchUpdatedEvidence() {
    setIsLoading(true);

    try {
      const creds = (await Auth.currentAuthenticatedUser()).username;
      const sendDetails = {
        [API_USERNAME_KEYWORD]: creds,
        catalog_name: usedCatalog,
      };
      const response = await sendRequest(
        sendDetails,
        ENDPOINTS["get_evidence"],
      );
      const { updated_evidence } = await response.json();

      const updatedTagsData = props.evidenceData.map((tagData) => ({
        ...tagData,
        evidences: tagData.evidences.map((evidence) => {
          const evidenceKey = `${evidence.fileName}_${evidence.chunk}_${tagData.tag}`;
          const evidenceUpdates = updated_evidence[evidenceKey] || {};
          return {
            ...evidence,
            isSubmitted: evidenceUpdates.is_submitted ?? false,
            isValid: evidenceUpdates.is_valid,
          };
        }),
      }));

      console.log("updatedTagsData", updatedTagsData);
      props.setEvidenceData(updatedTagsData);
    } catch (error) {
      console.error("Failed to fetch updated evidence:", error);
    }

    setIsLoading(false);
  }

  useEffect(() => {
    if (props.isEvidenceModalOpen) {
      fetchUpdatedEvidence();
    }
  }, [props.isEvidenceModalOpen]);

  useEffect(() => {
    const isSaveDisabled = !filteredData.some(
      ({ evidence }) => !evidence.isValid || evidence.isSubmitted,
    );
    setIsSaveDisabled(isSaveDisabled);
  }, [filteredData]);

  useEffect(() => {
    setValuesFilter(
      (prev) => prev || preferences?.profile?.DEFAULT_VALUE_FILTER,
    );
  }, [preferences?.profile?.DEFAULT_VALUE_FILTER]);

  useEffect(() => {
    const newFilteredData = props.evidenceData?.flatMap(
      ({ tag, evidences, total_size, page_count }) => {
        const sortedEvidences = evidences.sort((a, b) => {
          const indexA = Object.keys(props.sortedChunkMappings).indexOf(
            a.chunk,
          );
          const indexB = Object.keys(props.sortedChunkMappings).indexOf(
            b.chunk,
          );
          return indexA - indexB;
        });

        return sortedEvidences
          .filter((evidence) => {
            const formattedPageRange = formatPageRange(
              evidence.pageRange,
              evidence.chunk,
              total_size,
              page_count,
            );
            const fileMatch =
              typeof evidence.fileName === "string"
                ? cleanSearch(evidence.fileName).includes(
                    cleanSearch(fileNameFilter),
                  )
                : true;
            const tagMatch =
              typeof tag === "string"
                ? cleanSearch(tag).includes(cleanSearch(tagFilter))
                : true;
            const chunkMatch =
              typeof (
                props.sortedChunkMappings[evidence.chunk] || evidence.chunk
              ) === "string"
                ? cleanSearch(
                    props.sortedChunkMappings[evidence.chunk] || evidence.chunk,
                  ).includes(cleanSearch(chunkFilter))
                : true;
            const evidenceMatch =
              typeof evidence.evidence === "string"
                ? cleanSearch(evidence.evidence).includes(
                    cleanSearch(evidenceFilter),
                  )
                : true;
            const valuesMatch = Array.isArray(evidence.values)
              ? evidence.values.some(
                  (value) =>
                    typeof value === "string" &&
                    cleanSearch(value).includes(cleanSearch(valuesFilter)),
                )
              : true;
            const pageRangeMatch =
              typeof formattedPageRange === "string"
                ? cleanSearch(formattedPageRange).includes(
                    cleanSearch(pageRangeFilter),
                  )
                : true;

            return (
              fileMatch &&
              tagMatch &&
              chunkMatch &&
              evidenceMatch &&
              valuesMatch &&
              pageRangeMatch
            );
          })
          .map((evidence) => ({
            tag,
            evidence: {
              ...evidence,
              originalChunk: evidence.chunk,
              chunk:
                props.sortedChunkMappings[evidence.chunk] || evidence.chunk,
              values: evidence.values.filter(value =>
                cleanSearch(value).includes(cleanSearch(valuesFilter))
              ),
            },
            total_size,
            page_count,
          }));
      },
    );

    setFilteredData(newFilteredData);
  }, [
    chunkFilter,
    evidenceFilter,
    props.evidenceData,
    props.sortedChunkMappings,
    tagFilter,
    valuesFilter,
    fileNameFilter,
  ]);

  const handleDownload = () => {
    const csvRows = [
      [
        "FileName",
        "Tag",
        "Chunk",
        "Page Range",
        "Evidence",
        "Values",
        "IsCorrect",
        "IsSubmitted",
      ].join(","),
      ...props.evidenceData.flatMap(
        ({ tag, evidences, total_size, page_count }) =>
          evidences.map((evidence) =>
            [
              `"${evidence.fileName}"`,
              tag,
              evidence.chunk,
              formatPageRange(
                evidence.pageRange,
                evidence.chunk,
                total_size,
                page_count,
              ),
              `"${evidence.evidence}"`,
              evidence.values.join("; "),
              evidence.isValid ? "Yes" : "No",
              evidence.isSubmitted ? "Yes" : "No",
            ].join(","),
          ),
      ),
    ];
    const csvString = csvRows.join("\n");
    const blob = new Blob([csvString], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "evidence-data.csv";
    document.body.appendChild(a);
    a.click();
    a.remove();
    URL.revokeObjectURL(url);
  };

  const handleValueChange = (tag, chunk, newValue) => {
    setEditableValues(prev => ({
      ...prev,
      [`${tag}_${chunk}`]: newValue
    }));
  };
  
  const handleSave = async () => {
    const filteredChanges = Object.values(pendingChanges).filter(
      (el) => el.updatedTag.isValid !== null,
    );

    const payloads = filteredChanges.map((el) => {
      const tagDetails = tagDict[el.tag] || {};

      return {
        filename: el.updatedTag.fileName,
        chunk: el.chunk,
        tagname: el.tag,
        tag_value: el.updatedTag.values,
        evidence: el.updatedTag.evidence,
        // catalogname: usedCatalog,
        is_valid: el.updatedTag.isValid,
        is_submitted: el.updatedTag.isSubmitted,
        tag_uuid: tagDetails.tag_uuid,
        version_uuid: tagDetails.version_uuid,
      };
    });

    const creds = (await Auth.currentAuthenticatedUser()).username;
    try {
      await sendRequest(
        {
          feedback_entries: payloads,
          [API_USERNAME_KEYWORD]: creds,
          catalog: usedCatalog,
        },
        ENDPOINTS["save_user_feedback"],
      );

      toast.success({ title: "Changes saved successfully!" });
      setPendingChanges({});
    } catch (error) {
      console.error("Error updating evidence:", error);
      toast.error({ title: "Failed to save changes. Please try again." });
    }
  };

  const handleInputChange = (tag, evidence, field, value) => {
    const updatedTag = {
      ...evidence,
      [field]: value,
    };

    const changeKey = `${tag}_${evidence.chunk}`;
    const existingChange = pendingChanges[changeKey];

    if (existingChange) {
      const updatedChange = {
        ...existingChange,
        updatedTag: {
          ...existingChange.updatedTag,
          [field]: value,
        },
      };
      setPendingChanges({
        ...pendingChanges,
        [changeKey]: updatedChange,
      });
    } else {
      setPendingChanges({
        ...pendingChanges,
        [changeKey]: {
          tag,
          chunk: evidence.chunk,
          updatedTag,
          old_value: previousValues[changeKey],
        },
      });
    }

    const result = props.evidenceData.find(
      (data) =>
        data.tag === tag &&
        data.evidences.some((e) => e.chunk === evidence.chunk),
    );

    if (result) {
      const evidenceIndex = result.evidences.findIndex(
        (e) => e.chunk === evidence.chunk,
      );
      if (evidenceIndex !== -1) {
        result.evidences[evidenceIndex] = updatedTag;
        props.setEvidenceData([...props.evidenceData]);
      }
    }
  };

  const handleSelectionChange = (tag, evidence, field, value) => {
    setPreviousValues((prev) => ({
      ...prev,
      [`${tag}_${evidence.chunk}_${field}`]: evidence[field],
    }));

    handleInputChange(tag, evidence, field, value);
  };

  const handleCheckboxChange = (tag, evidence, field, value) => {
    setPreviousValues((prev) => ({
      ...prev,
      [`${tag}_${evidence.chunk}_${field}`]: evidence[field],
    }));

    handleInputChange(tag, evidence, field, value);
  };

  const handleCollapse = (column) => {
    if (collapsedCollumns.has(column)) {
      collapsedCollumns.delete(column);
    } else {
      collapsedCollumns.add(column);
    }
    setCollapsedCollumns(new Set(collapsedCollumns));
  };

  const closeEvidence = () => {
    if (Object.keys(pendingChanges).length > 0) {
      setIsConfirmationModalOpen(true);
      return;
    }
    closeEvidenceWithoutConfirmation();
  };

  const closeEvidenceWithoutConfirmation = () => {
    props.setIsEvidenceModalOpen(false);
    props.setShowAllEvidence(false);
    setIsConfirmationModalOpen(false);
    setEvidenceFilter("");
    setTagFilter("");
    setChunkFilter("");
    setValuesFilter("");
    setFileNameFilter("");
    setPageRangeFilter("");
  };

  const handleDiscardChanges = () => {
    setPendingChanges({});
    setIsConfirmationModalOpen(false);
    closeEvidenceWithoutConfirmation();
  };

  const handleSaveChanges = () => {
    handleSave();
    setIsConfirmationModalOpen(false);
    closeEvidenceWithoutConfirmation();
  };

  const formatPageRange = (pageRange, chunk_index, total_size, page_count) => {
    // check that total_size and page_count are lists of length 1 each that both contain a number
    const validPageRange = pageRange && pageRange.length > 0;
    if (
      !validPageRange &&
      Array.isArray(total_size) &&
      total_size.length === 1 &&
      Number.isFinite(total_size[0]) &&
      Array.isArray(page_count) &&
      page_count.length === 1 &&
      Number.isFinite(page_count[0])
    ) {
      const char_start = chunk_index.split("_")[0];
      const char_end = chunk_index.split("_")[1];
      const chars_per_page = total_size[0] / page_count[0];
      pageRange = [
        Math.floor(char_start / chars_per_page) + 1,
        Math.min(Math.floor(char_end / chars_per_page) + 1, page_count[0]),
      ];
    } else if (!pageRange || pageRange.length === 0) {
      return "N/A";
    }

    if (pageRange.length === 1) return pageRange[0].toString();
    if (pageRange[0] === pageRange[1]) return pageRange[0].toString();
    return `${pageRange[0]} - ${pageRange[pageRange.length - 1]}`;
  };

  return (
    <Modal
      isOpen={props.isEvidenceModalOpen}
      onClose={() => {
        if (pdf) return;
        closeEvidence();
      }}
      title="Evidence List"
    >
      {isConfirmationModalOpen && (
        <div className="p-4">
          <p>You have unsaved changes. What would you like to do?</p>
          <div className="mt-4 flex justify-around">
            <button
              className="bg-primary w-1/5 text-white text-sm m-2 p-2 rounded-md"
              onClick={handleSaveChanges}
            >
              Save
            </button>
            <button
              className="bg-red-500 w-1/5 text-white text-sm m-2 p-2 rounded-md"
              onClick={handleDiscardChanges}
            >
              Discard
            </button>
          </div>
        </div>
      )}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-4 rounded-lg shadow-lg max-w-lg w-full mx-4">
            <div className="text-lg text-black p-4 border border-gray-300 shadow-lg rounded">
              <p>
                Do you want to apply this change to all same tag values '
                {currentChange.oldValue}' in this catalog?
              </p>
            </div>
            <div className="mt-4 flex justify-around">
              <button
                className="bg-primary w-4/12 text-white m-2 p-2 rounded-md"
                onClick={() => {
                  applyValueChangeToAll();
                }}
              >
                Apply to all
              </button>
              <button
                className="bg-red-500 w-4/12 text-white m-2 p-2 rounded-md"
                onClick={() => {
                  applySingleValueChange();
                }}
              >
                Just change this one
              </button>
            </div>
          </div>
        </div>
      )}
      {isLoading ? null : (
        <div className="flex flex-col w-full px-5 pb-5 pr-5" style={{height: "100%"}}>
          <table className="evidence-table w-full h-full z-10">
            <thead className="top-0 sticky bg-white z-10 shadow-sm">
              <tr>
                <th>
                  <div className="flex flex-col">
                    <div className="flex justify-between gap-2 items-center py-2">
                      <div className="text-nowrap">File Name</div>
                      <div
                        className="cursor-pointer"
                        onClick={() => handleCollapse("File Name")}
                      >
                        {collapsedCollumns.has("File Name") ? (
                          <FaArrowCircleRight />
                        ) : (
                          <FaArrowCircleLeft />
                        )}
                      </div>
                    </div>
                    <input
                      type="text"
                      style={{
                        width: collapsedCollumns.has("File Name") ? 60 : "auto",
                      }}
                      onChange={(e) => setFileNameFilter(e.target.value)}
                      placeholder="Filter by File Name"
                    />
                  </div>
                </th>
                <th>
                  <div className="flex flex-col">
                    <div className="flex justify-between gap-2 items-center py-2">
                      <div className="text-nowrap">Tag</div>
                      <div
                        className="cursor-pointer"
                        onClick={() => handleCollapse("Tag")}
                      >
                        {collapsedCollumns.has("Tag") ? (
                          <FaArrowCircleRight />
                        ) : (
                          <FaArrowCircleLeft />
                        )}
                      </div>
                    </div>
                    <input
                      type="text"
                      style={{
                        width: collapsedCollumns.has("Tag") ? 60 : "auto",
                      }}
                      onChange={(e) => setTagFilter(e.target.value)}
                      placeholder="Filter by Tag"
                    />
                  </div>
                </th>
                <th>
                  <div className="flex flex-col">
                    <div className="flex justify-between gap-2 items-center py-2">
                      <div className="text-nowrap">Page</div>
                      <div
                        className="cursor-pointer"
                        onClick={() => handleCollapse("Page Range")}
                      >
                        {collapsedCollumns.has("Page Range") ? (
                          <FaArrowCircleRight />
                        ) : (
                          <FaArrowCircleLeft />
                        )}
                      </div>
                    </div>
                    <input
                      type="text"
                      style={{
                        width: collapsedCollumns.has("Page Range")
                          ? 60
                          : "auto",
                      }}
                      onChange={(e) => setPageRangeFilter(e.target.value)}
                      placeholder="Filter by Page"
                    />
                  </div>
                </th>
                <th>
                  <div className="flex flex-col">
                    <div className="flex justify-between gap-2 items-center py-2">
                      <div className="text-nowrap">Chunk</div>
                      <div
                        className="cursor-pointer"
                        onClick={() => handleCollapse("Chunk")}
                      >
                        {collapsedCollumns.has("Chunk") ? (
                          <FaArrowCircleRight />
                        ) : (
                          <FaArrowCircleLeft />
                        )}
                      </div>
                    </div>
                    <input
                      type="text"
                      style={{
                        width: collapsedCollumns.has("Chunk") ? 60 : "auto",
                      }}
                      onChange={(e) => setChunkFilter(e.target.value)}
                      placeholder="Filter by Chunk"
                    />
                  </div>
                </th>
                <th>
                  <div className="flex flex-col">
                    <div className="flex justify-between gap-2 items-center py-2">
                      <div className="text-nowrap">Values</div>
                      <div
                        className="cursor-pointer"
                        onClick={() => handleCollapse("Values")}
                      >
                        {collapsedCollumns.has("Values") ? (
                          <FaArrowCircleRight />
                        ) : (
                          <FaArrowCircleLeft />
                        )}
                      </div>
                    </div>
                    <input
                      type="text"
                      className={
                        valuesFilter
                          ? preferences?.profile?.DEFAULT_VALUE_FILTER ===
                              valuesFilter && "bg-yellow-200"
                          : ""
                      }
                      style={{
                        width: collapsedCollumns.has("Values") ? 60 : "auto",
                      }}
                      onChange={(e) => setValuesFilter(e.target.value)}
                      value={valuesFilter}
                      placeholder="Filter by Value"
                    />
                  </div>
                </th>
                <th>
                  <div className="flex flex-col">
                    <div className="flex justify-between gap-2 items-center py-2">
                      <div className="text-nowrap">Evidence</div>
                      <div
                        className="cursor-pointer"
                        onClick={() => handleCollapse("Evidence")}
                      >
                        {collapsedCollumns.has("Evidence") ? (
                          <FaArrowCircleRight />
                        ) : (
                          <FaArrowCircleLeft />
                        )}
                      </div>
                    </div>
                    <input
                      type="text"
                      style={{
                        width: collapsedCollumns.has("Evidence") ? 60 : "auto",
                      }}
                      onChange={(e) => setEvidenceFilter(e.target.value)}
                      placeholder="Filter by Evidence"
                    />
                  </div>
                </th>
                <th className="whitespace-nowrap">Is Correct</th>
                <th className="whitespace-nowrap">Is Submitted</th>
              </tr>
            </thead>
            <tbody>
              {filteredDataOnPage
                .sort((a, b) => {
                  // First, compare by tag name
                  const tagComparison = a.tag.localeCompare(b.tag);
                  if (tagComparison !== 0) return tagComparison;
                
                  // If tags are the same, compare by chunk
                  const chunkA = a.evidence.chunk;
                  const chunkB = b.evidence.chunk;
                
                  if (chunkA === "N/A" && chunkB === "N/A") return 0;
                  if (chunkA === "N/A") return 1;
                  if (chunkB === "N/A") return -1;
                
                  const [startA, endA] = chunkA.split('_').map(Number);
                  const [startB, endB] = chunkB.split('_').map(Number);
                
                  if (startA !== startB) return startA - startB;
                  return (endA || startA) - (endB || startB);
                })
                .map(({ tag, evidence, total_size, page_count }, index) => (
                  <tr key={index}>
                    <td>
                      <div className="max-w-xs break-all">
                        {collapsedCollumns.has("File Name") ? (
                          <>...</>
                        ) : (
                          <>{evidence.fileName}</>
                        )}
                      </div>
                    </td>
                    <td>
                      {collapsedCollumns.has("Tag") ? <>...</> : <>{tag}</>}
                    </td>
                    <td>
                      {collapsedCollumns.has("Page Range") ? (
                        <>...</>
                      ) : (
                        <>
                          {formatPageRange(
                            evidence.pageRange,
                            evidence.chunk,
                            total_size,
                            page_count,
                          ) || "N/A"}
                        </>
                      )}
                    </td>
                    <td>
                      {collapsedCollumns.has("Chunk") ? (
                        <>...</>
                      ) : (
                        <>{evidence.chunk}</>
                      )}
                    </td>
                    <td>
                      {collapsedCollumns.has("Values") ? (
                        <>...</>
                      ) : (
                        <>
                          {evidence.isValid ? (
                            evidence.values.map((value, valueIndex) => (
                              <div key={valueIndex}>{value}</div>
                            ))
                          ) : (
                            <div style={{ position: "relative" }}>
                              <textarea
                                // defaultValue={evidence.values?.[0]}
                                value={editableValues[`${tag}_${evidence.chunk}`] || evidence.values?.[0] || ''}
                                onChange={(e) => {
                                  handleValueChange(tag, evidence.chunk, e.target.value);
                                  setIsTyping(true);
                                }}
                                className="border border-gray-300 rounded-md p-2 w-full"
                                onBlur={() => setIsTyping(false)}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    e.preventDefault();
                                    setIsTyping(false);
                                    showModalConfirmation(
                                      tag,
                                      evidence.chunk,
                                      editableValues[`${tag}_${evidence.chunk}`] || evidence.values[0],
                                      evidence.values[0],
                                    );
                                  }
                                }}
                              />
                              {isTyping && (
                                <div
                                  style={{
                                    position: "absolute",
                                    bottom: "100%",
                                    left: "50%",
                                    transform: "translateX(-50%)",
                                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                                    color: "white",
                                    padding: "5px 10px",
                                    borderRadius: "5px",
                                    fontSize: "12px",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  Click Enter to save
                                </div>
                              )}
                            </div>
                          )}
                        </>
                      )}
                    </td>
                    <td>
                      {collapsedCollumns.has("Evidence") ? (
                        <>...</>
                      ) : (
                        <>
                          {evidence.fileName?.toLowerCase().endsWith(".pdf") ||
                          evidence.fileName?.toLowerCase().endsWith(".docx") ? (
                            <div
                              className="rounded-md p-2 border cursor-pointer w-full max-w-xs overflow-auto"
                              onClick={async () => {
                                toast.info({ title: "Loading document..." });
                                const documentInfo = await loadDocumentInfo(
                                  evidence.fileName,
                                );
                                setShowFileOnPage("standard");
                                setPdf(documentInfo.file_url.toString());
                                setPdfSearch({
                                  evidence: [evidence.evidence],
                                  chunk_index: [evidence.chunk],
                                  page_count:
                                    catalogFiles[evidence.fileName].page_count,
                                  total_size:
                                    catalogFiles[evidence.fileName].total_size,
                                  tipm: catalogFiles[evidence.fileName]?.tipm,
                                  page_range: evidence.pageRange,
                                });
                              }}
                            >
                              {evidence.evidence}
                            </div>
                          ) : (
                            <div className="w-full max-w-xs rounded-md p-2 border overflow-auto">
                              {evidence.evidence}
                            </div>
                          )}
                        </>
                      )}
                    </td>
                    <td>
                      <div className="flex justify-center text-sm">
                        <select
                          className={`
                            block w-22 py-1 text-sm border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500
                            ${evidence.isValid === true ? "bg-green-50 text-green-800" : ""}
                              ${evidence.isValid === false ? "bg-red-50 text-red-800" : ""}
                            `}
                          value={
                            evidence.isValid == null
                              ? "unselected"
                              : evidence.isValid
                                ? "yes"
                                : "no"
                          }
                          onChange={(e) =>
                            handleSelectionChange(
                              tag,
                              evidence,
                              "isValid",
                              e.target.value === "yes"
                                ? true
                                : e.target.value === "no"
                                  ? false
                                  : null,
                            )
                          }
                        >
                          <option value="unselected">Unselected</option>
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </select>
                      </div>
                    </td>
                    <td>
                      <div className="flex justify-center h-full">
                        <input
                          type="checkbox"
                          checked={evidence.isSubmitted}
                          onChange={(e) =>
                            handleCheckboxChange(
                              tag,
                              evidence,
                              "isSubmitted",
                              e.target.checked,
                            )
                          }
                        />
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          <div className="flex-shrink-0 bg-white flex items-center sticky bottom-0 z-20 justify-end items-end">
            <Paginator
              onNewPage={(newPage) => setCurrentPage(newPage)}
              onNewPageSize={(newPageSize) => {
                setCurrentPage(1);
                setPageSize(newPageSize);
              }}
              currentPage={currentPage}
              pageSize={pageSize}
              numberOfItems={filteredData.length}
            />
            <div className="text-base p-4 flex items-center gap-2">
              <div>{props.currentItemKey}</div>
              <button onClick={handleDownload} className="download-button">
                <DownloadIcon />
              </button>
              <button
                className={`bg-primary text-white py-2 px-4 text-center text-sm rounded ${isSaveDisabled ? "bg-gray-300 cursor-not-allowed disabled opacity-30" : "hover:bg-deasieTurquoise"} `}
                disabled={isSaveDisabled}
                onClick={handleSave}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
}
