import { useContext, useEffect, useMemo, useState } from "react";
import Paginator from "../components/Paginator/Paginator";
import { FaExpandArrowsAlt } from "react-icons/fa";
import { FaMinimize } from "react-icons/fa6";
import { RiTableAltFill } from "react-icons/ri";
import { DataContext, useDataContext } from "../context/DataContext";
import { useAtom } from "jotai";
import { pdfjs } from "react-pdf";
import { pdfAtom, pdfSearchAtom, selectedCatalogItemsAtom } from "../atoms";
import Workers from "../utils/threading";
import { AdvancedTagFiltersModal } from "../components/utilities/AdvancedTagFilters/AdvancedTagFilters";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { useCatalogContext } from "../context/CatalogContext";
import { DocumentViewer } from "../components/utilities/DocumentViewer/DocumentViewer";
import { useCatalogDocumentInfoLoader } from "../api/queryHooks";
import CatalogTableRow from "./CatalogTableRow";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const CatalogTable = ({ onEvidenceClick, availableTags }) => {
  const [actionsFile, setActionsFile] = useState("");
  const [pageSize, setPageSize] = useState(20);
  const [expandedFiles, setExpandedFiles] = useState(new Set());
  const [expandedPointsFiles, setExpandedPointsFiles] = useState(new Set());
  const [pdf, setPdf] = useAtom(pdfAtom);
  const [pdfSearch, setPdfSearch] = useAtom(pdfSearchAtom);
  const [selectedCatalogItems, setSelectedCatalogItems] = useAtom(
    selectedCatalogItemsAtom,
  );

  const {
    setShowAdvancedTagFilters,
    showAdvancedTagFilters,
    taggingFilters,
    setTaggingFilters,
  } = useCatalogContext();

  const [advancedTaggingFilters, setAdvancedTaggingFilters] = useState({});
  const [tagFilterOpened, setTagFilterOpened] = useState("");
  const [tagFilterOpenedSearch, setTagFilterOpenedSearch] = useState("");
  const {
    catalogFiles,
    currentDataGroup,
    setSelectedFilters,
    catalogGetsRenamed,
    showFileOnPage,
    setShowFileOnPage,
    isGloballyExpanded,
    setIsGloballyExpanded,
    catalogTablePage,
    setCatalogTablePage,
  } = useContext(DataContext);

  useEffect(() => {
    setSelectedFilters(taggingFilters);
  }, [setSelectedFilters, taggingFilters]);

  const { usedCatalog, getCatalogAndUpdateCatalog } = useContext(DataContext);
  const loadDocumentInfo = useCatalogDocumentInfoLoader();

  const sortedEntries = useMemo(() => {
    return Object.entries(currentDataGroup || {})
      .slice((catalogTablePage - 1) * pageSize, catalogTablePage * pageSize)
      .sort(([_, fileA], [__, fileB]) => {
        if (!fileA.last_processed_at) {
          fileA.last_processed_at = new Date("1976/01/01").toISOString();
        }
        if (!fileB.last_processed_at) {
          fileB.last_processed_at = new Date("1976/01/01").toISOString();
        }
        return (
          new Date(fileB.last_processed_at).getTime() -
          new Date(fileA.last_processed_at).getTime()
        );
      });
  }, [currentDataGroup, catalogTablePage, pageSize]);

  useEffect(() => {
    (async () => {
      const advancedTaggingFilters = await Workers.invoke(
        "getAdvancedTaggingFilters",
        catalogFiles,
      );
      setAdvancedTaggingFilters(advancedTaggingFilters);
    })();
  }, [catalogFiles]);

  return (
    <div className="flex flex-col w-full h-full items-center overflow-hidden hide-scrollbar">
      {pdf && showFileOnPage === "standard" && (
        <DocumentViewer
          url={pdf}
          onClose={() => {
            setPdf("");
            setShowFileOnPage(null);
            setPdfSearch({
              evidence: [""],
              chunk_index: "",
              page_count: null,
              total_size: null,
              page_range: null,
              tipm: {},
            });
          }}
          search={pdfSearch}
        />
      )}
      <div className="w-full flex p-2 bg-white gap-2">
        {Object.keys(taggingFilters).length !== 0 && (
          <div className="w-full flex gap-1 ml-2 items-center">
            {Object.entries(taggingFilters).map(([tag, value]) => (
              <div
                className="relative whitespace-nowrap select-none text-sm flex bg-gray-50 rounded-md px-8 py-2 border cursor-pointer"
                onClick={() => {
                  setTagFilterOpened(tag);
                  setTagFilterOpenedSearch("");
                }}
              >
                {tag} {`(${taggingFilters?.[tag]?.size || 0})`}
                {tagFilterOpened === tag && (
                  <>
                    <div className="text-zinc-600 absolute top-[calc(100%_+_4px)] left-0 z-[100] min-w-[150px] max-w-[250px] overflow-hidden bg-white border rounded-md">
                      <div>
                        <input
                          type="text"
                          className="w-full bg-white px-3 py-2 outline-none"
                          placeholder="Search Value"
                          value={tagFilterOpenedSearch}
                          onChange={(e) => {
                            setTagFilterOpenedSearch(e.target.value);
                          }}
                        />
                      </div>
                      <div className="max-h-[200px] overflow-auto w-full">
                        {advancedTaggingFilters &&
                          advancedTaggingFilters.hasOwnProperty(tag) &&
                          advancedTaggingFilters[tag]
                            .filter((value) => {
                              if (tagFilterOpenedSearch) {
                                return value
                                  .toLowerCase()
                                  .includes(
                                    tagFilterOpenedSearch.toLowerCase(),
                                  );
                              }
                              return true;
                            })
                            .map((value, index) => {
                              const isTagValueSelected =
                                taggingFilters[tag]?.has(value);
                              return (
                                <div
                                  className={`px-4 py-2 ${isTagValueSelected ? "bg-slate-600 text-white" : index % 2 === 0 ? "bg-slate-50 hover:bg-slate-100" : "bg-white hover:bg-slate-100"}`}
                                  onClick={() => {
                                    taggingFilters[tag] =
                                      taggingFilters[tag] || new Set();
                                    if (isTagValueSelected) {
                                      taggingFilters[tag].delete(value);
                                    } else {
                                      taggingFilters[tag].add(value);
                                    }
                                    setTaggingFilters({ ...taggingFilters });
                                  }}
                                >
                                  {value}
                                </div>
                              );
                            })}
                      </div>
                    </div>
                    <div
                      className="fixed z-[99] inset-0 cursor-default"
                      onClick={(e) => {
                        e.stopPropagation();
                        setTagFilterOpened("");
                        setTagFilterOpenedSearch("");
                      }}
                    ></div>
                  </>
                )}
                <div className=" absolute top-1 right-2">
                  <button
                    className="font-semibold text-lg"
                    onClick={(e) => {
                      e.stopPropagation();
                      setTaggingFilters((prev) => {
                        delete prev[tag];
                        return { ...prev };
                      });
                    }}
                  >
                    x
                  </button>
                </div>
              </div>
            ))}
            <button
              disabled={Object.values(taggingFilters).flat().length === 0}
              className="border-2 text-sm ml-2 border-primary py-2 px-4 text-primary rounded-md whitespace-nowrap"
              onClick={async () => {
                setTaggingFilters({});
              }}
            >
              Clear all
            </button>
          </div>
        )}
      </div>
      {showAdvancedTagFilters && (
        <div className="absolute z-50 inset-0 p-4 bg-black bg-opacity-20 backdrop-blur-sm flex items-center justify-center">
          <div className="w-full max-w-lg max-h-[50vh] p-4 bg-white rounded-md shadow-md flex flex-col gap-4 overflow-hidden">
            <AdvancedTagFiltersModal
              onCancel={() => setShowAdvancedTagFilters(false)}
              onAccept={(newFilters) => {
                setTaggingFilters((prev) => ({
                  ...prev,
                  ...Object.fromEntries(
                    newFilters.map((filter) => [filter, new Set([])]),
                  ),
                }));
                setShowAdvancedTagFilters(false);
              }}
              currentFilters={Object.keys(taggingFilters)}
            />
          </div>
        </div>
      )}

      <div className="w-full h-full bg-white overflow-auto relative hide-scrollbar">
        {!currentDataGroup && (
          <div className="absolute inset-0 bg-white flex justify-center items-center">
            <div className="text-gray-200 animate-pulse text-6xl">
              <RiTableAltFill />
            </div>
          </div>
        )}
        {!catalogGetsRenamed ? (
          <table className="table-auto w-full text-sm text-left rtl:text-right hide-scrollbar">
            <thead className="text-xs uppercase z-0">
              <tr>
                <th
                  scope="col"
                  className="px-5 py-2 w-5 top-0 sticky bg-gray-100 z-0"
                >
                  <input
                    type="checkbox"
                    className="appearance-none h-3 w-3 border border-gray-300 rounded-sm bg-white checked:bg-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 transition duration-200 ease-in-out cursor-pointer"
                    checked={
                      selectedCatalogItems.size ===
                      Object.keys(currentDataGroup || {}).length
                    }
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedCatalogItems(
                          new Set(Object.keys(currentDataGroup || {})),
                        );
                      } else {
                        setSelectedCatalogItems(new Set());
                      }
                    }}
                  />
                </th>
                <th
                  scope="col"
                  className="max-w-[150px] px-5 p-3 top-0 sticky bg-gray-100 z-0"
                >
                  File name
                </th>

                <th
                  scope="col"
                  className="text-left px-5 py-3 top-0 sticky bg-gray-100 z-0"
                >
                  <div className="flex items-center gap-1">
                    <div
                      className={`items-center text-xl h-3 flex justify-center transition-all cursor-pointer py-1 px-2 rounded-md ${isGloballyExpanded ? "text-grey" : "text-grey"}`}
                      onClick={() => {
                        setIsGloballyExpanded((state) => !state);
                      }}
                    >
                      {isGloballyExpanded ? (
                        <FaMinimize />
                      ) : (
                        <FaExpandArrowsAlt />
                      )}
                    </div>
                    <span>Tags</span>
                  </div>
                </th>
                <th
                  scope="col"
                  className="px-5 py-3 w-5 top-0 sticky bg-gray-100 z-40"
                ></th>
                <th
                  scope="col"
                  className="px-5 py-3 w-10 top-0 sticky bg-gray-100 z-40"
                >
                  Actions
                </th>

                <th className="text-left w-24 px-5 py-3 top-0 sticky bg-gray-100 z-40">
                  Evidence
                </th>
              </tr>
            </thead>
            <tbody className="">
              {sortedEntries.map(([docKey, tags], index) => {
                return (
                  <>
                    <CatalogTableRow
                      key={docKey}
                      docKey={docKey}
                      tags={tags}
                      index={index}
                      actionsFile={actionsFile}
                      expandedFiles={expandedFiles}
                      setExpandedFiles={setExpandedFiles}
                      expandedPointsFiles={expandedPointsFiles}
                      setExpandedPointsFiles={setExpandedPointsFiles}
                      availableTags={availableTags}
                      setActionsFile={setActionsFile}
                      loadDocumentInfo={loadDocumentInfo}
                      onEvidenceClick={onEvidenceClick}
                    />
                  </>
                );
              })}
            </tbody>
          </table>
        ) : (
          <div className="h-full shrink-0 grow-0 flex flex-col bg-zinc-100 rounded-md overflow-hidden justify-center items-center">
            <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-primary"></div>
            <p className="mt-4 text-lg font-semibold">
              Loading {usedCatalog}...
            </p>
          </div>
        )}
      </div>
      <div className="bg-white shadow-md w-full  rounded-b-md">
        <Paginator
          onNewPage={(newPage) => setCatalogTablePage(newPage)}
          currentPage={catalogTablePage}
          onNewPageSize={(newPageSize) => {
            setCatalogTablePage(1);
            setPageSize(newPageSize);
          }}
          pageSize={pageSize}
          numberOfItems={Object.keys(currentDataGroup || {}).length}
        />
      </div>
    </div>
  );
};

export default CatalogTable;
