import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";

const connectorConfig = {
  s3: {
    displayName: "S3",
    headers: ["display_name", "bucket_name", "base_path", "access_key_id", "secret_access_key"],
    newConnector: () => ({
      storage: { name: "", type: "s3", credentials: { access_key_id: "", secret_access_key: "" } },
      base_path: "",
      name: "s3",
    }),
  },
  azureblob: {
    displayName: "Azure Blob",
    headers: ["display_name", "storage_account", "storage_container", "tenant_id", "client_id", "client_secret"],
    newConnector: () => ({
      storage: { name: "", type: "azureblob", credentials: { tenant_id: "", client_id: "", client_secret: "" } },
      base_path: "",
      name: "azure",
    }),
  },
  sharepoint: {
    displayName: "Sharepoint",
    headers: ["display_name", "site_name", "folder_path", "client_id", "client_secret", "sharepoint_url"],
    newConnector: () => ({
      storage: { name: "", type: "sharepoint", credentials: { client_id: "", client_secret: "", sharepoint_url: "" } },
      base_path: "",
      name: "",
    }),
  },
  qdrant: {
    displayName: "Qdrant",
    headers: ["display_name", "collection_name", "url", "api_key", "file_name_key", "text_key"],
    newConnector: () => ({
      storage: { name: "", type: "qdrant", credentials: { url: "", api_key: "" } },
      base_path: "",
      name: "",
      file_name_key: "",
      text_key: "",
    }),
  },
};

function DataStoreTable({ initialData, onDataChange }) {
  const [tableData, setTableData] = useState(initialData);
  const [newTitles, setNewTitles] = useState(Object.fromEntries(Object.keys(connectorConfig).map(key => [key, ""])));

  const updateTableData = (header, key, connectorType, value) => {
    const newData = { ...tableData };
    const connector = newData[key];

    if (header === "display_name") return;

    if (["bucket_name", "storage_account", "site_name"].includes(header)) {
      connector.storage.name = value;
    } else if (["storage_container", "folder_path", "collection_name", "base_path"].includes(header)) {
      connector.base_path = value;
      if (connectorType === "qdrant") connector.storage.name = value;
    } else if (["file_name_key", "text_key"].includes(header)) {
      connector.storage[header] = value;
    } else if (connector.storage.credentials && header in connector.storage.credentials) {
      connector.storage.credentials[header] = value;
    }

    setTableData(newData);
    onDataChange(newData);
  };

  const addRow = (connectorType) => {
    if (newTitles[connectorType]) {
      const newData = {
        ...tableData,
        [newTitles[connectorType]]: {
          ...connectorConfig[connectorType].newConnector(),
          name: newTitles[connectorType],
        },
      };
      setTableData(newData);
      setNewTitles({ ...newTitles, [connectorType]: "" });
      onDataChange(newData);
    }
  };

  const deleteRow = (key) => {
    const { [key]: _, ...newData } = tableData;
    setTableData(newData);
    onDataChange(newData);
  };

  const getDisplayValue = (value, header) => {
    if (["bucket_name", "storage_account", "site_name"].includes(header)) {
      return value.storage.name;
    } else if (["storage_container", "folder_path", "collection_name", "base_path"].includes(header)) {
      return value.base_path;
    } else if (["file_name_key", "text_key"].includes(header)) {
      return value.storage[header];
    } else if (value.storage.credentials && header in value.storage.credentials) {
      return value.storage.credentials[header];
    }
    return "";
  };

  const renderConnectorTable = (connectorType, config) => (
    <div key={connectorType} className="border-2 border-grey-700 rounded-md p-4">
      <table className="divide-y divide-gray-300 min-w-full">
        <thead>
          <tr><th colSpan={config.headers.length}>{config.displayName}</th></tr>
          <tr className="bg-gray-100">
            {config.headers.map(header => (
              <th key={header} className="py-2 text-left text-xs font-bold text-gray-700 uppercase tracking-wider">{header}</th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-300">
          {Object.entries(tableData)
            .filter(([_, value]) => value.storage.type === connectorType)
            .map(([key, value]) => (
              <tr key={key}>
                {config.headers.map(header => (
                  <td key={header} className="px-2 py-3 whitespace-nowrap text-sm text-gray-700 bg-white hover:bg-gray-50 w-auto">
                    <input
                      type="text"
                      className={`bg-white border-gray-200 form-input block w-full mt-1 border-2 rounded-lg shadow focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50 py-2 text-base ${header === "display_name" ? "cursor-not-allowed pointer-events-none opacity-50" : ""}`}
                      value={header === "display_name" ? key : getDisplayValue(value, header)}
                      onChange={(e) => updateTableData(header, key, connectorType, e.target.value)}
                      readOnly={header === "display_name"}
                    />
                  </td>
                ))}
                <td>
                  <button className="text-grey hover:text-red-700 me-2" onClick={() => deleteRow(key)}>
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <div className="mb-8">
        <input
          className="bg-white border-gray-200 mt-1 border-2 rounded-lg shadow py-2 text-sm me-2 p-3 min-w-[250px]"
          placeholder={`Enter name for new ${connectorType}`}
          value={newTitles[connectorType]}
          onChange={(e) => setNewTitles({ ...newTitles, [connectorType]: e.target.value })}
        />
        <button
          className="mt-4 px-4 py-2 bg-gray-200 hover:bg-gray-300 rounded shadow-sm text-sm"
          onClick={() => addRow(connectorType)}
          disabled={!newTitles[connectorType]}
        >
          Add Connection
        </button>
      </div>
    </div>
  );

  return (
    <div className="overflow-x-auto flex flex-col flex-1 p-4">
      {Object.entries(connectorConfig).map(([connectorType, config]) => renderConnectorTable(connectorType, config))}
    </div>
  );
}

export default DataStoreTable;