import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudUpload } from "@fortawesome/free-solid-svg-icons";

export const SelectFilesButton = ({ handleFileChange }) => {
    return (
        <>
            <label htmlFor="fileInput" className="bg-primary rounded h-10 flex text-sm items-center text-white justify-center cursor-pointer mb-2 hover">
                <FontAwesomeIcon className="me-2" icon={faCloudUpload} style={{ fontSize: '1.2rem' }}/>
                <span>SELECT FILES</span>
            </label>
            <input
                type="file"
                multiple
                accept="image/jpeg, image/gif, image/png, .pdf, .doc, .docx, .txt, .application/msword, .xlsx, .xls, .PDF, .pptx"
                id="fileInput"
                onChange={handleFileChange}
            />
        </>
    )
}

export const SelectFoldersButton = ({ handleFileChange }) => {
    return (
        <>
            <label htmlFor="folderInput" className="w-100 bg-primary rounded h-10 flex text-sm items-center text-white justify-center cursor-pointer mb-2 hover">
                <FontAwesomeIcon className="me-2" icon={faCloudUpload} style={{ fontSize: '1.2rem' }}/>
                <span>SELECT FOLDER</span>
            </label>
            <input
                type="file"
                webkitdirectory=""
                directory=""
                id="folderInput"
                onChange={handleFileChange}
                className="w-100 bg-primary rounded h-10 flex items-center justify-center"
            />
        </>
    )
}